import { Table, Typography } from "antd";
import classNames from "classnames";
import React from "react";

import { NumberFormat } from "../../../../utils/numberFormat";

const { Text } = Typography;

const EquipmentTasks = ({ settings, generatingPdf, equipmentDetails }) => {
  const equipCol = [
    {
      title: "Name",
      dataIndex: "name",
      width: 267,
      className: "wrap-name",
    },
    {
      title: settings?.breakdown?.details?.equipmentTasks?.details?.qtyAndUnit
        ? "Qty"
        : "",
      dataIndex: "quantityToShow",
      render: (value) =>
        settings?.breakdown?.details?.equipmentTasks?.details?.qtyAndUnit
          ? value
          : "",
      width: 71,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.equipmentTasks?.details?.qtyAndUnit
        ? "Unit"
        : "",
      dataIndex: "unitToShow",
      render: (value) =>
        settings?.breakdown?.details?.equipmentTasks?.details?.qtyAndUnit
          ? value
          : "",
      width: 80,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.equipmentTasks?.details?.unitCost
        ? "Unit Cost"
        : "",
      dataIndex: "unitCostToShow",
      render: (value) =>
        settings?.breakdown?.details?.equipmentTasks?.details?.unitCost
          ? value
          : "",
      width: 104,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.equipmentTasks?.details?.cost
        ? "Total Cost"
        : "",
      dataIndex: "costToShow",
      render: (value) =>
        settings?.breakdown?.details?.equipmentTasks?.details?.cost
          ? value
          : "",
      width: 104,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.equipmentTasks?.details?.charge
        ? "Charge"
        : "",
      dataIndex: "chargeToShow",
      render: (value) =>
        settings?.breakdown?.details?.equipmentTasks?.details?.charge
          ? value
          : "",
      width: 104,
      className: "text-center",
    },
  ];

  console.log("settings 123 => ", settings);

  return equipmentDetails?.length ? (
    <>
      <div
        style={{
          textAlign: "justify",
          fontWeight: generatingPdf ? "bold" : "600",
          marginBottom: "10px",
          color: "#484D59",
          fontSize: "14px",
          marginTop: "16px",
        }}
        className="materialbox"
      >
        Equipment
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={equipCol}
          dataSource={equipmentDetails}
          pagination={false}
          tableLayout="fixed"
          className={`proposal_table_card expanded equipment_proposal_table_expend ${
            settings?.breakdown?.details?.equipmentTasks?.details?.totals &&
            equipmentDetails?.length > 1 &&
            (settings?.breakdown?.details?.equipmentTasks?.details?.cost ||
              settings?.breakdown?.details?.equipmentTasks?.details?.charge)
              ? "hideData"
              : ""
          } ${
            !(
              settings?.breakdown?.details?.equipmentTasks?.details?.cost ||
              settings?.breakdown?.details?.equipmentTasks?.details?.charge
            )
              ? "hide-last-row-border"
              : ""
          }`}
          bordered={false}
          style={{
            minWidth: 704,
          }}
          {...{
            ...(equipmentDetails?.length > 1 &&
              (settings?.breakdown?.details?.equipmentTasks?.details?.cost ||
                settings?.breakdown?.details?.equipmentTasks?.details
                  ?.charge) && {
                summary: (pageData) => (
                  <Table.Summary.Row
                    className={classNames({
                      "hide-borders": settings?.estimateTotal,
                    })}
                  >
                    <Table.Summary.Cell colSpan={4}>
                      <Text
                        style={{
                          color: "#484d59",
                          fontWeight: generatingPdf ? "bold" : 600,
                        }}
                      >
                        Total
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                      {settings?.breakdown?.details?.equipmentTasks?.details
                        ?.cost && (
                        <Text
                          style={{
                            color: "#484d59",
                            fontWeight: generatingPdf ? "bold" : 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {NumberFormat(pageData?.[0]?.totalCost)}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                      {settings?.breakdown?.details?.equipmentTasks?.details
                        ?.charge && (
                        <Text
                          style={{
                            color: "#484d59",
                            fontWeight: generatingPdf ? "bold" : 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {NumberFormat(pageData?.[0]?.totalCharge)}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ),
              }),
          }}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default EquipmentTasks;
