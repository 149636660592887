import { Checkbox } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const handleCheckChange = ({ target: { checked } }) => {
    setChecked(checked);
    navigate(`/view-proposal?token=${searchParams.get("token")}`);
  };
  return (
    <>
      <Checkbox checked={checked} onChange={handleCheckChange} />
      <span>I&lsquo;m not robot</span>
    </>
  );
};

export default Redirect;
