import "./modal-style.css";

import { Modal } from "antd";
import classNames from "classnames";
import React from "react";

const CustomModal = (props) => (
  <Modal
    name={props.name || ""}
    title={props.title}
    // visible={props.isModalOpen}
    open={props.isModalOpen}
    onOk={props.handleOk}
    onCancel={props.handleCancel}
    wrapClassName={classNames("custom-modal", {
      [props.wrapperClass]: props.wrapperClass,
    })}
    width={props.width}
    footer={false}
    bodyStyle={props.bodyStyle}
    closable={props.closable}
    centered={props.centered}
    closeIcon={props.closeIcon}
    destroyOnClose={props.destroyOnClose}
  >
    {props.children}
  </Modal>
);

export default CustomModal;
