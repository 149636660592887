import { Table, Typography } from "antd";
import classNames from "classnames";
import React from "react";

import { NumberFormat } from "../../../../utils/numberFormat";

const { Text } = Typography;

const MaterialTasks = ({
  settings,
  laborDetails,
  equipmentDetails,
  subcontractorDetails,
  generatingPdf,
  materialDetails,
}) => {
  const materialCol = [
    {
      title: "Name",
      dataIndex: "name",
      width: 267,
      className: "wrap-name",
    },
    {
      title: settings?.breakdown?.details?.materials?.details?.qtyAndUnit
        ? "Qty"
        : "",
      dataIndex: "quantityToShow",
      render: (value) =>
        settings?.breakdown?.details?.materials?.details?.qtyAndUnit
          ? value
          : "",
      width: 71,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.materials?.details?.qtyAndUnit
        ? "Unit"
        : "",
      dataIndex: "unitToShow",
      render: (value) =>
        settings?.breakdown?.details?.materials?.details?.qtyAndUnit
          ? value
          : "",
      width: 80,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.materials?.details?.unitCost
        ? "Unit Cost"
        : "",
      dataIndex: "unitCostToShow",
      render: (value) =>
        settings?.breakdown?.details?.materials?.details?.unitCost ? value : "",
      width: 104,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.materials?.details?.cost
        ? "Total Cost"
        : "",
      dataIndex: "costToShow",
      render: (value) =>
        settings?.breakdown?.details?.materials?.details?.cost ? value : "",
      width: 104,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.materials?.details?.charge
        ? "Charge"
        : "",
      dataIndex: "chargeToShow",
      render: (value) =>
        settings?.breakdown?.details?.materials?.details?.charge ? value : "",
      width: 104,
      className: "text-center",
    },
  ];

  console.log("materialDetails", materialDetails);

  return materialDetails?.length ? (
    <>
      <div
        className="materialbox"
        style={{
          textAlign: "justify",
          fontWeight: generatingPdf ? "bold" : "600",
          marginBottom: "10px",
          color: "#484D59",
          fontSize: "14px",
        }}
      >
        Material
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={materialCol}
          dataSource={materialDetails}
          pagination={false}
          tableLayout="fixed"
          className={classNames("proposal_table_card expanded", {
            hideData:
              settings?.breakdown?.details?.materials?.details?.totals &&
              materialDetails?.length > 1 &&
              (settings?.breakdown?.details?.materials?.details?.cost ||
                settings?.breakdown?.details?.materials?.details?.charge),
            "hide-last-row-border":
              !settings?.breakdown?.details?.materials?.details?.cost &&
              !settings?.breakdown?.details?.materials?.details?.charge &&
              materialDetails?.length > 1 &&
              (!settings?.breakdown?.details?.laborTasks?.active ||
                !laborDetails?.length) &&
              (!settings?.breakdown?.details?.subcontractorsTasks?.active ||
                !subcontractorDetails?.length) &&
              (!settings?.breakdown?.details?.equipmentTasks?.active ||
                !equipmentDetails?.length),
          })}
          bordered={false}
          {...{
            ...(materialDetails?.length > 1 &&
              (settings?.breakdown?.details?.materials?.details?.cost ||
                settings?.breakdown?.details?.materials?.details?.charge) && {
                summary: (pageData) => (
                  <Table.Summary.Row
                    className={classNames({
                      "hide-borders":
                        (!settings?.breakdown?.details?.laborTasks?.active ||
                          !laborDetails?.length) &&
                        (!settings?.breakdown?.details?.subcontractorsTasks
                          ?.active ||
                          !subcontractorDetails?.length) &&
                        (!settings?.breakdown?.details?.equipmentTasks
                          ?.active ||
                          !equipmentDetails?.length),
                    })}
                  >
                    <Table.Summary.Cell colSpan={4}>
                      <Text
                        style={{
                          color: "#484d59",
                          fontWeight: generatingPdf ? "bold" : 600,
                        }}
                      >
                        Total
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                      {settings?.breakdown?.details?.materials?.details
                        ?.cost && (
                        <Text
                          style={{
                            color: "#484d59",
                            fontWeight: generatingPdf ? "bold" : 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {NumberFormat(pageData?.[0]?.totalCost)}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className="text-center">
                      {settings?.breakdown?.details?.materials?.details
                        ?.charge && (
                        <Text
                          style={{
                            color: "#484d59",
                            fontWeight: generatingPdf ? "bold" : 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {NumberFormat(pageData?.[0]?.totalCharge)}
                        </Text>
                      )}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                ),
              }),
          }}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default MaterialTasks;
