/* eslint-disable no-unused-vars */
import { formatPhoneNumber } from "react-phone-number-input";

import { modifyPhoneNumber } from "./javascript";

export const mentions = [
  { text: "Customer", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Company", value: "{{COMPANY_NAME}}", field: "companyName" },
  { text: "Address", value: "{{ADDRESS}}", field: "address" },
  {
    text: "Project Address",
    value: "{{PROJECT_ADDRESS}}",
    field: "projectAddress",
  },
  {
    text: "UserNamePhoneNumber",
    value: "{{USER_NAME_PHONE_NUMBER}}",
    field: "phoneNumber",
  },
  {
    text: "CompanyPhoneNumber",
    value: "{{COMPANY_PHONE_NUMBER}}",
    field: "CompanyPhoneNumber",
  },
  {
    text: "Customer Address",
    value: "{{CUSTOMER_ADDRESS}}",
    field: "customerAddress",
  },
  { text: "UserName", value: "{{USER_NAME}}", field: "currentUserName" },
  {
    text: "ClickHereToSeeTheFullProjectSummary",
    value: "{{ClickHereToSeeTheFullProjectSummary}}",
    field: "ClickHereToSeeTheFullProjectSummary",
  },
];

export const templateMentions = [
  { text: "Customer Name", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Company Name", value: "{{COMPANY_NAME}}", field: "companyName" },
];
export const projectSummaryMentions = [
  { text: "Customer Name", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Customer Name", value: "{{CUSTOMERNAME}}", field: "customerName" },
  { text: "Customer Address", value: "{{ADDRESS}}", field: "customerAddress" },
  { text: "Customer Address", value: "{{PHONENO}}", field: "customerAddress" },
  {
    text: "Project Address",
    value: "{{PROJECT_ADDRESS}}",
    field: "projectAddress",
  },
  {
    text: "Customer Number",
    value: "{{USER_NAME_PHONE_NUMBER}}",
    field: "userNumber",
  },
  {
    text: "Customer Address",
    value: "{{CUSTOMER_ADDRESS}}",
    field: "customerAddress",
  },
  { text: "User Name", value: "{{USER_NAME}}", field: "userName" },
  {
    text: "Organization Name",
    value: "{{COMPANY_NAME}}",
    field: "organizationName",
  },
  {
    text: "Organization Number",
    value: "{{COMPANY_PHONE_NUMBER}}",
    field: "organizationNumber",
  },
  {
    text: "Organization Address",
    value: "{{COMPANY_ADDRESS}}",
    field: "organizationAddress",
  },
  { text: "Billing Email", value: "{{BILLING_EMAIL}}", field: "billingEmail" },
  {
    text: "Billing Phone Number",
    value: "{{BILLING_PHONE}}",
    field: "billingPhoneNumber",
  },
];

export const SMSTemapateMention = [
  { text: "Customer Email", value: "$customerEmail", field: "customerEmail" },
  { text: "Customer Name", value: "$customerName", field: "customerName" },
  {
    text: "Customer Address",
    value: "$customerAddress",
    field: "customerAddress",
  },
  { text: "day", value: "$day", field: "day" },
];

export function replacePatterns(input, customerDetails, organizationDetails) {
  return input
    .replace(/<\/?a[^>]*>/g, "")
    .replaceAll("@{{", "{{")
    .replace(
      /{{USER_NAME_PHONE_NUMBER}}/g,
      formatPhoneNumber(
        organizationDetails?.phoneNumber
          ? `+${organizationDetails?.phoneNumber}`
          : "",
      ),
    )
    .replace(
      /{{COMPANY_PHONE_NUMBER}}/g,
      formatPhoneNumber(
        customerDetails?.contactNo ? `+${customerDetails?.contactNo}` : "",
      ),
    )
    .replace(/{{COMPANY_NAME}}/g, organizationDetails?.name);
}
export const replaceWithoutTagValue = (input) =>
  input.replace(/<\/?a[^>]*>/g, "").replaceAll("@{{", "{{");

export const replaceMentions = (
  input,
  customerDetails,
  organization,
  userDetails,
) =>
  input
    ?.replace(/{{COMPANY_NAME}}/g, organization?.name)
    ?.replace(/{{COMPANYNAME}}/g, organization?.name)
    ?.replace(/{{CUSTOMER_NAME}}/g, customerDetails?.fullName)
    ?.replace(
      /{{COMPANY_PHONE_NUMBER}}/g,
      modifyPhoneNumber(organization?.phoneNumber),
    )
    ?.replace(/{{ADDRESS}}/g, organization?.address)
    ?.replace(/{{CUSTOMER_ADDRESS}}/g, customerDetails?.address)
    ?.replace(
      /{{USER_NAME_PHONE_NUMBER}}/g,
      `${userDetails?.name || ""} ${
        userDetails?.activePhoneNumber
          ? modifyPhoneNumber(userDetails?.activePhoneNumber)
          : ""
      }`,
    )
    ?.replace(/{{USER_NAME}}/g, userDetails?.name)
    ?.replace(/{{ClickHereToSeeTheFullProjectSummary}}/g, "");
