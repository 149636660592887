/* eslint-disable no-unused-vars */
import { SettingOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, message, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";

import {
  createNewProposal,
  getProposalDetailsWithId,
  saveProposalChanges,
  updateProposalSettings,
} from "../../../api/proposal";
import { MAX_SIZE_TITLE } from "../../../utils/constants/constants";
import {
  CopyContract,
  editIconContrat,
  ProposalFilterIcon,
} from "../../../utils/svg.file";
import CustomModal from "../../invoicing/details/CustomModal";

const { Panel } = Collapse;

const genExtra = () => (
  <SettingOutlined
    style={{ fontSize: "20px" }}
    className="mobile_icon_proposal"
    onClick={(event) => {
      event.stopPropagation();
    }}
  />
);

const modifyKeyName = {
  equipmentTasks: "Equipment",
  laborTasks: "Labor",
  subcontractorsTasks: "Subscontractors",
  materials: "Materials",
};

const modifySubKeyName = {
  totals: "Totals Only",
  qtyAndUnit: "Qty & Unit",
  unitCost: "Unit Cost",
  cost: "Total Cost",
  charge: "Charge",
};

const ProposalSettings = ({
  proposalList,
  selectedProposal,
  settings,
  handleChangeToggle,
  switchLoading,
  setEditProposalSettings,
  setSelectedProposal,
  setSelectedProposalSettings,
  getProposalList,
  proposalDetails,
}) => {
  const [proposalName, setProposalName] = useState("");
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [createProposal, setCreateProposal] = useState(false);

  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const resizeWindow = () => {
      if (!matches) {
        if (window.innerWidth > 992) setMatches(true);
        else setMatches(false);
      }
    };
    resizeWindow();
    window.addEventListener("resize", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  const handleMobileToggle = () => {
    setMatches(!matches);
  };

  const handleSubmit = async () => {
    const clonedSettings = { ...settings };
    if (!proposalName) {
      setErrors({
        title: "Please give proposal name",
      });
    } else {
      setErrors(null);
      setLoading(true);
      delete clonedSettings._id;
      delete clonedSettings.__v;
      delete clonedSettings.updatedAt;
      delete clonedSettings.title;
      const payload = {
        title: proposalName,
        ...clonedSettings,
      };
      const response = await createNewProposal({ data: payload });
      if (response?.remote === "success") {
        // const clonedList = [...]
        setCreateProposal(false);
        setLoading(false);

        setSelectedProposal(response?.data?.data?._id);
        if (getProposalList) {
          getProposalList(false);
        }
      } else {
        console.log(
          "Error while adding new proposal template => ",
          response?.errors?.errors,
        );
        message.error(
          response?.errors?.errors?.error || response?.errors?.errors?.message,
        );
        setLoading(false);
      }
    }
  };
  const handleSaveTemplate = async () => {
    const data = {
      ...settings,
    };
    const response = await saveProposalChanges({
      data,
      proposalId: selectedProposal,
    });
    if (response.remote === "success") {
      message.success("Proposal saved successfully");
    } else {
      console.log("Error while saving proposal template => ", response);
      message.error(
        response?.errors?.errors ||
          response?.errors?.errors?.error ||
          response?.errors?.errors?.message,
      );
    }
  };

  const getProposalDetails = async (id, estimationId) => {
    setLoading(true);
    const response = await getProposalDetailsWithId({ id });
    if (response?.remote === "success") {
      const res = await updateProposalSettings({
        estimationId,
        data: response?.data?.data,
      });
      if (res?.remote === "success") {
        setSelectedProposalSettings((prev) => ({
          ...prev,
          ...res?.data?.data,
        }));
      } else {
        message.error("Something Went Wrong!");
        console.log(
          "Error while updating proposal template after changing template => ",
          res,
        );
      }
      setLoading(false);
    } else {
      console.log("Error while changing proposal template => ", response);
      message.error(
        response?.errors?.errors?.error ||
          response?.errors?.errors?.message ||
          response?.errors?.errors,
      );
      setLoading(false);
    }
  };

  const handleProposalChange = async (value) => {
    setSelectedProposal(value);
    console.log("proposalDetails => ", proposalDetails);
    await getProposalDetails(value, proposalDetails?.estimateId);
  };
  return (
    <>
      <Collapse defaultActiveKey={["1"]} ghost className="contract-collapse">
        <Panel
          header="Settings"
          key="1"
          className="contract-toggle"
          extra={genExtra()}
          style={{
            border: "none",
          }}
        >
          <div className="d-flex align-items-center gap-2 justify-content-between">
            <Select
              popupClassName="overlay-drop"
              className="select-contarct-w"
              options={proposalList}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button
                    type="text"
                    className="w-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCreateProposal(true);
                    }}
                  >
                    Add New Proposal
                  </Button>
                </>
              )}
              value={selectedProposal}
              onChange={(value) => {
                handleProposalChange(value);
              }}
              loading={loading}
              disabled={loading}
            />
            <div className="d-flex align-items-center gap-2">
              <Button
                type="link"
                shape="circle"
                className="copy_proposal_btn"
                onClick={() => handleSaveTemplate()}
              >
                {CopyContract}
              </Button>
              <Button
                className="proposal_filter_btn proposal_filter_btn2"
                onClick={handleMobileToggle}
              >
                {ProposalFilterIcon}
              </Button>
            </div>
          </div>
          {matches && (
            <div
              className="d-flex flex-column gap-3 mobile_proposal_setting_card scroll-popover pe-2"
              style={{
                maxHeight: "calc(100vh - 150px)",
                overflowY: "auto",
                border: "none",
              }}
            >
              <div className="contract-heading">
                <h2>Overall</h2>
                <ul>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Template Color</span>
                      <span
                        style={{
                          width: 25,
                          height: 25,
                          borderRadius: 8,
                          backgroundColor: settings?.templateColor,
                          boxShadow:
                            "0 0px 1px 0 rgba(0,0,0,0.2),0 1px 3px 0 rgba(0,0,0,0.19)",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setEditProposalSettings({ templateColor: true })
                        }
                      />
                    </div>
                  </li>
                  {/* Cover page */}
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Cover Page</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ coverPage: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.coverPage}
                          loading={switchLoading?.coverPage}
                          disabled={switchLoading?.coverPage}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "coverPage",
                              data: { coverPage: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  {/* About Us */}
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>About Us</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ aboutUs: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.aboutUs}
                          loading={switchLoading?.aboutUs}
                          disabled={switchLoading?.aboutUs}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "aboutUs",
                              data: { aboutUs: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  {/* Past Projects */}
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Past Projects</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ pastProjects: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>

                        <Switch
                          checked={settings?.pastProject}
                          loading={switchLoading?.pastProject}
                          disabled={switchLoading?.pastProject}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "pastProject",
                              data: { pastProject: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="contract-heading">
                <h2>Proposal</h2>
                <ul>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>
                        Line Item Photos
                      </span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.lineItemPhotos}
                          loading={switchLoading?.lineItemPhotos}
                          disabled={switchLoading?.lineItemPhotos}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "lineItemPhotos",
                              data: { lineItemPhotos: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>
                        Line Item Prices
                      </span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.lineItemPrice}
                          loading={switchLoading?.lineItemPrice}
                          disabled={switchLoading?.lineItemPrice}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "lineItemPrice",
                              data: { lineItemPrice: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>
                        Client Description
                      </span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.clientDescription}
                          loading={switchLoading?.clientDescription}
                          disabled={switchLoading?.clientDescription}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "clientDescription",
                              data: {
                                clientDescription: toggle,
                                ...(toggle && { crewNotes: false }),
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Crew Notes</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.crewNotes}
                          loading={switchLoading?.crewNotes}
                          disabled={switchLoading?.crewNotes}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "crewNotes",
                              data: {
                                crewNotes: toggle,
                                ...(toggle && { clientDescription: false }),
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="contract-heading">
                <h2>Summary</h2>
                <ul>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Show Line Items</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={
                            settings?.summary?.details?.lineItems?.active
                          }
                          loading={switchLoading?.lineItems}
                          disabled={switchLoading?.lineItems}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "lineItems",
                              data: {
                                summary: {
                                  details: {
                                    lineItems: {
                                      active: toggle,
                                    },
                                  },
                                },
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    {settings?.summary?.details?.lineItems?.active && (
                      <ul className="mt-3">
                        <li>
                          <div className="d-flex aligin-items-center gap-2 justify-content-between">
                            <span style={{ fontWeight: "500" }}>
                              Price Per Line Item
                            </span>
                            <div className="d-flex gap-2">
                              <Switch
                                checked={
                                  settings?.summary?.lineItems?.details
                                    ?.pricePerLineItem
                                }
                                loading={switchLoading?.pricePerLineItem}
                                disabled={switchLoading?.pricePerLineItem}
                                onChange={(toggle) => {
                                  handleChangeToggle({
                                    name: "pricePerLineItem",
                                    data: {
                                      summary: {
                                        details: {
                                          lineItems: {
                                            details: {
                                              pricePerLineItem: toggle,
                                            },
                                          },
                                        },
                                      },
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Show Breakdown</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.breakdown?.active}
                          disabled={switchLoading?.breakdown}
                          loading={switchLoading?.breakdown}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "breakdown",
                              data: {
                                breakdown: {
                                  active: toggle,
                                  details: {
                                    materials: {
                                      active: toggle,
                                    },
                                    laborTasks: {
                                      active: toggle,
                                    },
                                    subcontractorsTasks: {
                                      active: toggle,
                                    },
                                    equipmentTasks: {
                                      active: toggle,
                                    },
                                  },
                                },
                                ...(toggle &&
                                  !settings?.summary?.details?.lineItems
                                    ?.active && {
                                    summary: {
                                      details: {
                                        lineItems: {
                                          active: toggle,
                                        },
                                      },
                                    },
                                  }),
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    {/* breakdown line items */}
                    {settings?.breakdown?.active && (
                      <ul className="mt-3" style={{ paddingLeft: "0px" }}>
                        {Object.entries(settings?.breakdown?.details || {}).map(
                          ([key, val], ind) => (
                            <li key={`breakdown-${key}-${ind}`}>
                              <div className="d-flex aligin-items-center gap-2 justify-content-between">
                                <span
                                  style={{
                                    fontWeight: "500",
                                    // paddingLeft: 24 * 2,
                                  }}
                                >
                                  {modifyKeyName[key] || key}
                                </span>
                                <div className="d-flex gap-2">
                                  <Switch
                                    checked={val?.active}
                                    disabled={
                                      switchLoading?.[`breakdown-${key}`]
                                    }
                                    loading={
                                      switchLoading?.[`breakdown-${key}`]
                                    }
                                    onChange={(toggle) =>
                                      handleChangeToggle({
                                        name: `breakdown-${key}`,
                                        data: {
                                          breakdown: {
                                            details: {
                                              [key]: { active: toggle },
                                            },
                                          },
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              {/* Detailed breakdown */}
                              {val?.active && (
                                <ul className="mt-3">
                                  {Object.entries(val?.details)?.map(
                                    ([k, v], i) => (
                                      <li
                                        key={`breakdown-${key}-${ind}-${k}-${i}`}
                                      >
                                        <div className="d-flex aligin-items-center gap-2 justify-content-between">
                                          <span
                                            style={{
                                              fontWeight: "500",
                                              // paddingLeft: 24 * 3,
                                            }}
                                          >
                                            {modifySubKeyName[k] || k}
                                          </span>
                                          <div className="d-flex gap-2">
                                            <Switch
                                              checked={v}
                                              disabled={
                                                switchLoading?.[
                                                  `breakdown-${key}-${k}`
                                                ]
                                              }
                                              loading={
                                                switchLoading?.[
                                                  `breakdown-${key}-${k}`
                                                ]
                                              }
                                              onChange={(toggle) =>
                                                handleChangeToggle({
                                                  name: `breakdown-${key}-${k}`,
                                                  data: {
                                                    breakdown: {
                                                      details: {
                                                        [key]: {
                                                          details: {
                                                            [k]: toggle,
                                                          },
                                                        },
                                                      },
                                                    },
                                                  },
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              )}
                            </li>
                          ),
                        )}
                      </ul>
                    )}
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Proposal Total</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.estimateTotal}
                          loading={switchLoading?.estimateTotal}
                          disabled={switchLoading?.estimateTotal}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "estimateTotal",
                              data: { estimateTotal: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="contract-heading border-0">
                <h2>Other</h2>
                <ul>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Payment Terms</span>
                      <Switch
                        checked={settings?.paymentTerms}
                        loading={switchLoading?.paymentTerms}
                        disabled={switchLoading?.paymentTerms}
                        onChange={(toggle) =>
                          handleChangeToggle({
                            name: "paymentTerms",
                            data: { paymentTerms: toggle },
                          })
                        }
                      />
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Show Percentage</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.percentage}
                          loading={switchLoading?.percentage}
                          disabled={switchLoading?.percentage}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "percentage",
                              data: { percentage: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Next Steps</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ nextSteps: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.nextSteps}
                          loading={switchLoading?.nextSteps}
                          disabled={switchLoading?.nextSteps}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "nextSteps",
                              data: { nextSteps: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>
                        Proposed Schedule
                      </span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ proposedSchedule: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.proposedSchedule}
                          loading={switchLoading?.proposedSchedule}
                          disabled={switchLoading?.proposedSchedule}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "proposedSchedule",
                              data: {
                                proposedSchedule: toggle,
                              },
                              openModal: "proposedSchedule",
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Financing</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ financing: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.financing}
                          loading={switchLoading?.financing}
                          disabled={switchLoading?.financing}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "financing",
                              data: { financing: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>
                        Terms & Conditions
                      </span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({
                              termsAndConditions: true,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.termsAndConditions}
                          loading={switchLoading?.termsAndConditions}
                          disabled={switchLoading?.termsAndConditions}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "termsAndConditions",
                              data: { termsAndConditions: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Attachments</span>
                      <div className="d-flex gap-2">
                        <span
                          aria-disabled
                          onClick={() =>
                            setEditProposalSettings({ attachments: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {editIconContrat}
                        </span>
                        <Switch
                          checked={settings?.attachments}
                          loading={switchLoading?.attachments}
                          disabled={switchLoading?.attachments}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "attachments",
                              data: { attachments: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex aligin-items-center gap-2 justify-content-between">
                      <span style={{ fontWeight: "500" }}>Signature</span>
                      <div className="d-flex gap-2">
                        <Switch
                          checked={settings?.signature}
                          loading={switchLoading?.signature}
                          disabled={switchLoading?.signature}
                          onChange={(toggle) =>
                            handleChangeToggle({
                              name: "signature",
                              data: { signature: toggle },
                            })
                          }
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </Panel>
      </Collapse>
      <CustomModal
        isModalOpen={createProposal}
        handleCancel={() => setCreateProposal(false)}
        closeIcon={<></>}
        title="Create New Proposal"
        destroyOnClose
      >
        <Form layout="vertical" className="formstate">
          <Form.Item label="Title">
            <Input
              placeholder="Enter Proposal Name"
              value={proposalName}
              onChange={(e) => {
                setProposalName(e?.target?.value);
                if (e?.target?.value?.length > MAX_SIZE_TITLE) {
                  setErrors((prev) => ({
                    ...prev,
                    title: `Length must be less than ${MAX_SIZE_TITLE} chars`,
                  }));
                }
              }}
            />
            {errors?.title && (
              <p className="m-0 text-danger">{errors?.title}</p>
            )}
          </Form.Item>
        </Form>
        <Button
          className="apply-btn"
          type="primary"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Add Proposal
        </Button>
      </CustomModal>
    </>
  );
};

export default ProposalSettings;
