/* eslint-disable no-unused-vars */
import api from "./api";

export const getProposalSettingDetails = async (estimationId) => {
  const response = await api.request({
    url: `/v3/proposal/estimate/${estimationId}`,
    method: "GET",
  });
  return response;
};

export const getProposalSettingList = async () => {
  const response = await api.request({
    url: `/v3/proposal/settings`,
    method: "GET",
  });
  return response;
};

export const getProposalById = async (proposalId) => {
  const response = await api.request({
    url: `/v3/proposal/settings/${proposalId}`,
    method: "GET",
  });
  return response;
};

export const updateProposalSettings = async ({
  estimationId,
  data,
  isDefault,
  proposalSettingId,
}) => {
  const response = await api.request({
    url: `/v3/proposal/estimate/settings/${estimationId}${
      isDefault && proposalSettingId
        ? `?default=${isDefault}&proposalSettingId=${proposalSettingId}`
        : ""
    }`,
    method: "PUT",
    data,
  });
  return response;
};

export const copyProposalShareableLink = async ({ estId, proposalId }) => {
  const response = await api.request({
    url: `/v3/proposal/estimate/${estId}/proposal/${proposalId}/copy-link`,
    method: "GET",
  });
  return response;
};

export const sendShareableLink = async ({ estId, proposalId }) => {
  const response = await api.request({
    url: `/v3/proposal/estimate/send-sharable-link`,
    method: "POST",
    data: {
      estimateId: estId,
      proposalId,
    },
  });
  return response;
};

export const sendProposalToCustomer = (data) =>
  api.request({
    url: "/v3/proposal/estimate/customer/send-proposal",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const signProposalByOrganization = async ({ estId, data }) =>
  api.request({
    url: `/v3/proposal/estimate/${estId}/proposal/organization/signature`,
    method: "POST",
    data,
  });
export const signProposalByClient = async ({ estId, data }) =>
  api.request({
    url: `/v3/proposal/estimate/customer/${estId}/proposal/signature`,
    method: "POST",
    data,
  });

export const updateProposalCoverPage = async ({ estId, data, isDefault }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/cover-page${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProposalAboutUs = async ({ estId, data, isDefault }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/about-us${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProposalPastProjects = async ({ estId, data, isDefault }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/past-project${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProposalNextSteps = async ({ estId, data, isDefault }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/next-steps${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProposalProposedSchedule = async ({
  estId,
  data,
  isDefault,
}) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/proposal-schedule${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const updateProposalFinancing = async ({ estId, data, isDefault }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/financing${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createNewProposal = async ({ data }) =>
  api.request({
    url: `/v3/proposal/settings`,
    method: "POST",
    data,
  });

export const getProposalDetailsWithId = async ({ id }) =>
  api.request({
    url: `/v3/proposal/settings/${id}`,
    method: "GET",
  });

export const saveProposalChanges = async ({ data, proposalId }) =>
  api.request({
    url: `/v3/proposal/settings/${proposalId}`,
    data,
    method: "PUT",
  });

/* Ralph: Check whether it has in new version or not */
export const updateProposalTermsCondition = async ({
  data,
  isDefault,
  estId,
}) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/terms-and-conditions${
      isDefault ? "?default=true" : ""
    }`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getProposalForCustomer = async ({ token }) =>
  api.request({
    url: `/v3/proposal/estimate/customer/${token}`,
    method: "GET",
  });

export const updateAttachmentFile = async ({ estId, data }) =>
  api.request({
    url: `/v3/proposal/estimate/settings/${estId}/attachments`,
    method: "PUT",
    data,
  });

export const getOrganizationDetails = async ({ estimateId }) =>
  api.request({
    url: `/v3/proposal/estimate/${estimateId}/proposal/organization-information`,
    method: "GET",
  });
