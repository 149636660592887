/* eslint-disable no-unused-vars */
import "./content-style.css";

import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Image as AntdImage,
  message,
  Modal,
  Tooltip,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getContractDetailsByTokenAPI,
  saveContractAPI,
  sendConfirmationEmailAndSaveContractPdfForClientAPI,
  signContractByClientAPI,
  signContractByOrganizationAPI,
} from "../../../api/contract";
import { updateEstimationTemplateImageAPI } from "../../../api/formula";
import {
  generateQBPaymentLink,
  generateSquarePaymentLink,
  generateStripePaymentLink,
  getSquareCharge,
} from "../../../api/invoice";
import { updateEstimateLineItemImageAPI } from "../../../api/v2/userEstimation";
// import { updateOrganizationTermAndCondition } from "../../../api/organization";
import defaultCoverPhoto from "../../../images/defaultCoverPhoto.jpg";
import { modalToggleAction } from "../../../redux/invoice/invoice.action";
import { createValidUrl } from "../../../utils/commonFuctions";
import { fileToBase64 } from "../../../utils/fileBase64";
import { NumberFormat } from "../../../utils/numberFormat";
import regex from "../../../utils/regex";
import { mentions } from "../../../utils/suggestions";
import { payCash } from "../../../utils/svg.file";
import { ESignature } from "../../estimates/contractPreview/e-signature.component";
import CustomModal from "../../invoicing/details/CustomModal";
import EmailModal from "../../invoicing/invoice-setting/emailModal";
import SmallLoader from "../../loader/smallLoader";
import TermsAndConditions from "../../setting/termsandconditions/TermsAndConditions";
import CertificateOfCompletion from "./certificateOfCompletion";
import styles from "./contractPreview.module.css";
import {
  addExtraPercentageAmount,
  downloadBase64File,
  getBase64OfHTMLDivComponentAsPDF,
} from "./helper";
import {
  ACHPaymentPopup,
  CardPaymentFeePopup,
  CreditCardPaymentPopup,
  PayDepositePopup,
  QBBillingDetail,
  SquareAchPaymentModal,
} from "./Modal";
import SendContractEmail from "./sendContractEmail";

const SIGNATURE_FOR = Object.freeze({
  CONTRACTOR: "Contractor",
  CUSTOMER: "Customer",
});
function toDataURL(url, callback) {
  const img = new Image();
  img.crossOrigin = "Anonymous"; // Enable cross-origin requests if needed
  img.onload = function () {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Determine the MIME type based on the image's file extension
    let mimeType;
    if (url.endsWith(".jpg") || url.endsWith(".jpeg")) {
      mimeType = "image/jpeg";
    } else if (url.endsWith(".png")) {
      mimeType = "image/png";
    } else if (url.endsWith(".gif")) {
      mimeType = "image/gif";
    } else {
      // Default to PNG if the format is not recognized
      mimeType = "image/png";
    }

    const dataURL = canvas.toDataURL(mimeType);
    callback(dataURL);
  };
  img.src = url;
}

function compareUrlWithBase64(url, base64) {
  return new Promise((resolve) => {
    if (base64 === "") resolve(false);
    else {
      toDataURL(url, (e) => {
        resolve(e === base64);
      });
    }
  });
}

function Content({
  estimateDetails,
  projectCharges,
  currentUser,
  customerDetails,
  organizationDetails,
  editable,
  token,
  needToShowTermsButton = true,
  depositPopupDetail,
  setDepositPopupDetail,
  hasPermission = false,
  customerView = false,
}) {
  const editorRef = useRef(null);
  const history = useNavigate();
  const [sendingEmail, setSendingEmail] = useState(false);
  const organization = useSelector((state) => state.organization);
  const { squarePaymentInfo, modalToggle } = useSelector(
    (state) => state?.invoice,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  // this state is used to pause original organization coverPhoto if coverPhoto
  // is updated by contractor
  const [isSettingEstimation, setIsSettingEstimation] = useState(true);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [organizationLogoBase64, setOrganizationLogoBase64] = useState("");
  const [estimationDetails, setEstimationDetails] = useState({});
  const [coverPhoto, setCoverPhoto] = useState("");
  const [logo, setLogo] = useState("");
  const [teamPhoto, setTeamPhoto] = useState("");
  const [contractorSignature, setContractorSignature] = useState("");
  const [customerSignature, setCustomerSignature] = useState("");
  const [isOpenTermAndConditions, setIsOpenTermAndConditions] = useState("0");
  const [eSignatureOpenFor, setESignatureOpenFor] = useState("");
  // this state is used to show warning to employee of organization, if he updated `contractorSignature`
  // and not signed the contract, but trying to add customer signature.
  const [showCustomerSignatureWarning, setShowCustomerSignatureWarning] =
    useState(false);
  const [contractorSigningContract, setContractorSigningContract] =
    useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [isEditTermAndCondition, setIsEditTermAndCondition] = useState(false);
  const [editedTermAndCondition, setEditedTermAndCondition] = useState(
    EditorState.createEmpty(),
  );
  const [showCardPayment, setShowCardPayment] = useState(false);
  const [showACHPayment, setShowACHPayment] = useState(false);
  const [showCardPaymentFee, setShowCardPaymentFee] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [prevPopup, setPrevPopup] = useState("");
  const [qbCardPayment, setQbCardPayment] = useState(false);
  const [qbAchPayment, setQbAchPayment] = useState(false);
  const [squareACHPayment, setSquareACHPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [editingLineItemImage, setEditingLineItemImage] = useState({
    lineItemDraggableId: "",
    newImage: "",
    formulaId: "",
  });
  const [isUpdatingLineItemImage, setIsUpdatingLineItemImage] = useState(false);
  // const user = useSelector((state) => state.user);
  // const [html, setHtml] = useState("");

  const [openEditor, setOpenEditor] = useState(false);
  const [billingEmail, setBillingEmail] = useState("");
  const [billingError, setBillingError] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [validFileError, setValidFileError] = useState(false);

  const updateEstimateImageOnly = async () => {
    setIsUpdatingLineItemImage(true);
    message.loading({
      key: "updateEstimate",
      content: "Updating Line Item image",
      duration: 0,
    });

    const estimationId = estimationDetails._id;
    const body = {
      draggableId: editingLineItemImage.lineItemDraggableId,
      newImage: editingLineItemImage.newImage,
    };
    const res = await updateEstimateLineItemImageAPI(estimationId, body);
    console.log({ res });
    if (res.remote === "success") {
      message.success({
        key: "updateEstimate",
        content: "Updated",
        duration: 2,
      });
      setEstimationDetails(res.data.data);
    } else {
      message.error({
        key: "updateEstimate",
        content: "Failed to update image",
        duration: 2,
      });
    }
    setEditingLineItemImage({
      formulaId: "",
      lineItemDraggableId: "",
      newImage: "",
    });
    setIsUpdatingLineItemImage(false);
  };
  const updateEstimationTemplateImageWithEstimate = async () => {
    setIsUpdatingLineItemImage(true);
    message.loading({
      key: "updateEstimateWithEstimationTemplate",
      content: "Updating Line Item Image...",
      duration: 0,
    });

    const estimationId = estimationDetails._id;
    const body = {
      draggableId: editingLineItemImage.lineItemDraggableId,
      newImage: editingLineItemImage.newImage,
    };
    const res = await updateEstimateLineItemImageAPI(estimationId, body);
    if (res.remote === "success") {
      message.loading({
        key: "updateEstimateWithEstimationTemplate",
        content: "Updating Estimate Template...",
        duration: 0,
      });
      const data = await updateEstimationTemplateImageAPI(
        editingLineItemImage.formulaId,
        {
          newImage: body.newImage,
        },
      );
      if (data.remote === "success") {
        message.success({
          key: "updateEstimateWithEstimationTemplate",
          content: "Updated Estimate Template",
          duration: 2,
        });
      } else {
        message.error({
          key: "updateEstimateWithEstimationTemplate",
          content: "Failed to update Estimation Template",
          duration: 2,
        });
      }
      setEstimationDetails(res.data.data);
    } else {
      message.error({
        key: "updateEstimateWithEstimationTemplate",
        content: "Failed to update image",
        duration: 2,
      });
    }
    setEditingLineItemImage({
      formulaId: "",
      lineItemDraggableId: "",
      newImage: "",
    });
    setIsUpdatingLineItemImage(false);
  };

  const handleOpenEditor = () => {
    setOpenEditor(true);
  };

  const handleCancelEditor = () => {
    setOpenEditor(false);
  };
  const recallContractDetails = async () => {
    const res = await getContractDetailsByTokenAPI({ token });
    if (res.remote === "success") {
      if (
        res.data.estimateDetails?.estimateDetails?.signedByClient?.signature
      ) {
        const isDepositPopupViewed =
          res.data?.estimateDetails.estimateDetails.isDepositPopupViewed;
        const depositPopup =
          res.data?.estimateDetails?.organizationDetails?.invoiceSettings
            .depositPopup;
        const shouldShowPopup = !isDepositPopupViewed && depositPopup;

        setDepositPopupDetail({
          invoiceSettings: {
            ...res.data?.estimateDetails?.organizationDetails?.invoiceSettings,
            openModal: shouldShowPopup,
          },
        });
      }
    }
  };
  const onSignatureSave = (e, title, fullName, signatureStyle) => {
    switch (eSignatureOpenFor) {
      case SIGNATURE_FOR.CONTRACTOR:
        if (e) {
          handleSignatureByOrganization(e, fullName, signatureStyle);
        }
        break;
      case SIGNATURE_FOR.CUSTOMER:
        if (e) {
          setCustomerSignature(e);
          setGeneratingPdf(true);
          setIsOpenTermAndConditions("1");
          setEstimationDetails((prevState) => ({
            ...prevState,
            signedByClient: {
              ...(prevState.signedByClient || {}),
              date: new Date().toISOString(),
              name: fullName,
            },
          }));
          // waiting to update state
          setTimeout(
            () => handleAddClientSignature(e, fullName, signatureStyle),
            1000,
          );
        }
        break;
      default:
        break;
    }
    setESignatureOpenFor("");
  };
  const handleAddClientSignature = async (
    customerSignature,
    fullName,
    signatureStyle,
  ) => {
    message.loading({
      content: "Signing Contract...",
      key: "updatable",
      duration: 0,
    });
    const payload = {
      projectId: customerDetails._id,
      estimationId: estimationDetails._id,
      signedByEmployeeId: currentUser.id,
      customerSignature,
      customerName: fullName,
      signatureStyle,
    };

    if (!editable) {
      delete payload.signedByEmployeeId;
    }
    const res = await signContractByClientAPI(payload, token);

    if (res.remote === "success") {
      setEstimationDetails(res.data.updatedEstimation);
      // send confirmation mail
      // The region we are not send email when signing contract. That we need to generate PDF with certificate
      // And certificate is generated after estimate is signed by client.
      setTimeout(async () => {
        await sendConfirmationEmailAndSaveContractPdfForClient();
      }, 500);
    } else {
      message.error({
        content: "Something went wrong!",
        key: "updatable",
        duration: 2,
      });
      setShowCustomerSignatureWarning(true);
      setEstimationDetails((prevState) => ({
        ...prevState,
        signedByClient: {},
      }));
    }
    //----------------
    // setIsOpenTermAndConditions("0");
    // setGeneratingPdf(false);
    //----------------
  };

  const sendConfirmationEmailAndSaveContractPdfForClient = async () => {
    message.loading({
      content: "Confirming Signature...",
      key: "updatable",
      duration: 0,
    });
    const signedContractPdf = await getBase64OfHTMLDivComponentAsPDF(
      "divToPrint",
      "certificateOfCompletion",
    );
    const payload = {
      contractPdf: signedContractPdf,
      organizationId: organizationDetails._id,
      estimationId: estimationDetails._id,
    };
    const res = await sendConfirmationEmailAndSaveContractPdfForClientAPI(
      payload,
      token,
    );
    if (res.remote === "success") {
      const status = res?.data?.updatedEstimation?.status;
      const currentLocation = `${location.pathname}${location?.search}`;
      message.success({
        content: "Signed Contract Successfully",
        key: "updatable",
        duration: 2,
      });
      if (status === "Signed" && hasPermission) {
        history(currentLocation, {
          state: {
            redirectTo: `/v2/customer-details?customerId=${
              res?.data?.updatedEstimation?.projectId
            }&scheduledCustomer=true&panel=invoicing&signedUserEstimationId=${
              res?.data?.updatedEstimation?._id
            }&redirectTo=${encodeURIComponent("/v2/estimating")}`,
          },
        });
      }

      // if (token) {
      await recallContractDetails();
      // }
    } else {
      message.error({
        content: "Something went wrong while confirming signature!",
        key: "updatable",
        duration: 2,
      });
    }
    setIsOpenTermAndConditions("0");
    setGeneratingPdf(false);
  };

  const saveContract = useCallback(
    async ({ coverPhoto, termAndCondition }) => {
      const key = "saveContract";
      message.loading({ content: "Saving...", key, duration: 0 });
      const payload = {
        estimateId: estimationDetails._id,
      };
      if (coverPhoto) {
        payload.coverImage = coverPhoto;
      }
      if (termAndCondition) {
        payload.termAndCondition = termAndCondition;
      }
      const res = await saveContractAPI(payload);
      if (res.remote === "success") {
        message.success({ content: "Saved", key, duration: 2 });
      } else {
        message.error({ content: "Something went wrong!", key, duration: 2 });
      }
      console.log({ res });
    },
    [estimationDetails._id],
  );

  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    setIsOpenTermAndConditions("1");
    setTimeout(async () => {
      const base64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      downloadBase64File(
        base64,
        `${customerDetails.fullName}-${estimationDetails.estimationNumber}.pdf`,
      );
      setIsOpenTermAndConditions("0");
      setGeneratingPdf(false);
    }, 1000);
  };

  const handleSignatureByOrganization = async (
    contractorSignature,
    contractorName,
    signatureStyle,
  ) => {
    const key = "signatureByOrganization";
    message.loading({ content: "Signing Contract...", key, duration: 0 });
    setContractorSignature(contractorSignature);
    setIsOpenTermAndConditions("1"); // need to open term and conditions when signing contract
    setGeneratingPdf(true);
    setContractorSigningContract(true);
    // this will add date before generating date
    setEstimationDetails((prevState) => ({
      ...prevState,
      signedByOrganization: {
        ...(prevState.signedByOrganization || {}),
        date: new Date().toISOString(),
        name: contractorName,
      },
    }));
    setTimeout(async () => {
      const signedContractPdf = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
      );
      const res = await signContractByOrganizationAPI({
        contractorSignature,
        estimationId: estimationDetails._id,
        signedContractPdf,
        contractorName,
        signatureStyle,
      });
      if (res.remote === "success") {
        setEstimationDetails(res.data.updatedEstimation);
        const { signedByOrganization } = res.data.updatedEstimation;
        toDataURL(
          createValidUrl(signedByOrganization.signature),
          setContractorSignature,
        );
        message.success({ content: "Signed Successfully", key, duration: 2 });
      } else {
        message.error({ content: "Something went wrong!", key, duration: 2 });
      }
      setContractorSigningContract(false);
      setGeneratingPdf(false);
      setIsOpenTermAndConditions("0");
    }, 1000);
  };

  // Function to handle outside clicks
  const handleOutsideTermAndConditionClick = useCallback(
    (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        setIsEditTermAndCondition(false);
        setTimeout(() => {
          // save contract also with updated termAndConditions
          saveContract({
            termAndCondition: estimationDetails.termAndCondition,
          });
        }, 500);
      }
    },
    [estimationDetails.termAndCondition, saveContract],
  );
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setDepositPopupDetail((prev) => ({
      ...prev,
      invoiceSettings: {
        ...prev.invoiceSettings,
        openModal: false,
      },
    }));
  };
  // Add event listener on component mount
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideTermAndConditionClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideTermAndConditionClick,
      );
    };
  }, [handleOutsideTermAndConditionClick]);

  useEffect(() => {
    setIsSettingEstimation(() => {
      if (estimateDetails?._id) {
        setEstimationDetails(estimateDetails || {});
        return false;
      }
      return true;
    });
  }, [estimateDetails]);
  useEffect(() => {
    if (!isSettingEstimation) {
      const coverPhoto =
        estimationDetails.coverPhoto ||
        organizationDetails.estimationCoverPhoto ||
        organization.coverPhoto
          ? createValidUrl(
              estimationDetails.coverPhoto ||
                organizationDetails.estimationCoverPhoto ||
                organization.coverPhoto,
            )
          : "";
      if (coverPhoto) {
        toDataURL(coverPhoto, (e) => {
          if (e.includes("image")) {
            setCoverPhoto(e);
          }
        });
      }
    }
    if (organizationDetails.logo) {
      const logo = createValidUrl(organizationDetails.logo);
      toDataURL(logo, setLogo);
    }

    if (organizationDetails.teamPhoto) {
      const teamPhoto = createValidUrl(organizationDetails.teamPhoto);
      toDataURL(teamPhoto, setTeamPhoto);
    }

    if (organizationDetails.signature) {
      const organizationSignature = createValidUrl(
        organizationDetails.signature,
      );
      toDataURL(organizationSignature, setOrganizationLogoBase64);
    }

    if (estimationDetails.signedByOrganization?.signature) {
      const contractorSignature = createValidUrl(
        estimationDetails.signedByOrganization?.signature,
      );
      toDataURL(contractorSignature, setContractorSignature);
    }

    if (estimationDetails.signedByClient?.signature) {
      const contractorSignature = createValidUrl(
        estimationDetails.signedByClient?.signature,
      );
      toDataURL(contractorSignature, setCustomerSignature);
    }

    // if(organization)
  }, [estimationDetails, organizationDetails, isSettingEstimation]);

  const handleOk = () => {
    setIsModalVisible(false);
    // handleCancel();
  };

  const handleCardPaymentCancel = ({
    toggle,
    toggleNext = false,
    prevPopup = "",
  }) => {
    setShowCardPayment(toggle);
    setPrevPopup(prevPopup);
    if (
      organizationDetails?.invoiceSettings?.paymentIntegration
        ?.extraChargeForCardPayment?.chargeEnabled
    ) {
      console.log(organizationDetails);
      setShowCardPaymentFee(toggleNext);
      setShowCardPayment(false);
      setShowPaymentOptions(false);
    } else {
      handleConfirmCardPayment();
    }
  };

  const handleBackToCard = () => {
    if (prevPopup === "card") {
      setShowCardPayment(true);
      setShowPaymentOptions(false);
      setShowCardPaymentFee(false);
    }
    if (prevPopup === "options") {
      setShowPaymentOptions(true);
      setShowCardPayment(false);
      setShowCardPaymentFee(false);
    }
  };

  const handleCancelQBCard = () => {
    dispatch(
      modalToggleAction({
        qbBillingDetails: false,
      }),
    );
    setBillingEmail("");
    setBillingError("");
  };

  const handleBillingEmailChange = (e) => {
    const { value } = e?.target;
    setBillingEmail(value);
    setBillingError(
      !value
        ? "Please enter billing email"
        : !regex.emailRegex.test(value)
        ? "Billing email is invalid"
        : "",
    );
  };

  const handleCancelSquareAch = () => {
    setSquareACHPayment(false);
  };

  const handleTokenizeResponse = async (token) => {
    const authToken = new URLSearchParams(location.search).get("token");
    const data = {
      paymentToken: token?.token,
    };
    console.log(" depositPopupDetail", depositPopupDetail);
    const response = await getSquareCharge(
      authToken,
      data,
      depositPopupDetail?.invoiceSettings?.invoice?._id,
    );
    if (response.remote === "success") {
      message.success(
        "Payment made successful, please wait for the bank to confirm the payment",
      );
      setSquareACHPayment(false);
      setPaymentLoading(false);
    }
  };

  const openLinkNewTab = (url) => {
    try {
      const a = document.getElementById("open-new-tab");
      a.setAttribute("href", url);
      console.log("first #401", a);
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      a.dispatchEvent(clickEvent);
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleQBPayment = async ({ type }) => {
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    setBtnLoading(true);
    if (billingEmail) {
      const response = await generateQBPaymentLink({
        token,
        type,
        invoiceId,
        billingEmail,
      });
      if (response.remote === "success") {
        if (response?.data?.data?.link) {
          openLinkNewTab(response.data.data.link);
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        setBillingEmail("");
        setBillingError("");
        if (type === "CARD") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        if (type === "ACH") {
          dispatch(
            modalToggleAction({
              qbBillingDetails: false,
            }),
          );
        }
        setBtnLoading(false);

        // if (window) window.open(response.data.data.link, "_blank").focus();
        // window.location.href = response.data.data.link;
      } else {
        message.error("Please check the email as its seems invalid");
        setBtnLoading(false);
      }
    } else {
      setBillingError("Please enter billing email");
      setBtnLoading(false);
    }
  };

  const handleConfirmCardPayment = async () => {
    const paymentMethod =
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration;
    const token = new URLSearchParams(location.search).get("token");
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    console.log("paymentMethod", paymentMethod);
    setPaymentType("");
    if (paymentMethod === "quickbooks") {
      if (token) {
        setPaymentType("CARD");
        setShowCardPaymentFee(false);
        dispatch(
          modalToggleAction({
            qbBillingDetails: true,
          }),
        );
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "stripe") {
      if (token) {
        const response = await generateStripePaymentLink(
          token,
          "CARD",
          invoiceId,
        );
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          setShowCardPaymentFee(false);
        } else {
          message.error(response?.errors?.errors?.error);
        }
      } else {
        message.error("Token not provided");
      }
    }
    if (paymentMethod === "square") {
      if (token) {
        const response = await generateSquarePaymentLink({
          token,
          type: "CARD",
          invoiceId,
        });
        if (response.remote === "success") {
          if (response?.data?.data?.paymentLink) {
            window.location.href = response.data.data.paymentLink;
          } else {
            message.error(
              "There is some issues with the payments, Please try again later.",
            );
          }
          setShowCardPaymentFee(false);
        }
      } else {
        message.error("Token not provided");
      }
    }
  };

  const handleACHPaymentCancel = async () => {
    const paymentMethod =
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration;
    const token = new URLSearchParams(location.search).get("token");
    const invoiceId = depositPopupDetail?.invoiceSettings?.invoice?._id;
    setPaymentType("");
    if (paymentMethod === "quickbooks") {
      setPaymentType("ACH");
      setShowACHPayment(false);
      dispatch(
        modalToggleAction({
          qbBillingDetails: true,
        }),
      );
    }
    if (paymentMethod === "stripe") {
      const response = await generateStripePaymentLink(token, "ACH", invoiceId);
      if (response.remote === "success") {
        if (response?.data?.data?.paymentLink) {
          window.location.href = response.data.data.paymentLink;
        } else {
          message.error(
            "There is some issues with the payments, Please try again later.",
          );
        }
        setShowACHPayment(false);
      } else {
        message.error(response?.errors?.errors?.error);
      }
    }
    if (paymentMethod === "square") {
      setSquareACHPayment(true);
    }
  };

  const openPopups = () => {
    setIsModalVisible(false);
    const paymentIntegration =
      organizationDetails?.invoiceSettings?.paymentIntegration;
    const isPaymentIntegrated =
      depositPopupDetail?.invoiceSettings?.paymentIntegration?.[
        depositPopupDetail?.invoiceSettings?.paymentIntegration
          ?.activeIntegration
      ]?.accountId;
    if (
      paymentIntegration?.allowCardPayment &&
      paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowPaymentOptions(true);
    }
    if (
      paymentIntegration?.allowCardPayment &&
      !paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowCardPayment(true);
    }
    if (
      !paymentIntegration?.allowCardPayment &&
      paymentIntegration?.allowAchPayment &&
      isPaymentIntegrated
    ) {
      setShowACHPayment(true);
    }
    if (!isPaymentIntegrated) {
      setIsModalVisible(true);
    }
  };

  return (
    <>
      <div>
        <a
          style={{ display: "none" }}
          id="open-new-tab"
          href="#!"
          target="_blank"
          rel="noreferrer"
        >
          click
        </a>
        {token && customerView && (
          <div className="mx-973">
            <Button
              type="primary"
              onClick={handleDownloadPdf}
              disabled={generatingPdf}
              className="my-2 download-pdf-btn"
              icon={
                <>
                  {generatingPdf && <SmallLoader />}
                  <DownloadOutlined />
                </>
              }
            >
              Download
            </Button>
          </div>
        )}
      </div>
      <div
        id="divToPrint"
        style={{ display: "table", width: "100%", boxShadow: "none" }}
        className="preview-card max-center"
      >
        {coverPhoto ? (
          <img
            style={{ borderRadius: "25px", width: "100%" }}
            src={coverPhoto}
            alt="coverPhoto"
          />
        ) : (
          ""
        )}
        {!generatingPdf &&
          editable &&
          !estimationDetails.signedByClient?._id && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "15px",
                marginRight: "10px",
              }}
              className="preview-imgd"
            >
              <ImgCrop aspect={4031 / 1410}>
                <Upload
                  ghost
                  maxCount={1}
                  showUploadList={false}
                  customRequest={async (e) => {
                    const coverPhoto = await fileToBase64(e.file);
                    setCoverPhoto(coverPhoto);
                    saveContract({ coverPhoto });
                  }}
                >
                  <Tooltip title="Edit cover photo">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                </Upload>
              </ImgCrop>
            </div>
          )}
        <div
          style={{ textAlign: "right", padding: "15px 24px 0px" }}
          className="preview-title"
        >
          <h5 style={{ margin: "0px", fontSize: "18px", fontWeight: "600" }}>
            {organizationDetails.name}
          </h5>
          <span>Sales: {currentUser.name}</span>
        </div>
        <div
          style={{
            width: "100%",
            float: "left",
            padding: "10px 24px 0px",
          }}
          className={generatingPdf ? "" : "preview-1"}
        >
          <div
            style={{ width: "400px", float: "left" }}
            className={generatingPdf ? "" : "preview-2"}
          >
            <span style={{ fontSize: "12px" }}>Estimation Number: </span>
            <h5
              style={{
                margin: "0px 0px 15px 0px",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {estimationDetails.estimationNumber}
            </h5>
            <span style={{ fontSize: "12px" }}>Prepared For: </span>
            <h5
              style={{
                margin: "0px 0px 15px 0px",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {customerDetails.fullName}
            </h5>
            {customerDetails.address ? (
              <>
                <span style={{ fontSize: "12px" }}>Located At: </span>
                <h5
                  style={{
                    margin: "0px 0px 0px 0px",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {customerDetails.address}
                </h5>
              </>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ width: "234px", float: "right" }}
            className={generatingPdf ? "" : "preview-3"}
          >
            {logo && (
              <img style={{ maxWidth: "100%" }} src={logo} alt="companyLogo" />
            )}
          </div>
        </div>
        <div
          style={{ padding: "0px 24px", float: "left", width: "100%" }}
          className={generatingPdf ? "" : "preview-4"}
        >
          <hr style={{ marginBottom: "0px" }} />
        </div>
        <div
          style={{
            width: "100%",
            float: "left",
            padding: "15px 25px 0",
          }}
          className={generatingPdf ? "" : "preview-5"}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: organizationDetails.coverNote,
            }}
          />
        </div>
        <div
          style={{ padding: "0px 24px", float: "left", width: "100%" }}
          className={generatingPdf ? "" : "preview-6"}
        >
          <hr style={{ marginBottom: "0px" }} />
        </div>
        <div
          style={{ width: "100%", float: "left", padding: "24px" }}
          className={generatingPdf ? "" : "preview-7"}
        >
          <h5
            style={{
              margin: "0px 0px 15px 0px",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Scope of work
          </h5>
          {estimationDetails?.services?.length
            ? estimationDetails.services.map((item, index) => (
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    background: "#e8f1fd",
                    borderRadius: "25px",
                    marginBottom: "15px",
                  }}
                  className={generatingPdf ? "" : "preview-8"}
                  key={index}
                >
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      background: "#3483fa",
                      borderRadius: "25px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "75px",
                    }}
                    className={generatingPdf ? "" : "preview-9"}
                  >
                    <>
                      <AntdImage
                        style={{
                          height: "95px",
                          width: "150px",
                        }}
                        src={
                          !item.photo?.includes("onepercent")
                            ? createValidUrl(item.photo)
                            : defaultCoverPhoto
                        }
                        // crossOrigin="anonymous"
                        alt={item.title}
                      />
                    </>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "15px",
                        paddingLeft: "25px",
                        width: "100%",
                      }}
                      className={generatingPdf ? "" : "preview-10"}
                    >
                      <div className="d-flex">
                        <h5
                          style={{
                            fontSize: "24px",
                            color: "#fff",
                            margin: "0",
                          }}
                        >
                          {item.displayName || item.title}
                        </h5>
                        {generatingPdf || (token && customerView) ? (
                          ""
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              marginRight: "10px",
                            }}
                            className="preview-imgd"
                          >
                            <ImgCrop aspect={150 / 95}>
                              <Upload
                                ghost
                                maxCount={1}
                                showUploadList={false}
                                customRequest={async (e) => {
                                  const newPhoto = await fileToBase64(e.file);
                                  setEditingLineItemImage({
                                    formulaId: item._id,
                                    lineItemDraggableId: item.draggableId,
                                    newImage: newPhoto,
                                  });
                                }}
                                accept="image/*"
                              >
                                <Tooltip title="Edit line item photo">
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<EditOutlined />}
                                  />
                                </Tooltip>
                              </Upload>
                            </ImgCrop>
                          </div>
                        )}
                      </div>
                      <h5
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          margin: "0",
                        }}
                      >
                        {NumberFormat(
                          item.actualServices[0]?.totalServiceCharge,
                        )}
                      </h5>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", float: "left", padding: "15px" }}
                    className={generatingPdf ? "" : "preview-11"}
                  >
                    <div
                      className={styles.clientContract}
                      dangerouslySetInnerHTML={{
                        __html: generatingPdf
                          ? item.processedClientContract
                              ?.replaceAll("\n", "<br>")
                              .replace(
                                /<a.*?><strong>(.*?)<\/strong><\/a>/g,
                                "$1",
                              )
                              .replace(/<strong>/g, "")
                              .replace(/<strong\/>/g, "")
                          : item.processedClientContract?.replaceAll(
                              "\n",
                              "<br>",
                            ),
                      }}
                    />
                  </div>
                </div>
              ))
            : ""}

          <div
            className={`text-right mt-4 pb-4 pe-lg-3 ${
              generatingPdf ? "" : "preview-12"
            }`}
            style={{ float: "left", width: "100%" }}
          >
            <ul className="list-inline">
              <li>
                <b>Subtotal</b> <abbr>:</abbr>
                <div className="righttotal">
                  {NumberFormat(projectCharges.projectCharge)}
                </div>
              </li>
              {/** no need in soft launch -> Oscar */}
              {/* {discount ? (
                <li>
                  <b>Discount</b> <abbr>:</abbr>
                  <div className="righttotal">{NumberFormat(discount)}</div>
                </li>
              ) : (
                ""
              )} */}
              {estimationDetails.tax?.status &&
              estimationDetails.tax?.percentage > 0 ? (
                <li>
                  <b>Tax &nbsp; {estimationDetails.tax?.percentage}%</b>
                  <abbr>:</abbr>
                  <div className="righttotal">
                    {" "}
                    {NumberFormat(projectCharges.taxAmount)}
                  </div>
                </li>
              ) : (
                ""
              )}
              <li>
                <b>Contract Total</b>
                <abbr>:</abbr>
                <div className="righttotal">
                  {NumberFormat(projectCharges.totalChargeAfterDiscountAndTax)}
                </div>
              </li>
            </ul>
          </div>
          <div
            style={{ width: "100%", float: "left" }}
            className={generatingPdf ? "" : "preview-13"}
          >
            <p>
              Are you ready to move forward? Contact the salesperson you were
              working with right away to get on our schedule!
            </p>
            <h5
              style={{ fontSize: "18px", color: "#fff", margin: "0" }}
              className="text-black"
            >
              Payment Terms for Project :
            </h5>
            <table style={{ width: "100%" }}>
              {estimationDetails.paymentTerms?.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "5px 0px" }}>
                    <b>{item.title}</b>
                  </td>
                  <td style={{ padding: "5px 0px 5px 25px" }}>
                    {NumberFormat(item.totalPrice)}
                  </td>
                </tr>
              ))}
            </table>
            <span
              style={{
                display: "block",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              Note: Payment terms will change if change orders are made
            </span>
            <div
              style={{ width: "100%", float: "left", marginBottom: "10px" }}
              className={generatingPdf ? "" : "preview-14"}
            >
              {organizationDetails.teamPhoto ? (
                <>
                  <h5
                    style={{
                      margin: "15px 0px 15px 0px",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    Meet Our Team!
                  </h5>
                  <img
                    style={{ borderRadius: "25px", width: "100%" }}
                    src={teamPhoto}
                    alt="teamPhoto"
                  />
                </>
              ) : (
                ""
              )}

              {(organization?.termAndCondition ||
                organizationDetails?.termAndCondition ||
                estimationDetails.termAndCondition) &&
              !(
                estimationDetails.termAndCondition ||
                organization?.termAndCondition ||
                organizationDetails?.termAndCondition
              )
                ?.replace("\n", "")
                ?.replace("\r", "")
                ?.trim()
                .match(regex.emptyHtml, "") ? (
                <div style={{ marginTop: "10px" }}>
                  <Collapse
                    activeKey={isOpenTermAndConditions}
                    className="collapse-bordrd"
                    onChange={(e) => {
                      if (e.length !== 2) {
                        setIsOpenTermAndConditions(["0"]);
                      } else {
                        setIsOpenTermAndConditions(["1"]);
                      }
                    }}
                  >
                    <Collapse.Panel
                      className="border-radius"
                      header="Terms For Project"
                      key="1"
                    >
                      {isEditTermAndCondition && editable ? (
                        <div ref={editorRef}>
                          <Editor
                            editorState={editedTermAndCondition}
                            onEditorStateChange={(editorState) => {
                              setEditedTermAndCondition(editorState);
                              let html = draftToHtml(
                                convertToRaw(editorState.getCurrentContent()),
                              );

                              if (html.includes("@{{")) {
                                html = html.replace(/<\/?a[^>]*>/g, "");
                                html = html.replaceAll("@{{", "{{");
                              }
                              setEstimationDetails((prevState) => ({
                                ...prevState,
                                termAndCondition: html,
                              }));
                            }}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            mention={{
                              trigger: "@",
                              separator: " ",
                              suggestions: mentions,
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            const contentBlock = htmlToDraft(
                              (
                                estimationDetails.termAndCondition ||
                                organization?.termAndCondition ||
                                organizationDetails?.termAndCondition
                              ).replaceAll("{{", "@{{"),
                            );
                            const contentState =
                              ContentState.createFromBlockArray(
                                contentBlock.contentBlocks,
                              );
                            EditorState.createWithContent(contentState);
                            setEditedTermAndCondition(
                              EditorState.createWithContent(contentState),
                            );

                            setIsEditTermAndCondition(true);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: (
                              estimationDetails.termAndCondition ||
                              organization?.termAndCondition ||
                              organizationDetails?.termAndCondition ||
                              ""
                            )
                              .replace(
                                /{{COMPANY_NAME}}/g,
                                organizationDetails?.name,
                              )
                              .replace(
                                /{{COMPANYNAME}}/g,
                                organizationDetails?.name,
                              )
                              .replace(
                                /{{CUSTOMER_NAME}}/g,
                                customerDetails?.fullName,
                              )
                              .replace(
                                /{{CUSTOMERNAME}}/g,
                                customerDetails?.fullName,
                              )
                              .replace(
                                /{{CUSTOMER_ADDRESS}}/g,
                                customerDetails?.address,
                              )
                              .replace(
                                /{{CUSTOMERADDRESS}}/g,
                                customerDetails?.address,
                              )
                              .replace(
                                /{{ADDRESS}}/g,
                                organizationDetails?.address,
                              )
                              .replace(
                                /{{USER_NAME_PHONE_NUMBER}}/g,
                                () =>
                                  `1 ${formatPhoneNumber(
                                    `+${
                                      organizationDetails?.phoneNumber || ""
                                    }`,
                                    "NATIONAL",
                                  )}`,
                              )
                              .replace(
                                /{{COMPANY_PHONE_NUMBER}}/g,
                                () =>
                                  `1 ${formatPhoneNumber(
                                    `+${customerDetails?.contactNo || ""}`,
                                    "NATIONAL",
                                  )}`,
                              ),
                          }}
                        />
                      )}
                    </Collapse.Panel>
                  </Collapse>
                </div>
              ) : (
                ""
              )}
            </div>
            {!generatingPdf &&
              !organization?.termAndCondition &&
              !organizationDetails?.termAndCondition &&
              needToShowTermsButton && (
                <Button type="primary" onClick={handleOpenEditor}>
                  Add Terms & Conditions
                </Button>
              )}
            <div
              style={{ float: "left", width: "100%", marginTop: "15px" }}
              className={generatingPdf ? "" : "preview-15"}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
                className={generatingPdf ? "" : "preview-16"}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-17"}
                >
                  <label style={{ display: "flex", marginBottom: "5px" }}>
                    Customer Signature
                  </label>
                  <div id="div_2">
                    {!estimationDetails?.customerSignature?.image}
                    {customerSignature ? (
                      <img
                        src={customerSignature}
                        alt="CustomerSignature"
                        style={{ height: 50 }}
                      />
                    ) : generatingPdf ? (
                      <div style={{ height: 50 }} />
                    ) : (
                      <Button
                        type="primary"
                        className="add-btn"
                        size="large"
                        onClick={async () => {
                          const isSignable =
                            estimationDetails.signedByOrganization?.date &&
                            (await compareUrlWithBase64(
                              createValidUrl(
                                estimationDetails.signedByOrganization
                                  ?.signature || organizationDetails.signature,
                              ),
                              contractorSignature,
                            ));
                          if (isSignable) {
                            setESignatureOpenFor(SIGNATURE_FOR.CUSTOMER);
                          } else {
                            setShowCustomerSignatureWarning(true);
                          }
                        }}
                      >
                        Add Customer Signature
                      </Button>
                    )}
                    <div
                      style={{
                        border: "0px",
                        width: "100%",
                        borderBottom: "1px solid #ddd",
                        color: "#000",
                        outline: "none",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-18"}
                >
                  <label style={{ display: "flex", marginBottom: "5px" }}>
                    Contractor Signature
                    {!generatingPdf &&
                      editable &&
                      contractorSignature &&
                      !estimationDetails.signedByClient?._id && (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                          }
                        >
                          <EditOutlined />
                        </div>
                      )}
                  </label>
                  <div id="div_2">
                    {contractorSignature ? (
                      <img
                        src={contractorSignature}
                        alt="ContractorSignature"
                        style={{ height: 50 }}
                      />
                    ) : generatingPdf ? (
                      <div style={{ height: 50 }} />
                    ) : !editable ? (
                      estimateDetails.signedByOrganization?.signature ? (
                        <Button
                          type="primary"
                          className="add-btn"
                          size="large"
                          onClick={() =>
                            setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                          }
                          disabled={!editable}
                        >
                          Add Contractor Signature
                        </Button>
                      ) : (
                        <div style={{ height: 50 }} />
                      )
                    ) : (
                      <Button
                        type="primary"
                        className="add-btn"
                        size="large"
                        onClick={() =>
                          setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                        }
                        disabled={!editable}
                      >
                        Add Contractor Signature
                      </Button>
                    )}
                    {/* <div style={{ height: 50 }} /> */}
                    <div
                      style={{
                        border: "0px",
                        width: "100%",
                        borderBottom: "1px solid #ddd",
                        color: "#000",
                        outline: "none",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-19"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Customer Name
                  </label>
                  <input
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                    }}
                    value={estimationDetails.signedByClient?.name}
                  />
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-20"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Company
                  </label>
                  <input
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                    }}
                    value={organizationDetails.name}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-20"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Date
                  </label>
                  <input
                    type="text"
                    value={
                      estimationDetails.signedByClient?.date
                        ? moment
                            .utc(estimationDetails.signedByClient?.date)
                            .local()
                            .format("MM/DD/YYYY")
                        : ""
                    }
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                    }}
                  />
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-21"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Date
                  </label>
                  <input
                    type="text"
                    value={
                      estimationDetails.signedByOrganization?.date
                        ? moment
                            .utc(estimationDetails.signedByOrganization?.date)
                            .local()
                            .format("MM/DD/YYYY")
                        : ""
                    }
                    disabled
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Start */}
        {estimationDetails.signedByClient && (
          <CertificateOfCompletion
            estimationDetails={estimationDetails}
            organizationDetails={organizationDetails}
            customerDetails={customerDetails}
          />
        )}
        {/** End */}
        {editable && (
          <>
            <div
              className="text-right p-lg-4 mb-3 py-4 preview-22"
              style={{ marginRight: "25px" }}
            >
              <Button
                type="primary"
                className="add-btn"
                size="large"
                onClick={async () => {
                  setSendEmail(true);
                }}
                disabled={
                  generatingPdf ||
                  contractorSigningContract ||
                  estimationDetails.signedByClient?._id ||
                  !contractorSignature
                }
              >
                Send to Customer
              </Button>
            </div>
            <div
              className="ant-floating"
              style={{ zIndex: 999, display: "flex", flexDirection: "column" }}
            >
              <Button
                type="primary"
                onClick={handleDownloadPdf}
                disabled={generatingPdf}
              >
                {generatingPdf ? <SmallLoader /> : <DownloadOutlined />}
              </Button>
            </div>
          </>
        )}
      </div>
      <Modal
        visible={showCustomerSignatureWarning}
        onCancel={() => setShowCustomerSignatureWarning(false)}
        title="Warning"
        footer={
          <>
            <Button onClick={() => setShowCustomerSignatureWarning(false)}>
              OK
            </Button>
          </>
        }
      >
        {editable
          ? "The Contractor’s signature is required before the Customer can sign. Please sign the Estimate as the Contractor before continuing with the Customer's signature."
          : "The Contractor Signature is required prior to you signing the estimate. Please reach out to the Contractor if you are ready to sign."}
      </Modal>
      {sendEmail && (
        <Modal
          visible={sendEmail}
          onCancel={() => {
            setFiles(null);
            setFileSizeError(false);
            setValidFileError(false);
            if (!sendingEmail) setSendEmail(false);
          }}
          width={800}
          className="email-preview-modal"
          title={[
            <div className="d-flex align-items-center">
              <span
                className="me-2 lineheight-12 cursor-pointer"
                // onClick={onClose}
              >
                <ArrowLeftOutlined />
              </span>
              Preview
            </div>,
          ]}
          footer={null}
        >
          <SendContractEmail
            sendingEmail={sendingEmail}
            setSendingEmail={setSendingEmail}
            templateName="Estimate"
            onClose={() => {
              setFiles(null);
              setFileSizeError(false);
              setValidFileError(false);
              if (!sendingEmail) setSendEmail(false);
            }}
            estimationId={estimationDetails._id}
            generatingPdf={generatingPdf}
            files={files}
            setFiles={setFiles}
            fileSizeError={fileSizeError}
            setFileSizeError={setFileSizeError}
            validFileError={validFileError}
            setValidFileError={setValidFileError}
          />
        </Modal>
      )}
      {eSignatureOpenFor && (
        <ESignature
          isOpen={!!eSignatureOpenFor}
          onClose={() => setESignatureOpenFor("")}
          onSave={onSignatureSave}
          title={eSignatureOpenFor}
          requiredFullName
          organizationSignature={organizationLogoBase64}
          contractorName={currentUser.name}
          isDefault={eSignatureOpenFor === SIGNATURE_FOR.CONTRACTOR}
        />
      )}
      <Modal
        visible={openEditor}
        onCancel={handleCancelEditor}
        wrapClassName="customModal"
        width={1000}
        title=""
        footer={false}
      >
        <TermsAndConditions
          needBreadcrumb={false}
          needPreview={false}
          className="editor-max-height"
          handleClosePopup={handleCancelEditor}
        />
      </Modal>
      <CustomModal
        name="emilOpen"
        isModalOpen={depositPopupDetail?.invoiceSettings?.openModal}
        closable={false}
        width={800}
      >
        <EmailModal
          action="sign-contract"
          handleClose={handleCancel}
          title={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.title
          }
          welcomeNote={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.welcomeNote
          }
          body={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.popUpTemplate?.body
          }
          payWithCheckCash={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.payWithCheckCash
          }
          payWithCreditCardAch={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              ?.payWithCreditCardAch
          }
          invoice={depositPopupDetail?.invoiceSettings?.invoice || {}}
          payWithCheckCashNote={
            depositPopupDetail?.invoiceSettings?.depositPopupTemplate
              .payWithCheckCashNote
          }
          openPopups={openPopups}
        />
      </CustomModal>
      <PayDepositePopup
        isModalVisible={showPaymentOptions}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleACHPaymentCancel={handleACHPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowPaymentOptions={setShowPaymentOptions}
        paymentIntegration={
          depositPopupDetail?.invoiceSettings?.paymentIntegration?.[
            depositPopupDetail?.invoiceSettings?.paymentIntegration
              ?.activeIntegration
          ]?.accountId
        }
      />
      <CreditCardPaymentPopup
        showCardPayment={showCardPayment}
        handleCardPaymentCancel={handleCardPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowCardPayment={setShowCardPayment}
      />
      <CardPaymentFeePopup
        showCardPaymentFee={showCardPaymentFee}
        cardPercentage={
          organizationDetails?.invoiceSettings?.paymentIntegration
            ?.extraChargeForCardPayment?.chargePercentage
        }
        paymentAmount={addExtraPercentageAmount(
          depositPopupDetail?.invoiceSettings?.invoice?.amount,
          depositPopupDetail?.invoiceSettings?.paymentIntegration
            ?.extraChargeForCardPayment?.chargePercentage,
        )}
        handleCardPaymentCancel={handleCardPaymentCancel}
        handleConfirmCardPayment={handleConfirmCardPayment}
        handleBackToCard={handleBackToCard}
      />
      <ACHPaymentPopup
        showACHPayment={showACHPayment}
        handleACHPaymentCancel={handleACHPaymentCancel}
        setIsModalVisible={setIsModalVisible}
        setShowACHPayment={setShowACHPayment}
      />
      <QBBillingDetail
        isModalOpen={modalToggle?.qbBillingDetails}
        handleCancel={handleCancelQBCard}
        billingEmail={billingEmail}
        handleBillingEmailChange={handleBillingEmailChange}
        billingError={billingError}
        type={paymentType}
        handleQBPayment={handleQBPayment}
        loading={btnLoading}
      />
      {/* <QBCreditCardModal
        isModalOpen={qbCardPayment}
        handleCancel={handleCancelQBCard}
      />
      <QBAchPaymentModal
        isModalOpen={qbAchPayment}
        handleCancel={handleCancelQBAch}
      /> */}
      <SquareAchPaymentModal
        isModalOpen={squareACHPayment}
        handleCancel={handleCancelSquareAch}
        locationId={squarePaymentInfo?.locationId}
        handleTokenizeResponse={handleTokenizeResponse}
        loading={paymentLoading}
        setPaymentLoading={setPaymentLoading}
      />
      <Modal
        title={false}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="custom-modal"
        closable={false}
        width={542}
        centered
      >
        <div className="d-flex gap-3">
          {payCash}
          <div className="flex-fill">
            <h2 className="pay-title">To pay with a Check or Cash</h2>
            <div
              className="w-100 text-content"
              style={{
                borderRadius: 8,
                maxWidth: 600,
                minHeight: 150,
                width: "100%",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    depositPopupDetail?.invoiceSettings?.depositPopupTemplate?.payWithCheckCashNote?.replace(
                      /@/g,
                      "",
                    ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-end pt-4 d-flex align-items-center gap-2 justify-content-end flex-column flex-sm-row">
          {(depositPopupDetail?.invoiceSettings?.paymentIntegration?.stripe
            ?.accountId ||
            depositPopupDetail?.invoiceSettings?.paymentIntegration?.quickbooks
              ?.accountId) && (
            <Button className="clear-filter" onClick={() => openPopups()}>
              Back
            </Button>
          )}
          <Button
            className="apply-btn mobile-width"
            type="primary"
            onClick={handleClose}
          >
            Confirm
          </Button>
        </div>
      </Modal>
      <Modal
        title={false}
        open={editingLineItemImage?.lineItemDraggableId}
        onCancel={() => {
          if (!isUpdatingLineItemImage) {
            setEditingLineItemImage({
              formulaId: "",
              lineItemDraggableId: "",
              newImage: "",
            });
          }
        }}
        footer={false}
        className="custom-modal"
        closable={false}
        width={685}
        centered
      >
        <div className="border d-flex justify-content-around mb-2 p-4">
          <div>
            <AntdImage
              style={{
                height: "95px",
                width: "150px",
              }}
              src={editingLineItemImage.newImage}
              alt={editingLineItemImage.lineItemDraggableId}
              preview={false}
            />
            <ImgCrop aspect={150 / 95}>
              <Upload
                ghost
                maxCount={1}
                showUploadList={false}
                customRequest={async (e) => {
                  const newPhoto = await fileToBase64(e.file);
                  setEditingLineItemImage((prevState) => ({
                    ...prevState,
                    newImage: newPhoto,
                  }));
                }}
              >
                <Tooltip title="Edit cover photo">
                  <Button
                    // type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              </Upload>
            </ImgCrop>
          </div>
        </div>
        <div className="d-flex gap-3">
          <Button
            className="clear-filter mx-3"
            disabled={isUpdatingLineItemImage}
            onClick={() =>
              setEditingLineItemImage({
                formulaId: "",
                lineItemDraggableId: "",
                newImage: "",
              })
            }
          >
            Cancel
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={updateEstimateImageOnly}
            disabled={isUpdatingLineItemImage}
          >
            Update for this estimate only
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={updateEstimationTemplateImageWithEstimate}
            disabled={isUpdatingLineItemImage}
          >
            Update estimation template
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Content;
