import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Card, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useCallback, useEffect, useMemo } from "react";

import { allImageFilesExtension } from "../../../../utils/constants/constants";
import { fileToBase64 } from "../../../../utils/fileBase64";

function UploadFile({
  title,
  size,
  image,
  id,
  handleChange,
  handleRemove,
  aspect,
  shape,
  handleSubmit,
  isLoading,
  isSetUp,
}) {
  const { Dragger } = Upload;
  const [isChange, setIsChange] = React.useState(true);

  const handleImageChange = useCallback(
    async (image) => {
      const imageBase64 = await fileToBase64(image);
      handleChange(imageBase64, id);
    },
    [handleChange, id],
  );

  const props = useMemo(
    () => ({
      name: "file",
      onChange() {
        // handleImageChange(info.file.originFileObj);
      },
      method: "get",
      customRequest: async ({ file, onSuccess }) => {
        if (!allImageFilesExtension.includes(file?.type))
          message.error("Invalid file extension");
        if (onSuccess) {
          onSuccess("OK");
        }
      },
    }),
    [handleImageChange],
  );

  useEffect(() => {
    if (isSetUp) {
      props.maxCount = 1;
    }
  }, [isSetUp, props]);
  return (
    <Card
      bordered={false}
      style={{ zIndex: "1" }}
      className="upload-image-box radius-12 box-shadow mb-3 mb-lg-0 catalogs-estimate"
      title={[<h5 className="mb-0">{title}</h5>]}
    >
      <ImgCrop
        shape={shape ? "round" : "rect"}
        aspect={aspect}
        onModalOk={(file) => {
          setIsChange(false);
          handleImageChange(file);
        }}
      >
        <Dragger
          {...props}
          maxCount={1}
          listType="picture-card"
          className="mb-3"
          name={id}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Size by: {size}</p>
        </Dragger>
      </ImgCrop>

      {image && isChange && (
        <div className="border image-width">
          <img src={image} alt="" />
          <span onClick={() => handleRemove("", id)} className="ant-close-btn">
            <DeleteOutlined />
          </span>
        </div>
      )}
      {!isSetUp && (
        <Button
          className="company-setting px-5"
          onClick={handleSubmit}
          size="large"
        >
          {typeof isLoading === "function"
            ? isLoading()
              ? "Updating..."
              : "Update"
            : isLoading
            ? "Updating..."
            : "Update"}
        </Button>
      )}
    </Card>
  );
}

export default UploadFile;
