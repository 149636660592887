import { Menu, Select } from "antd";
import { Link } from "react-router-dom";

import { NumberFormat } from "../../../utils/numberFormat";
import { DeleteIcon, editRename } from "../../../utils/svg.file";
import { renderStatus } from "../../invoicing/helper";
import { updateStatus } from "./tableHelper";
const { Option } = Select;
export const MenuCard = ({ tabId, handleOpenDeleteModel, handleAddTab }) => (
  <Menu className="deletebox">
    <Menu.Item className="rename" key={tabId}>
      {editRename}{" "}
      <span
        className="ms-1 font-14"
        onClick={() => {
          handleAddTab(true, tabId);
        }}
      >
        Rename
      </span>
    </Menu.Item>
    <Menu.Item
      className="deletev"
      onClick={() => {
        handleOpenDeleteModel(tabId);
      }}
    >
      {DeleteIcon} <span className="ms-1 font-14">Delete</span>
    </Menu.Item>
  </Menu>
);

export const InvoiceData_column = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
    sorter: (a, b) =>
      String(a.customerName).localeCompare(String(b.customerName)),
    render: (text, data) => (
      <Link
        to={`/v2/customer-details?customerId=${
          data.customerId
        }&scheduledCustomer=true&panel=invoicing&signedUserEstimationId=${
          data.estimationId
        }&redirectTo=${encodeURIComponent("/invoice-dashboard")}`}
        style={{ color: "#101828", fontWeight: "500" }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Project Name",
    dataIndex: "projectName",
    sorter: (a, b) =>
      String(a.projectName).localeCompare(String(b.projectName)),
    render: (text, data) => (
      <Link
        to={`/v2/customer-details?customerId=${
          data.customerId
        }&scheduledCustomer=true&panel=invoicing&signedUserEstimationId=${
          data.estimationId
        }&redirectTo=${encodeURIComponent("/invoice-dashboard")}`}
        style={{ color: "#101828", fontWeight: "500" }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Project Status",
    dataIndex: "projectStatus",
    sorter: (a, b) =>
      String(a.projectStatus).localeCompare(String(b.projectStatus)),
  },
  {
    title: "Project No.",
    dataIndex: "projectNumber",
    sorter: (a, b) => String(a.projectNo).localeCompare(String(b.projectNo)),
    align: "center",
  },
  {
    title: "Invoice Status",
    dataIndex: "invoicesStatus",
    sorter: (a, b) =>
      String(a.invoiceStatus).localeCompare(String(b.invoiceStatus)),
    align: "center",
    render: (status) => renderStatus(status) || "-",
  },
  {
    title: "Days Due/Overdue",
    dataIndex: "daysDueOverdue",
    sorter: (a, b) => (a?.daysDueOverdue || 0) - (b?.daysDueOverdue || 0),
    align: "center",
    render: (rec) => (rec ? `${rec} Days` : "-"),
  },
  {
    title: "Total Project",
    dataIndex: "totalProject",
    sorter: (a, b) => (a?.totalProject || 0) - (b?.totalProject || 0),
    align: "center",
    render: (rec) => NumberFormat(rec),
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    sorter: (a, b) => (a?.amountPaid || 0) - (b.amountPaid || 0),
    render: (rec) => NumberFormat(rec),
  },
  {
    title: "Amount Overdue",
    dataIndex: "amountOverdue",
    sorter: (a, b) => (a.amountOverdue || 0) - (b.amountOverdue || 0),
    align: "center",
    render: (rec) => NumberFormat(rec),
  },
  {
    title: "Amount Due",
    dataIndex: "amountDue",
    sorter: (a, b) => (a.amountDue || 0) - (b.amountDue || 0),
    align: "center",
    render: (rec) => NumberFormat(rec),
  },
  {
    title: "Amount Not Due",
    dataIndex: "amountNotDue",
    sorter: (a, b) => (a.amountNotDue || 0) - (b.amountNotDue || 0),
    align: "center",
    render: (rec) => NumberFormat(rec),
  },
];

export const handleSelectDropdown = (item, projectStatuses) => (
  <Select
    key={item.projectStatus}
    defaultValue={item.projectStatus}
    style={{
      width: "190px",
      color: projectStatuses?.find((obj) => obj?.label === item.projectStatus)
        ?.color,
    }}
    optionLabelProp="styledLabel"
    onChange={(value) => {
      const payload = {
        newStatusId: value,
        estimationId: item.estimationId,
      };
      updateStatus(payload);
    }}
  >
    {projectStatuses.map((status) => (
      <Option
        key={status.value}
        value={status.value}
        styledLabel={
          <span style={{ color: status.color }}>{status.label}</span>
        }
      >
        <span style={{ color: status.color }}>{status.label}</span>
      </Option>
    ))}
  </Select>
);
