import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select } from "antd";
import React from "react";

import ReactMentionInput from "../../../../utils/mentionInput/mentionInput";
import { treeIcon } from "../../../../utils/svg.file";
import ConditionalInput from "../elementCard/conditionInput";
import { FORMULA_MATERIAL_TYPES } from "../helper";
import LaborOrProfitBox from "./laborOrProfitBox";

function MaterialCard({
  isAddingMaterial,
  material,
  handleChange,
  index,
  onFocusOut,
  handleRemoveMaterial,
  hiddenValueList,
  catalogsAndServices,
  units,
  processedElementList,
  elementList,
  handleAddMaterial,
}) {
  const [showDeleteModal, setShowDeleteModal] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isDeleteing, setIsDeleteing] = React.useState(false);
  const [materialTypeList, setMaterialTypeList] = React.useState([]);
  const [selectedCatalog, setSelectedCatalog] = React.useState(null);
  const [lineItemName, setLineItemName] = React.useState("");

  // eslint-disable-next-line no-unused-vars
  const [dropdownCustomField, setDropdownCustomField] = React.useState([]);

  // const handleCustomFields = (details) => {
  //   if (details) {
  //     const customFields = details.customFields || [];
  //     setDropdownCustomField(customFields);
  //     const event = { target: { value: customFields, name: "customFields" } };
  //     handleChange(event, index);
  //   }
  // };

  React.useEffect(() => {
    setMaterialTypeList([
      { value: FORMULA_MATERIAL_TYPES.material, label: "Material Cost" },
      { value: FORMULA_MATERIAL_TYPES.labor, label: "Labor Cost" },
      {
        value: FORMULA_MATERIAL_TYPES.subcontractor,
        label: "Subcontractor Cost",
      },
      {
        value: FORMULA_MATERIAL_TYPES.equipment,
        label: "Equipment Cost",
      },
    ]);
  }, []);

  const getSelectedCatalog = (id) => {
    const catalog = catalogsAndServices.find((item) => item.id === id);
    if (catalog) {
      setSelectedCatalog(catalog);
      return catalog.display;
    }
    return "";
  };

  React.useEffect(() => {
    setLineItemName(material?.name || "");
  }, [material?.name]);
  return (
    <tr>
      <td>
        <Row className="align-items-center">
          <Col md={24}>
            <label>Name Material:</label>
          </Col>
          <Col md={24}>
            <ReactMentionInput
              className="ant-furmulla-input px-2 outline w-200"
              elementList={elementList
                .filter((elem) => elem.type === "dropdown")
                .map((elem) => ({ id: elem._id, display: elem.name }))}
              hiddenInputList={[]}
              onChange={(e, newValue) => {
                setLineItemName(newValue);
              }}
              lineItemsList={[]}
              placeholder="Enter Charge use '@' and '#' for the dynamic values"
              value={lineItemName}
              onBlur={() => {
                const e = { target: { value: lineItemName, name: "name" } };
                handleChange(e, index);
              }}
              // onBlur={onFocusOut}
              // getSelectedCatalog={getSelectedCatalog}
            />
          </Col>
        </Row>
        <div>
          <span
            className=""
            onClick={() => {
              if (handleAddMaterial && !isAddingMaterial)
                handleAddMaterial(index + 1);
            }}
          >
            {treeIcon}
          </span>
        </div>
      </td>
      <td>
        <Row className="align-items-start">
          <Col md={24} xs={24}>
            <label>Type: </label>
          </Col>
          <Col md={24} xs={24}>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                handleChange({ target: { name: "type", value } }, index);
              }}
              value={material.type}
              onBlur={onFocusOut}
            >
              {materialTypeList.map((type) => (
                <Select.Option value={type.value} key={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </td>
      {material.type === "labor" ? (
        <td>
          <Row>
            <Col md={24} xs={24}>
              <label># of Workers:</label>
            </Col>
            <Col md={24} xs={24}>
              <ReactMentionInput
                className="ant-furmulla-input px-2 outline w-200"
                onChange={(e, newValue) => {
                  e = { target: { ...e.target, name: "numberOfWorkers" } };
                  handleChange(e, index, newValue);
                }}
                noMaterial
                value={material.numberOfWorkers}
                onBlur={onFocusOut}
                isCustomNameInput
              />
            </Col>
          </Row>
        </td>
      ) : (
        ""
      )}
      <td>
        <Row>
          <Col md={24} xs={24}>
            <label>Enter Quantity:</label>
          </Col>
          <Col md={24} xs={24}>
            <ReactMentionInput
              className={`ant-furmulla-input px-2 outline w-200 ${
                !material.isValidquantity ? "error-formula" : ""
              }`}
              elementList={processedElementList}
              hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                display: hiddenValue.name,
                id: hiddenValue._id,
              }))}
              onChange={(e, newValue) => {
                e = { target: { ...e.target, name: "quantity" } };
                if (selectedCatalog) {
                  e.target.value = e.target.value.replace(
                    selectedCatalog.display,
                    selectedCatalog.slug,
                  );
                  newValue = newValue.replace(
                    selectedCatalog.display,
                    selectedCatalog.slug,
                  );
                }
                handleChange(e, index, newValue);
              }}
              lineItemsList={dropdownCustomField.map((field) => ({
                display: `${material?.name}-${field.fieldName}`,
                id: field.superId,
              }))}
              placeholder="Enter Charge use '@' and '#' for the dynamic values"
              value={material.quantity}
              onBlur={onFocusOut}
              getSelectedCatalog={getSelectedCatalog}
            />
          </Col>
        </Row>
      </td>
      <td>
        <Row className="align-items-center">
          <Col md={24} xs={24}>
            <label>Unit</label>
          </Col>
          <Col md={24} xs={24}>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                handleChange({ target: { name: "unit", value } }, index);
              }}
              value={material.unit}
            >
              <Select.Option value=""> Unit less</Select.Option>
              {units.map((unit, index) => (
                <Select.Option value={unit._id} key={index}>
                  {unit.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </td>
      <td>
        <Row className="align-items-center" style={{ width: "165px" }}>
          <Col md={24} xs={24}>
            <label>Unit Cost:</label>
          </Col>
          <Col md={24} xs={24}>
            <div className="d-flex">
              <ReactMentionInput
                className={`ant-furmulla-input px-2 outline w-200 ${
                  !material.isValidUnitCost ? "error-formula" : ""
                }`}
                elementList={processedElementList}
                onChange={(e, newValue) => {
                  e = { target: { ...e.target, name: "unitCost" } };
                  handleChange(e, index, newValue);
                }}
                placeholder="Enter Charge use '@' and '#' for the dynamic values"
                value={material.unitCost}
                onBlur={onFocusOut}
                isMaterialInput
                type={material.type}
                hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                  display: hiddenValue.name,
                  id: hiddenValue._id,
                }))}
                lineItemsList={dropdownCustomField.map((field) => ({
                  display: `${material?.name}-${field.fieldName}`,
                  id: field.superId || field._id,
                }))}
                getSelectedCatalog={getSelectedCatalog}
              />
            </div>
          </Col>
        </Row>
      </td>

      <td>
        <Row className="align-items-start">
          <Col md={24} xs={24}>
            <label>Cost:</label>{" "}
          </Col>
          <Col md={24} xs={24}>
            <div className="d-flex">
              <ReactMentionInput
                className={`ant-furmulla-input px-2 outline w-200 ${
                  !material.isValidcost ? "error-formula" : ""
                }`}
                elementList={processedElementList}
                onChange={(e, newValue) => {
                  e = { target: { ...e.target, name: "cost" } };
                  handleChange(e, index, newValue);
                }}
                placeholder="Enter Charge use '@' and '#' for the dynamic values"
                value={material.cost}
                onBlur={onFocusOut}
                isMaterialInput
                type={material.type}
                hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                  display: hiddenValue.name,
                  id: hiddenValue._id,
                }))}
                lineItemsList={dropdownCustomField.map((field) => ({
                  display: `${material?.name}-${field.fieldName}`,
                  id: field.superId,
                }))}
                getSelectedCatalog={getSelectedCatalog}
              />
            </div>
          </Col>
        </Row>
      </td>
      <td>
        <LaborOrProfitBox
          elementList={processedElementList}
          materialType={material.type}
          onFocusOut={onFocusOut}
          handleChange={handleChange}
          index={index}
          value={
            material.type === FORMULA_MATERIAL_TYPES.labor ||
            material.type === FORMULA_MATERIAL_TYPES.equipment
              ? material.laborChargePerHrs
              : material.grossProfit
          }
          hiddenInputList={hiddenValueList}
        />
      </td>
      <td>
        <Row className="align-items-start">
          <Col md={24} xs={24}>
            <label>Charge:</label>
          </Col>
          <Col md={24} xs={24}>
            <ReactMentionInput
              className={`ant-furmulla-input w-200 ${
                !material.isValidcharge ? "error-formula" : ""
              }`}
              elementList={processedElementList}
              onChange={(e, newValue) => {
                e = {
                  target: {
                    ...e.target,
                    name: "charge",
                  },
                };
                handleChange(e, index, newValue);
              }}
              placeholder="Enter Charge use '@' and '#' for the dynamic values"
              value={material.charge}
              onBlur={onFocusOut}
              isMaterialInput
              type={material.type}
              hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                display: hiddenValue.name,
                id: hiddenValue._id,
              }))}
              lineItemsList={dropdownCustomField.map((field) => ({
                display: `${material?.name}-${field.fieldName}`,
                id: field.superId,
              }))}
              getSelectedCatalog={getSelectedCatalog}
            />
          </Col>
        </Row>
      </td>
      <td>
        <Row className="align-items-start">
          <Col md={24} xs={24}>
            <label>Conditional?</label>
          </Col>
          <Col md={24} xs={24}>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => {
                handleChange(
                  { target: { name: "isConditional", value } },
                  index,
                );
              }}
              value={!!material.isConditional}
              onBlur={onFocusOut}
            >
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Col>
        </Row>
      </td>
      <td>
        <Row className="align-items-start">
          <Col md={24} xs={24}>
            <label>Show Line Item IF:</label>
          </Col>
          <Col md={24} xs={24}>
            <ConditionalInput
              hiddenValueList={hiddenValueList}
              elementList={elementList}
              element={material}
              handleChange={(value) =>
                handleChange({ target: { name: "conditions", value } }, index)
              }
              disabled={!material.isConditional}
            />
          </Col>
        </Row>
        <span
          className="delect"
          style={{ cursor: "pointer" }}
          onClick={() => setShowDeleteModal(true)}
        >
          <DeleteOutlined className="text-danger" />
        </span>
      </td>

      <Modal
        className="modal-radius warning-modal"
        title="Warning!"
        visible={showDeleteModal}
        closeIcon={<InfoCircleOutlined />}
        width={350}
        footer={null}
      >
        <p>Are you sure you want to delete this item?</p>
        <Row>
          <Col md={12} className="text-center">
            <Button
              type="text"
              onClick={() => {
                setShowDeleteModal(false);
              }}
              disabled={isDeleteing}
            >
              Cancel
            </Button>
          </Col>
          <Col md={12}>
            <Button
              type="link"
              onClick={() => {
                handleRemoveMaterial(index);
                setShowDeleteModal(false);
              }}
              disabled={isDeleteing}
            >
              {isDeleteing ? "Deleting..." : "Delete"}
            </Button>
          </Col>
        </Row>
      </Modal>
    </tr>
  );
}

export default MaterialCard;
