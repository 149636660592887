/* eslint-disable no-unused-vars */
import regex from "../../../utils/regex";
import { POSSIBLE_ANSWER_TYPE } from "../../setting/SetupQuestion/v2/utils/helper";

export const validateLeadForm = ({ details, selectedValue, newProject }) => {
  console.log("details => ", details);
  const errors = {};
  const {
    fullName,
    email,
    projectName,
    leadSource,
    questionsAnswers,
    projectList,
  } = details;
  if (!fullName) {
    errors.fullName = "Full Name Required";
  }
  if (!projectName?.replace(/^\s+|\s+$/g, "")) {
    errors.projectName = "Project Name Required";
  }
  if (
    newProject
      ? projectList?.some(
          (obj) =>
            obj?.projectName?.replace(/^\s+|\s+$/g, "") ===
            projectName?.replace(/^\s+|\s+$/g, ""),
        )
      : projectList
          ?.filter((obj) => obj?._id !== selectedValue)
          ?.some(
            (obj) =>
              obj?.projectName?.replace(/^\s+|\s+$/g, "") ===
              projectName?.replace(/^\s+|\s+$/g, ""),
          )
  ) {
    errors.projectName = "Project name must be unique";
  }
  if (email) {
    if (!regex.emailRegex.test(email)) {
      errors.email = "Email is not valid";
    }
  }
  if (leadSource === undefined) {
    errors.leadSource = "Field Required";
  }
  questionsAnswers?.forEach((questions) => {
    const { _id, answer, question } = questions;
    if (
      question?.isMandatory &&
      question.questionInputType !== POSSIBLE_ANSWER_TYPE.toggleButton
    ) {
      if (!answer.length) {
        errors[_id] = "Field Required";
      }
    }
  });

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const validateProjectForm = (details, options) => {
  const errors = {};
  const { projectName, leadSource, questionsAnswers } = details;
  if (!projectName?.trim()) {
    errors.projectName = "Project Name Required";
  }
  console.log("options => ", options);
  if (
    options?.some(
      (obj) => obj?.label === projectName && obj?.value !== obj?.label,
    )
  ) {
    console.log("Inside condition", { projectName, options });
    errors.projectName = "Project name must be unique";
  }
  if (leadSource === undefined) {
    errors.leadSource = "Field Required";
  }
  questionsAnswers?.forEach((questions) => {
    const { _id, answer, question } = questions;
    if (
      question?.isMandatory &&
      question.questionInputType !== POSSIBLE_ANSWER_TYPE.toggleButton
    ) {
      if (!answer.length) {
        errors[_id] = "Field Required";
      }
    }
  });

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
