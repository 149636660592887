/* eslint-disable no-unused-vars */
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { allImageFilesExtension } from "../../../../utils/constants/constants";
import { fileToBase64 } from "../../../../utils/fileBase64";
import {
  DeleteIcon,
  DeleteIconView,
  DeleteIconViewNew,
  ProposalDeleteIcon,
} from "../../../../utils/svg.file";
// import { DeleteIconViewNew } from "../../../invoicing/svg.icons";

const { Dragger } = Upload;

const ProposalUploader = ({ id, handleChange, size }) => {
  const [image, setImage] = useState([]);
  const handleImageChange = useCallback(
    async (image) => {
      const imageBase64 = await fileToBase64(image);
      handleChange(imageBase64);
    },
    [handleChange],
  );
  const props = useMemo(
    () => ({
      name: "file",
      method: "get",
      beforeUpload: () => {
        setImage([]);
      },
      customRequest: async ({ file, onSuccess }) => {
        setImage((prev) => [...prev, file]);
        onSuccess("OK");
      },
      showUploadList: false,
    }),
    [handleImageChange],
  );

  useEffect(() => {
    if (image?.length) {
      handleChange(image);
    }
  }, [image]);

  return (
    <>
      <Dragger
        {...props}
        maxCount={4}
        multiple
        listType="picture-card"
        className="mb-3"
        name={id}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Size by: {size}</p>
      </Dragger>
      {/* {!!image?.length && (
        <div className="d-flex gap-4">
          {image?.map((img, ind) => (
            <div
              className="border"
              style={{ position: "relative", borderRadius: 8 }}
            >
              <img
                src={img}
                alt={`past-project-${ind}`}
                width={180}
                height={122}
                style={{ borderRadius: 8 }}
              />
              <span
                onClick={() => handleRemove("", id)}
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  top: "0%",
                  width: "100%",
                  height: "100%",
                  color: "#DC3543",
                }}
              >
                <ProposalDeleteIcon style={{ color: "#DC3543" }} />
              </span>
            </div>
          ))}
        </div>
      )} */}
    </>
  );
};

export default ProposalUploader;
