import { Input } from "antd";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useSelector } from "react-redux";

function GoogleSearchInput({
  address,
  placeholder,
  onOptionMessage,
  handleChange,
  isDisabled = false,
  defaultAddress = "",
}) {
  const usFilter = {
    componentRestrictions: { country: ["us", "usa", "ca"] }, // Restrict to us and usa locations
  };
  const googleAPIKey = useSelector((state) => state.api.googleKey);
  const [showNoOptionMessage, setShowNoOptionMessage] = useState("");
  const [value, setValue] = useState("");
  const [showAddress, setShowAddress] = useState(false);

  useEffect(() => {
    setShowNoOptionMessage(onOptionMessage);
  }, [onOptionMessage]);

  useEffect(() => {
    setValue(address);
  }, [address]);
  useEffect(() => {
    if (!showAddress) {
      handleChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleAddressChange = (e) => {
    setValue(e.label);
  };

  const handleNoOptionMessage = (value) => {
    if (!value.inputValue) {
      return showNoOptionMessage;
    }
    return "No results found for the search";
  };
  return (
    <div>
      {!showAddress ? (
        <Input
          className="radius-30"
          size="large"
          placeholder={placeholder}
          name="address"
          value={value}
          onFocus={() => setShowAddress(true)}
          disabled={isDisabled}
          defaultValue={defaultAddress}
        />
      ) : (
        googleAPIKey && (
          <GooglePlacesAutocomplete
            apiKey={googleAPIKey}
            selectProps={{
              isClearable: true,
              defaultInputValue: value,
              blurInputOnSelect: true,
              onBlur: () => setShowAddress(false),
              autoFocus: true,
              noOptionsMessage: handleNoOptionMessage,
              placeholder,
              onChange: (val) => {
                handleAddressChange(val);
              },
            }}
            autocompletionRequest={usFilter}
          />
        )
      )}
    </div>
  );
}

export default GoogleSearchInput;
