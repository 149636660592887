import { Card, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { replaceMentions } from "../../../utils/suggestions";
import { processDescription, removePreLink } from "./helper";

const ProposalPastProjects = ({ proposalDetails, settings, generatingPdf }) => {
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();
  const token = params.get("token");
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className={`mb-3 proposal-card-h-fix ${
        generatingPdf ? "" : "proposal-card-h-fix2"
      }`}
      bodyStyle={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="proprosol-card-h flex-fill">
        <div className="contract-bg-section">
          <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
            <div
              className={`abouts-btn ${generatingPdf ? "" : "abouts-btn2"}`}
              style={{
                backgroundColor: settings?.templateColor,
                color: settings?.templateTextColor,
                padding: "20px 60px",
                ...(generatingPdf && {
                  fontSize: "35px",
                  fontWeight: "bold",
                }),
              }}
            >
              Past Projects
            </div>
            {(userDetails?.organization?.logo ||
              proposalDetails?.organizationDetails?.logo) && (
              <div className="ms-auto">
                <img
                  src={createValidUrl(
                    token
                      ? proposalDetails?.organizationDetails?.logo
                      : userDetails?.organization?.logo,
                  )}
                  alt=""
                  className={`company-logo ${
                    generatingPdf ? "" : "company-logo2"
                  }`}
                />
              </div>
            )}
          </div>
        </div>
        <div className={`aboutContent ${generatingPdf ? "" : "aboutContent2"}`}>
          <h2
            className="proposal-title-h2"
            dangerouslySetInnerHTML={{
              __html: replaceMentions(
                proposalDetails?.pastProject?.title,
                proposalDetails?.customerDetails,
                token
                  ? proposalDetails?.organizationDetails
                  : userDetails?.organization,
                token ? proposalDetails?.userDetails : userDetails,
              ),
            }}
          />
          <div
            className={`about-content-editor ${
              generatingPdf ? "" : "about-content-editor2"
            }`}
            dangerouslySetInnerHTML={{
              __html: processDescription({
                desc: replaceMentions(
                  proposalDetails?.pastProject?.description,
                  proposalDetails?.customerDetails,
                  token
                    ? proposalDetails?.organizationDetails
                    : userDetails?.organization,
                  token ? proposalDetails?.userDetails : userDetails,
                ),
                removeBold: generatingPdf,
              }),
            }}
          />
        </div>
      </div>
      <div className="pt-0 pt-lg-5">
        <Row gutter={[24, 24]}>
          {proposalDetails?.pastProject?.photos?.map((link, index) => (
            <Col xs={12} lg={12} key={index}>
              <Card
                bordered={false}
                bodyStyle={{ padding: "0px" }}
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  overflow: "hidden",
                }}
              >
                <img
                  className={`pasimg ${generatingPdf ? "" : "pasimg2"}`}
                  style={{ height: "230px", width: "100%", objectFit: "cover" }}
                  alt={`past-project-${index}`}
                  src={
                    link
                      ? removePreLink(link)
                      : "https://images.unsplash.com/photo-1512917774080-9991f1c4c750"
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  );
};

export default ProposalPastProjects;
