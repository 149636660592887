import { Button, Switch, Table } from "antd";
import { Editor } from "react-draft-wysiwyg";

import {
  getQueryFromUrl,
  PAYMENT_STATUS,
  userEstimationStatus,
} from "../../utils/constants/constants";
import { NumberFormat } from "../../utils/numberFormat";
import {
  // AddInternalNoteIcon,
  // AddInternalNoteIcon,
  DeleteInvoice,
  DraftIcon,
  InvoiceDraftIcon,
  InvoiceDueIcon,
  InvoiceEye,
  InvoiceNotDueIcon,
  InvoicePaidIcon,
  InvoiceSentIcon,
  InvoiceSignedIcon,
  ViewSendInvoiceIcon,
} from "./svg.icons";

const modifyStatus = {
  due: "Due",
  notdue: "Not Due",
  notDue: "Not Due",
  sent: "Sent",
  notSent: "Not Sent",
  viewed: "Viewed",
  overdue: "Overdue",
  paid: "Paid",
  [userEstimationStatus.pending]: "Draft",
  [userEstimationStatus.internallyConfirmed]: "Confirmed",
  [userEstimationStatus.sentToCustomer]: "Sent",
};

export const statusStyleIcon = {
  Signed: {
    style: {
      background: "#FBFFF8",
      color: "#719d40",
    },
    Icon: InvoiceSignedIcon,
  },
  Sent: {
    style: {
      background: "#EAFFFF",
      color: "#51C1C2",
    },
    Icon: InvoiceSentIcon,
  },
  Draft: {
    style: {
      background: "#EAECF0",
      color: "#667085",
    },
    Icon: InvoiceDraftIcon,
  },
  Due: {
    style: {
      background: "#FFF5E7",
      color: "#FF8E29",
      iconStyle: "#FFB758",
    },
    Icon: InvoiceDueIcon,
  },
  "Not Due": {
    style: {
      background: "#CFE6FF",
      color: "#2E90FA",
      iconStyle: "#3483FA",
    },
    Icon: InvoiceNotDueIcon,
  },
  Paid: {
    style: {
      background: "#ECFDF3",
      color: "#027A48",
      iconStyle: "#12B76A",
    },
    Icon: InvoicePaidIcon,
  },
  Confirmed: {
    style: {
      background: "#CFE6FF",
      color: "#2E90FA",
      iconStyle: "#3483FA",
      width: 100,
    },
    Icon: InvoicePaidIcon,
  },
  [userEstimationStatus.pending]: {
    style: {
      background: "#EAECF0",
      color: "#667085",
      iconStyle: "#667085",
      width: 150,
    },
    Icon: DraftIcon,
  },
  "Not Sent": {
    style: {
      background: "#EAECF0",
      color: "#667085",
    },
    Icon: InvoiceDraftIcon,
  },
  Viewed: {
    style: {
      background: "#CFE6FF",
      color: "#2E90FA",
      iconStyle: "#3483FA",
      width: 90,
    },
    Icon: InvoicePaidIcon,
  },
  Overdue: {
    style: {
      background: "#FEF3F2",
      color: "#B42318",
      iconStyle: "#F04438",
      width: 90,
    },
    Icon: InvoiceDueIcon,
  },
  /** Set "Sent" status style with background and color with paid */
};
export const renderStatus = (status) => {
  if (status || modifyStatus[status]) {
    const returnStatus = statusStyleIcon[modifyStatus[status] || status];
    const Icon = returnStatus?.Icon;
    return (
      <div
        className="d-flex align-items-center gap-1"
        style={{
          justifyContent: "center",
          fontSize: 14,
          padding: "0px 5px",
          borderRadius: 16,
          fontWeight: 500,
          ...returnStatus?.style,
          height: returnStatus?.style?.height || 25,
          width: returnStatus?.style?.width || 85,
          margin: "0px auto",
        }}
      >
        {Icon && (
          <Icon
            style={{
              stroke: returnStatus?.iconStyle || returnStatus?.style?.color,
            }}
          />
        )}
        <span style={{ alignItems: "center" }}>
          {modifyStatus[status] || status}
        </span>
      </div>
    );
  }
  return <>-</>;
};
export const invoicePaymentData = [
  {
    title: "Date Paid",
    dataIndex: "date_paid",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
  },
];
// Define columns for the project detail table
export const projectColumns = (record) => [
  {
    title: "Date",
    dataIndex: "date",
    className: "text-start",
    key: "date",
    render: (date) => <span className="text-start d-block">{date}</span>,
  },
  {
    title: "Doc. Number",
    dataIndex: "docNumber",
    key: "docNumber",
    className: "text-start",
    render: (docNumber) => (
      <span className="text-start d-block">{docNumber}</span>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    className: "text-start",
    render: (description) => (
      <span className="text-start d-block">{description}</span>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    className: "text-center",
    render: (amount) => NumberFormat(amount, { fractionalPoint: true }),
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    className: "text-center",
    render: (balance, rowData, ind) =>
      record?.length - 1 === ind ? (
        <span className="font-bold">
          {NumberFormat(balance, { fractionalPoint: true })}
        </span>
      ) : (
        NumberFormat(balance, { fractionalPoint: true })
      ),
  },
];
const remainingColumns = [
  {
    title: "Invoice Name",
    dataIndex: "invoiceName",
    key: "invoiceName",
  },
  {
    title: "Invoice Number",
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    className: "text-center",
    render: (invoiceNumber) => (
      <span className="text-table-td">{invoiceNumber}</span>
    ),
  },
  {
    title: "Status",
    dataIndex: "invoiceStatus",
    key: "invoiceStatus",
    render: (status) => renderStatus(status) || "-",
    className: "text-center",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    className: "text-center",
    render: (amount) => (
      <span className="text-table-td">
        {NumberFormat(amount, { fractionalPoint: true }) || "-"}
      </span>
    ),
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    className: "text-center",
    render: (date) => (
      <span className="text-table-td">
        {date ? new Date(date).toLocaleDateString("en-US") : "-"}
      </span>
    ),
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "amountPaid",
    render: (amountPaid) =>
      amountPaid ? NumberFormat(amountPaid, { fractionalPoint: true }) : "-",
    className: "text-center",
  },
  {
    title: "Paid Date",
    dataIndex: "paidDate",
    key: "paidDate",
    render: (date) => (
      <span className="text-table-td">
        {date ? new Date(date).toLocaleDateString("en-US") : "-"}
      </span>
    ),
    className: "text-center",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance) => (
      <span className="text-table-td">
        {NumberFormat(balance, { fractionalPoint: true }) || "-"}
      </span>
    ),
    className: "text-center",
  },
];
export const receiptColumn = [
  {
    title: "Description",
    dataIndex: "description",
    className: "receipt-invoice-name",
  },
  {
    title: "Invoice #",
    dataIndex: "invoice",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
  },
  {
    title: "Amount Paid",
    dataIndex: "amount_paid",
  },
  {
    title: "Date Paid",
    dataIndex: "date_paid",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
];

export const selectUnpaidActionItems = ({
  paid,
  rowId,
  navigate,
  // setAddInternalNotes,
  handleDeleteInvoice,
  handleEditPayments,
  fromUnpaid = false,
  hasInvoiceDate = false,
  handleSelectInvoiceDate = null,
  rowData,
}) => {
  switch (!!paid) {
    case true:
      return [
        {
          ...(fromUnpaid && {
            label: (
              <>
                <span>
                  <ViewSendInvoiceIcon />
                </span>
                <span className="action-label">View/ Send Invoice</span>
              </>
            ),
            key: "0",
            onClick: () => {
              if (hasInvoiceDate) {
                navigate(
                  `/send-invoice?id=${rowId}&estimationId=${getQueryFromUrl(
                    "signedUserEstimationId",
                  )}`,
                  {
                    state: {
                      prevPage: location.search,
                      redirectFrom: "invoice",
                      paid: false,
                    },
                  },
                );
              } else {
                handleSelectInvoiceDate();
              }
            },
          }),
        },
        {
          label: (
            <>
              <span>
                <ViewSendInvoiceIcon />
              </span>
              <span className="action-label">View/ Send Receipt</span>
            </>
          ),
          key: "1",
          onClick: () => {
            navigate(
              `/send-receipt?id=${rowId}&estimationId=${getQueryFromUrl(
                "signedUserEstimationId",
              )}`,
              {
                state: {
                  prevPage: location.search,
                  redirectFrom: "paid",
                  paid: true,
                  isPayment: false,
                },
              },
            );
          },
        },
        {
          label: (
            <>
              <span>
                <InvoiceEye />
              </span>
              <span className="action-label">View/ Edit Payment(s)</span>
            </>
          ),
          key: "2",
          onClick: () => handleEditPayments(),
        },
        /* Hide the internal note option as of now, will enable later: by Ralph (don't remove this) */
        // {
        //   label: (
        //     <>
        //       <span>
        //         <AddInternalNoteIcon />
        //       </span>
        //       <span className="action-label">Add Internal Notes</span>
        //     </>
        //   ),
        //   key: "3",
        //   onClick: () => setAddInternalNotes(true),
        // },
      ];

    case false:
      return [
        {
          label: (
            <>
              <span>
                <ViewSendInvoiceIcon />
              </span>
              <span className="action-label">View/ Send Invoice</span>
            </>
          ),
          key: "0",
          disabled: rowData?.newInvoice,
          onClick: () => {
            if (hasInvoiceDate) {
              navigate(
                `/send-invoice?id=${rowId}&estimationId=${getQueryFromUrl(
                  "signedUserEstimationId",
                )}`,
                {
                  state: {
                    prevPage: location.search,
                    redirectFrom: "invoice",
                    paid: false,
                  },
                },
              );
            } else {
              handleSelectInvoiceDate();
            }
            // navigate(
            //   `/send-invoice?id=${rowId}&estimationId=${getQueryFromUrl(
            //     "signedUserEstimationId",
            //   )}`,
            //   {
            //     state: {
            //       prevPage: location.search,
            //       redirectFrom: "invoice",
            //       paid: false,
            //     },
            //   },
            // );
          },
        },
        {
          label: (
            <>
              <span>
                <DeleteInvoice />
              </span>
              <span className="action-label text-danger">Delete Invoice</span>
            </>
          ),
          key: "1",
          onClick: () => handleDeleteInvoice(),
          disabled: rowData?.newInvoice,
        },
        /* Hide the internal note option as of now, will enable later: by Ralph (don't remove this) */
        // {
        //   label: (
        //     <>
        //       <span>
        //         <AddInternalNoteIcon />
        //       </span>
        //       <span className="action-label">Add Internal Notes</span>
        //     </>
        //   ),
        //   key: "3",
        //   onClick: () => setAddInternalNotes(true),
        // },
      ];

    default:
      break;
  }
};

export const alertBannerMessage = {
  delete: {
    content: <b>The Invoice has been successfully deleted.</b>,
  },
  invoiceSent: {
    content: <b>Invoice sent successfully</b>,
  },
  receiptSent: {
    content: <b>Receipt sent successfully</b>,
  },
  summarySent: {
    content: <b>Project summary sent successfully</b>,
  },
};

export const renderTableAndFooter = ({
  redirectFrom,
  data,
  tableData,
  handleNoteChange,
  invoiceNote,
  handleEditNote,
  isEditNote,
  tags,
  editorState,
  handlePayment,
  paymentLoading,
  paymentBtnText,
  handleHideTable,
  isHidden,
  showToggle,
  loading,
}) => {
  const getStatusColor = {
    due: {
      color: "#E42626",
      label: "Amount Due",
    },
    overdue: {
      color: "#E42626",
      label: "Amount Overdue",
    },
    notdue: {
      color: "#3483FA",
      label: "Amount",
    },
    notDue: {
      color: "#3483FA",
      label: "Amount",
    },
    paid: {
      color: "#027948",
      label: "Amount",
    },
  };
  switch (redirectFrom) {
    case "invoice":
      const dueAmount = (data?.invoiceAmount || 0) - (data?.amountPaid || 0);
      return (
        <>
          <h3 className="px-2">Invoice</h3>
          <Table
            style={{ whiteSpace: "normal", tableLayout: "fixed" }}
            columns={tableData?.column}
            dataSource={tableData?.data}
            // scroll={{ x: 1300 }}
            className="userTable mobile-table-view"
            pagination={false}
          />
          <div
            className="d-flex my-4 flex-wrap"
            style={{ justifyContent: "space-between" }}
          >
            <div className="mt-3 w-50 mobile-summary">
              <p style={{ color: "#646973", margin: 0 }}>Invoice Notes</p>
              {!isEditNote ? (
                <div
                  className="border p-3 mobile-editor"
                  dangerouslySetInnerHTML={{
                    __html: invoiceNote || data?.invoiceNote,
                  }}
                  style={{
                    borderRadius: 8,
                    // width: 500,
                    minHeight: 150,
                    // fontSize: "initial",
                  }}
                  onClick={() => handleEditNote()}
                />
              ) : (
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleNoteChange}
                  toolbarHidden
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class mobile-editor"
                  toolbarClassName="toolbar-class"
                  mention={{
                    trigger: "@",
                    separator: " ",
                    suggestions: tags,
                  }}
                  editorStyle={{ width: "100%" }}
                  style={{ borderRadius: "4px" }}
                  onBlur={() => handleEditNote()}
                />
              )}
            </div>
            <div
              className="d-flex justify-content-end mobile-30"
              style={{ width: "30%" }}
            >
              <div className="subtotal-invoice">
                <ul>
                  <li>
                    <span>Invoice Amount</span>
                    <span>
                      {NumberFormat(data?.invoiceAmount, {
                        fractionalPoint: true,
                      })}
                    </span>
                  </li>
                  <li>
                    <span>Payments Received</span>
                    <span>
                      {NumberFormat(data?.amountPaid, {
                        fractionalPoint: true,
                      })}
                    </span>
                  </li>
                  <li
                    className="border-invoice"
                    style={{
                      color:
                        getStatusColor[data?.invoiceStatus?.toLowerCase()]
                          ?.color,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span>
                      {
                        getStatusColor[data?.invoiceStatus?.toLowerCase()]
                          ?.label
                      }
                    </span>
                    <span>
                      {NumberFormat(dueAmount, { fractionalPoint: true })}
                    </span>
                  </li>
                </ul>
                <Button
                  type="primary"
                  className="apply-btn w-100 mt-5"
                  onClick={() => handlePayment(true)}
                  loading={
                    paymentLoading ||
                    data?.paymentStatus === PAYMENT_STATUS.PENDING
                  }
                  disabled={
                    data?.invoiceStatus ===
                      PAYMENT_STATUS.PAID.toLocaleLowerCase() ||
                    paymentLoading ||
                    (data?.paymentStatus === PAYMENT_STATUS.PAID && !dueAmount)
                  }
                >
                  {paymentBtnText || "Pay Invoice"}
                </Button>
              </div>
            </div>
          </div>
        </>
      );
    case "paid":
      const remainingBalance = data?.previousBalance - data?.amount;
      const statusColor =
        getStatusColor[
          data?.invoices?.find((obj) => obj?.invoiceStatus !== "paid")
            ?.invoiceStatus
        ];
      return (
        <>
          <h3 className="px-2">Payment</h3>
          <Table
            style={{ whiteSpace: "normal", marginBottom: 10 }}
            columns={tableData?.column?.payment}
            dataSource={tableData?.data?.payment}
            pagination={false}
            // scroll={{ x: 1300 }}
            className="userTable customClass"
            components={{
              body: {
                cell: ({ className, ...restProps }) => (
                  <td className="text-center" {...restProps} />
                ),
              },
            }}
          />
          <h3 className="px-2">Invoice</h3>
          <Table
            style={{ whiteSpace: "normal", tableLayout: "fixed" }}
            columns={tableData?.column?.invoice}
            dataSource={tableData?.data?.invoice}
            // scroll={{ x: 1300 }}
            className="userTable mobile-table-view"
            pagination={false}
          />
          <div className="d-flex my-4" style={{ justifyContent: "end" }}>
            <div
              className="d-flex justify-content-end mobile-30"
              style={{ width: "30%" }}
            >
              <div className="subtotal-invoice">
                <ul>
                  <li>
                    <span>Previous Balance</span>
                    <span>
                      {NumberFormat(data?.previousBalance, {
                        fractionalPoint: true,
                      })}
                    </span>
                  </li>
                  <li>
                    <span>Payments Received</span>
                    <span>
                      {NumberFormat(data?.amount, { fractionalPoint: true })}
                    </span>
                  </li>
                  <li
                    className="border-invoice"
                    style={{
                      color:
                        remainingBalance === 0 ? "#027948" : statusColor?.color,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span>New Balance</span>
                    <span>
                      {NumberFormat(remainingBalance, {
                        fractionalPoint: true,
                      })}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      );

    case "payment":
      const dueAmountPayment =
        (data?.invoiceAmount || 0) - (data?.amountPaid || 0);
      return (
        <>
          <h3 className="px-2">Payment</h3>
          <Table
            style={{ whiteSpace: "normal" }}
            columns={tableData?.column}
            dataSource={tableData?.data}
            pagination={false}
            // scroll={{ x: 1300 }}
            className="userTable customClass"
            components={{
              body: {
                cell: ({ className, ...restProps }) => (
                  <td className="text-center" {...restProps} />
                ),
              },
            }}
          />
          <div className="d-flex justify-content-end gap-2 flex-wrap">
            <div className="subtotal-invoice" style={{ fontSize: "initial" }}>
              <ul>
                <li>
                  <span>Invoice Amount</span>
                  <span>
                    {NumberFormat(data?.invoiceAmount, {
                      fractionalPoint: true,
                    })}
                  </span>
                </li>
                <li>
                  <span>Payments Received</span>
                  <span>
                    {NumberFormat(data?.amountPaid, { fractionalPoint: true })}
                  </span>
                </li>
                <li className="border-invoice">
                  <span>Amount Due</span>
                  <span>
                    {NumberFormat(dueAmountPayment, { fractionalPoint: true })}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    case invoiceEmailCategory.projectSummary:
      // Manually add balance property to each item
      tableData.projectSummaryTable.forEach((item, index) => {
        if (index === 0) {
          item.balance = item.amountWithSign;
        } else {
          const prevItem = tableData.projectSummaryTable[index - 1];
          item.balance = prevItem.balance + item.amountWithSign;
        }
      });

      // Now, use the tableData.projectSummaryTable directly
      const rows = tableData.projectSummaryTable.map((item) => ({
        key: item._id,
        date: item.date ? new Date(item.date).toLocaleDateString("en-US") : "-",
        docNumber: item.docNumber,
        description: (
          <>
            <strong>{item.description.title}</strong>
            <div>{item.description.description}</div>
          </>
        ),
        amount: item.amountWithSign,
        balance: item.balance,
      }));

      return (
        <>
          <div>
            <h3 className={`px-2 ${handleHideTable ? "float-start" : ""}`}>
              Project Summary
            </h3>
            {showToggle && handleHideTable ? (
              <Switch
                checked={!isHidden}
                onChange={handleHideTable}
                disabled={loading}
              />
            ) : (
              ""
            )}
          </div>
          {handleHideTable && isHidden ? (
            ""
          ) : (
            <Table
              style={{ whiteSpace: "normal" }}
              columns={projectColumns(rows)}
              dataSource={rows}
              pagination={false}
              // scroll={{ x: 1300 }}
              className="userTable mobile-table-view"
            />
          )}
        </>
      );

    case "remainingPayments":
      const remainingRows = tableData.remainingPayments.map((item) => ({
        key: item._id,
        invoiceName: item.invoiceName,
        invoiceNumber: item.invoiceNumber,
        invoiceStatus: item.invoiceStatus,
        amount: item.amount,
        dueDate: item.dueDate,
        amountPaid: item.amountPaid,
        paidDate: item.paidDate,
        balance: item.balance,
      }));

      return (
        <>
          <h3 className="px-2">Remaining Payments</h3>
          <Table
            style={{ whiteSpace: "normal" }}
            columns={remainingColumns}
            dataSource={remainingRows}
            pagination={false}
            // scroll={{ x: 1300 }}
            className="userTable fix-table mobile-table-view"
          />
        </>
      );
    default:
      break;
  }
};

export const invoiceEmailCategory = {
  estimate: "Estimate",
  signedEstimate: "Signed Estimate",
  due: "Due Invoice",
  overdue: "Overdue Invoice",
  notdue: "Not Due Invoice",
  notDue: "Not Due Invoice",
  recordPaymentReceipt: "Recorded Payment Receipt",
  paymentReceipt: "QB/Stripe Payment Receipt",
  changeOrder: "Change Order",
  signedChangeOrder: "Signed Change Order",
  credit: "Credit",
  projectSummary: "Project Summary",
  dueDateReminder: "Due Date Reminders",
  overdueReminder: "Overdue Reminders",
  paid: "Recorded Payment Receipt",
};

export const calculateBalance = (record, selectedPayment = false) =>
  selectedPayment
    ? NumberFormat(record?.balance || 0)
    : `${NumberFormat(
        Number((record?.amount - record?.amountPayment).toFixed(2)),
      )}`;
// export const calculateBalance = (record, selectedPayment = false) =>
//   selectedPayment
//     ? NumberFormat(
//         record?.amount -
//           (record?.amountPayment - selectedPayment?.allocatedAmount),
//       )
//     : `${NumberFormat(record?.amount - record?.amountPayment)}`;

export const amountKeys = ["allocatedAmount", "amount", "paymentAmount"];

export const extractToken = (urlString) => {
  const queryString = urlString.split("?")[1];
  const queryParams = queryString.split("&");
  const url = queryParams
    .map((ele) => {
      const pair = ele.split("=");
      if (pair[0] === "token") {
        return pair[1];
      }
      return null;
    })
    ?.toString();

  return url;
};
