/* eslint-disable no-unused-vars */
import "cropperjs/dist/cropper.css";

import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import Cropper from "cropperjs";
import heic2any from "heic2any";
import React, { useEffect, useRef, useState } from "react";

import ModalDailog from "../../../../common/components/ModalDialogue";
import { createValidUrl } from "../../../../utils/commonFuctions";
import { allImageFilesExtension } from "../../../../utils/constants/constants";
import SmallLoader from "../../../loader/smallLoader";

const { Dragger } = Upload;

const ProposalImageCropper = ({
  setImageURL,
  imageURL,
  setPhoto,
  setErrors,
  errors,
}) => {
  const imageRef = useRef(null);
  let cropper = null;
  const cropperRef = useRef(null);
  const [showCropper, setShowCropper] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const customRequest = async ({ onSuccess, file }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (imageRef.current) {
        if (cropperRef.current) {
          cropperRef.current.destroy();
        }
        imageRef.current.src = e.target.result;
        cropperRef.current = new Cropper(imageRef.current, {
          aspectRatio: 234 / 109,
          zoomable: true,
          cropBoxResizable: true,
        });
        setPhoto(cropperRef.current || null);
        onSuccess();
      }
    };

    reader.onerror = () => {
      setShowCropper(false);
    };

    reader.readAsDataURL(file);
  };

  const validateImage = async (file) => {
    console.log("file => ", file);
    setImgLoading(true);
    const maxSize = 5 * 1024 * 1000;
    console.log("file => ", file?.type);
    if (!allImageFilesExtension.includes(file?.type)) {
      console.log("inside #61");
      message.error("Invalid file selected");
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
      // setErrors((prev) => ({ ...prev, file:  }));
      setPhoto(null);
      return false;
    }
    if (file.size > maxSize) {
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
      setErrors((prev) => ({
        ...prev,
        file: "Please upload file less than 5 MB",
      }));
      return true;
    }
    if (allImageFilesExtension.includes(file?.type)) setShowCropper(true);
    if (file.type === "image/heic" || file.type === "image/heif") {
      const fileName = file.name?.split(".")?.[0];

      // Convert HEIC to JPEG
      const convertedFile = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });

      convertedFile.name = `${fileName}.jpg`;
      setImgLoading(false);
      setErrors((prev) => ({ ...prev, file: "" }));
      return convertedFile;
    }
    setImgLoading(false);
    setErrors((prev) => ({ ...prev, file: "" }));
    return file;
  };

  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedImageDataUrl = cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toDataURL("image/jpeg");
      cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.jpg", {
              type: "image/jpeg",
            });
            setPhoto(file);
          }
        });
      if (croppedImageDataUrl) {
        setImageURL(croppedImageDataUrl || null);
        cropperRef.current.destroy();
      }
    }
    setShowCropper(false);
  };

  const handleCancelCropper = () => {
    setShowCropper(false);
    setPhoto(null);
  };

  useEffect(() => {
    if (!showCropper && imageRef.current) {
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
    }
  }, [showCropper]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: 250,
      }}
    >
      <SmallLoader />
    </div>
  );
  return (
    <>
      <Dragger
        name="logo"
        maxCount={1}
        listType="picture-card"
        customRequest={(option) => customRequest(option)}
        showUploadList={false}
        accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .heic"
        onRemove={() => setShowCropper(false)}
        // onStart={() => {
        //   setShowCropper(true);
        // }}
        className="mb-3"
        beforeUpload={validateImage}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p style={{ fontSize: 16 }} className="ant-upload-text">
          <span className="ant-uloadf">Click to upload</span>{" "}
          <span style={{ color: "#667085" }}>or</span>
        </p>
        <p className="m-0" style={{ color: "#667085" }}>
          Drag a image file
        </p>
        <p className="m-0" style={{ color: "#667085", fontSize: 12 }}>
          (File should be less than 5MB)
        </p>
      </Dragger>
      {errors?.file && <p className="text-danger m-0">{errors?.file}</p>}
      <ModalDailog
        isModalOpen={showCropper}
        handleCancel={() => {
          handleCancelCropper();
        }}
        closable
        title="Edit image"
        footer={[
          <Button
            className="clear-filter"
            onClick={() => {
              handleCancelCropper();
            }}
          >
            Cancel
          </Button>,
          <Button
            onClick={handleCrop}
            className="apply-btn"
            type="primary"
            style={{ marginBottom: 0 }}
          >
            Ok
          </Button>,
        ]}
      >
        <div>{imgLoading ? <Loader /> : <img ref={imageRef} alt="" />}</div>
      </ModalDailog>
      {createValidUrl(imageURL) && (
        <div className="border image-width">
          <img src={createValidUrl(imageURL)} alt="" />
          <span
            onClick={() => {
              setImageURL(null);
              setPhoto(null);
            }}
            className="ant-close-btn"
          >
            <DeleteOutlined />
          </span>
        </div>
      )}
    </>
  );
};

export default ProposalImageCropper;
