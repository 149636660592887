import { Table, Typography } from "antd";
import classNames from "classnames";
import React from "react";

import { NumberFormat } from "../../../../utils/numberFormat";
import HrsMinute from "../../../customer-details/components/estimationDetails/detailedBreakdown/hrsMinute";

const { Text } = Typography;

const LaborTasks = ({
  settings,
  generatingPdf,
  laborDetails,
  subcontractorDetails,
  equipmentDetails,
}) => {
  const laborCol = [
    {
      title: "Name",
      dataIndex: "name",
      width: 267,
      className: "wrap-name",
    },
    {
      ...(settings?.breakdown?.details?.laborTasks?.details?.breakdown && {
        title: "Breakdown",
        dataIndex: "breakdown",
        width: 193,
        className: "text-center",
        render: (obj) =>
          obj?.hrsBreakdown ? (
            <>
              <span className="d-flex align-items-center justify-content-between">
                {obj?.hrsBreakdown?.days && (
                  <>
                    <b>{obj?.hrsBreakdown?.days}</b>
                    Days &
                  </>
                )}
                <HrsMinute
                  hours={obj?.hrsBreakdown?.hours}
                  style={{ width: "fit-content", fontWeight: "bold" }}
                />
                Hrs/W
                <b>{obj?.hrsBreakdown?.totalWorkers}</b>Guys
              </span>
            </>
          ) : (
            <></>
          ),
      }),
    },
    {
      title: settings?.breakdown?.details?.laborTasks?.details?.qtyAndUnit
        ? "Qty"
        : "",
      dataIndex: "quantityToShow",
      render: (value) =>
        settings?.breakdown?.details?.laborTasks?.details?.qtyAndUnit
          ? value
          : "",
      width: 71,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.laborTasks?.details?.qtyAndUnit
        ? "Unit"
        : "",
      dataIndex: "unitToShow",
      render: (value) =>
        settings?.breakdown?.details?.laborTasks?.details?.qtyAndUnit
          ? value
          : "",

      width: 80,
      className: "text-center",
    },
    {
      title: settings?.breakdown?.details?.laborTasks?.details?.unitCost
        ? "Unit Cost"
        : "",
      dataIndex: "unitCostToShow",
      render: (value) =>
        settings?.breakdown?.details?.laborTasks?.details?.unitCost
          ? value
          : "",
      className: "text-center",
      width: 104,
    },
    {
      title: settings?.breakdown?.details?.laborTasks?.details?.cost
        ? "Total Cost"
        : "",
      dataIndex: "costToShow",
      render: (value) =>
        settings?.breakdown?.details?.laborTasks?.details?.cost ? value : "",
      className: "text-center",
      width: 104,
    },
    {
      title: settings?.breakdown?.details?.laborTasks?.details?.charge
        ? "Charge"
        : "",
      dataIndex: "chargeToShow",
      render: (value) =>
        settings?.breakdown?.details?.laborTasks?.details?.charge ? value : "",
      width: 104,
      className: "text-center",
    },
  ]?.filter((obj) => Object.keys(obj)?.length);
  return laborDetails?.length ? (
    <>
      <div
        style={{
          textAlign: "justify",
          fontWeight: generatingPdf ? "bold" : 600,
          marginBottom: "10px",
          color: "#484D59",
          fontSize: "14px",
          marginTop: "16px",
        }}
        className="materialbox"
      >
        Labor
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={laborCol}
          dataSource={laborDetails}
          pagination={false}
          className={classNames(
            "proposal_table_card expanded overflow-x-auto",
            {
              hideData:
                settings?.breakdown?.details?.laborTasks?.details?.totals &&
                laborDetails?.length > 1 &&
                (settings?.breakdown?.details?.laborTasks?.details?.cost ||
                  settings?.breakdown?.details?.laborTasks?.details?.charge),
              "hide-last-row-border":
                laborDetails?.length === 1 ||
                (!settings?.breakdown?.details?.laborTasks?.details?.cost &&
                  !settings?.breakdown?.details?.laborTasks?.details?.charge &&
                  (!settings?.breakdown?.details?.subcontractorsTasks?.active ||
                    !subcontractorDetails?.length) &&
                  (!settings?.breakdown?.details?.equipmentTasks?.active ||
                    !equipmentDetails?.length)),
            },
          )}
          bordered={false}
          tableLayout="fixed"
          {...{
            ...(laborDetails?.length > 1 &&
              (settings?.breakdown?.details?.laborTasks?.details?.cost ||
                settings?.breakdown?.details?.laborTasks?.details?.charge ||
                settings?.breakdown?.details?.laborTasks?.details
                  ?.qtyAndUnit) && {
                summary: (pageData) => {
                  const totalQty = pageData?.reduce(
                    (prev, curr) => prev + curr?.quantityToShow || 0,
                    0,
                  );
                  return (
                    <Table.Summary.Row
                      className={classNames({
                        "hide-borders":
                          (!settings?.breakdown?.details?.subcontractorsTasks
                            ?.active ||
                            !subcontractorDetails?.length) &&
                          (!settings?.breakdown?.details?.equipmentTasks
                            ?.active ||
                            !equipmentDetails?.length),
                      })}
                    >
                      <Table.Summary.Cell
                        {...{
                          ...(settings?.breakdown?.details?.laborTasks?.details
                            ?.breakdown && {
                            colSpan: 2,
                          }),
                        }}
                      >
                        <Text
                          style={{
                            color: "#484d59",
                            fontWeight: generatingPdf ? "bold" : 600,
                          }}
                        >
                          Total
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center">
                        {settings?.breakdown?.details?.laborTasks?.details
                          ?.qtyAndUnit && (
                          <Text
                            style={{
                              color: "#484d59",
                              fontWeight: generatingPdf ? "bold" : 600,
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {totalQty}
                          </Text>
                        )}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center">
                        {settings?.breakdown?.details?.laborTasks?.details
                          ?.cost && (
                          <Text
                            style={{
                              color: "#484d59",
                              fontWeight: generatingPdf ? "bold" : 600,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {NumberFormat(pageData?.[0]?.totalCost)}
                          </Text>
                        )}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="text-center">
                        {settings?.breakdown?.details?.laborTasks?.details
                          ?.charge && (
                          <Text
                            style={{
                              color: "#484d59",
                              fontWeight: generatingPdf ? "bold" : 600,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {NumberFormat(pageData?.[0]?.totalCharge)}
                          </Text>
                        )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  );
                },
              }),
          }}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default LaborTasks;
