import api from "./api";

export async function signContract(data, token) {
  const response = await api.requestByToken({
    url: "/contract/sign-contract",
    method: "PUT",
    data,
    token,
  });
  return response;
}

export async function saveContractAPI(data) {
  return api.request({
    url: "v3/contract/sign-contract/save",
    method: "PUT",
    data,
  });
}

export async function signContractByOrganizationAPI(data) {
  return api.request({
    url: "v3/contract/sign-contract/sign-contract-by-organization",
    method: "PUT",
    data,
  });
}
export async function signContractByClientAPI(data, token) {
  let url = "v3/contract/sign-contract/sign-contract-by-client";
  if (token) {
    url += `?token=${token}`;
  }
  return api.request({
    url,
    method: "PUT",
    data,
  });
}
export async function sendConfirmationEmailAndSaveContractPdfForClientAPI(
  data,
  token,
) {
  let url =
    "v3/contract/sign-contract/send-confirmation-email-and-save-contract-pdf-for-client";
  if (token) {
    url += `?token=${token}`;
  }
  return api.request({
    url,
    method: "PUT",
    data,
  });
}
export async function sendContractByOrganizationToClientAPI(data) {
  return api.request({
    url: "v3/contract/sign-contract/send-contract-by-organization-to-client",
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getContractDetailsByTokenAPI({ token }) {
  return api.request({
    url: `v3/contract/sign-contract/get-contract-details-by-token/${token}`,
    method: "GET",
  });
}
export async function getShareContractLinkAPI({ estimateId }) {
  return api.request({
    url: `v3/contract/sign-contract/share-contract-link/${estimateId}`,
    method: "GET",
  });
}

export async function uploadPdfToUserEstimationAPI(data) {
  return api.request({
    url: "v3/contract/sign-contract/upload-pdf-to-user-estimation",
    method: "PUT",
    data,
  });
}

export const viewedEstimation = (estId) =>
  api.request({
    url: "v3/contract/sign-contract/viewed-estimation",
    method: "POST",
    data: { estimationId: estId },
  });
