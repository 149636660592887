import { Col, Row } from "antd";
import React from "react";

import ReactMentionInput from "../../../../utils/mentionInput/mentionInput";
import { FORMULA_MATERIAL_TYPES } from "../helper";

function LaborOrProfitBox({
  materialType,
  onFocusOut,
  handleChange,
  index,
  value,
  hiddenInputList,
  elementList,
}) {
  return (
    <Row className="align-items-start">
      <Col md={24} xs={24}>
        <label>{getTitleByMaterialType(materialType).title}</label>
      </Col>
      <Col md={24} xs={24}>
        <ReactMentionInput
          className="ant-furmulla-input px-2 outline w-200"
          onChange={(e, newValue) => {
            e = {
              target: {
                ...e.target,
                name: getTitleByMaterialType(materialType).id,
              },
            };
            handleChange(e, index, newValue);
          }}
          noMaterial
          value={value}
          isCustomNameInput
          onBlur={onFocusOut}
          elementList={elementList}
          hiddenInputList={hiddenInputList.map((hiddenValue) => ({
            display: hiddenValue.name,
            id: hiddenValue._id,
          }))}
        />
      </Col>
    </Row>
  );
}

const getTitleByMaterialType = (materialType) => {
  switch (materialType) {
    case FORMULA_MATERIAL_TYPES.labor:
      return { title: "Unit Charge:", id: "laborChargePerHrs" };
    case FORMULA_MATERIAL_TYPES.material:
      return { title: "Gross Profit %:", id: "grossProfit" };
    case FORMULA_MATERIAL_TYPES.subcontractor:
      return { title: "Gross Profit %:", id: "grossProfit" };
    case FORMULA_MATERIAL_TYPES.equipment:
      return { title: "Unit Charge:", id: "laborChargePerHrs" };
    default:
      return "";
  }
};

export default LaborOrProfitBox;
