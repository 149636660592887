/* eslint-disable no-unused-vars */
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Upload } from "antd";
import Cropper from "cropperjs";
import React, { useEffect, useRef, useState } from "react";

import { updateAttachmentFile } from "../../../../api/proposal";
import ModalDailog from "../../../../common/components/ModalDialogue";
import {
  allImageFilesExtension,
  fileExtension,
} from "../../../../utils/constants/constants";
import ProposalImageCropper from "./ProposalImageCropper";

const { Dragger } = Upload;

const EditAttachmentUpload = ({
  handleCancel,
  proposalDetails,
  setProposalDetails,
}) => {
  let cropper = null;
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef(null);
  const imageRef = useRef(null);
  const customRequest = ({ onSuccess, file }) => {
    if (fileExtension.includes(file?.type)) {
      setErrors(null);
      if (allImageFilesExtension.includes(file?.type)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (imageRef.current) {
            if (cropperRef.current) {
              cropperRef.current.destroy();
            }
            imageRef.current.src = e?.target?.result;
            cropperRef.current = new Cropper(imageRef.current, {
              aspectRatio: 2480 / 3508,
              zoomable: true,
              cropBoxResizable: true,
            });
            setAttachment(cropperRef.current);
            onSuccess();
          }
        };

        reader.onerror = (e) => {
          setShowCropper(false);
          console.log("Error while loading template attachment => ", e);
        };

        reader.readAsDataURL(file);
      } else {
        setAttachment(file);
        onSuccess();
      }
    } else {
      setAttachment(null);
      setErrors({ file: "Please select valid file" });
    }
  };
  const validateFile = (file) => {
    const maxSize = 100 * 1024 * 1024;
    if (fileExtension.includes(file?.type)) {
      if (allImageFilesExtension.includes(file?.type)) {
        setShowCropper(true);
      }
      setAttachment(file);
      setErrors(null);
      return true;
    }
    if (file.size > maxSize) {
      setAttachment(null);
      setErrors({ file: "Selected file size more than 100 MB" });
      return false;
    }
    setAttachment(null);
    setErrors({ file: "Please select valid file" });
    return false;
  };
  const handleCrop = async () => {
    if (cropperRef.current) {
      const croppedImageDataUrl = cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toDataURL("image/jpeg");
      cropperRef.current
        .getCroppedCanvas({ fillColor: "#fff" })
        .toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "cropped-image.jpg", {
              type: "image/jpeg",
            });
            setAttachment(file);
          }
        });
      if (croppedImageDataUrl) {
        setAttachmentUrl(croppedImageDataUrl || null);
        cropperRef.current.destroy();
      }
    }
    setShowCropper(false);
  };
  const handleSubmit = async () => {
    setUpdating(true);
    if (attachment) {
      const data = new FormData();
      data.append("attachment", attachment);
      const response = await updateAttachmentFile({
        estId: proposalDetails?.estimateId,
        data,
      });
      if (response?.remote === "success") {
        setProposalDetails((prev) => ({
          ...prev,
          estimateDetails: {
            ...prev?.estimateDetails,
            attachment: response?.data?.data?.link,
          },
        }));
        setUpdating(false);
        handleCancel();
      } else {
        console.log(
          "Error while updating the attachments => ",
          response?.errors?.errors,
        );
        message.error(
          response?.errors?.errors?.error ||
            response?.errors?.errors?.message ||
            response?.errors?.errors,
        );
        setUpdating(false);
      }
    } else {
      setErrors({ file: "File is required to upload" });
    }
  };
  useEffect(() => {
    if (!showCropper && imageRef.current) {
      if (cropper) {
        cropper.destroy();
      }
      imageRef.current.src = null;
      cropper = null;
    }
  }, [showCropper]);
  return (
    <>
      <Form layout="vertical" className="formstate">
        <Form.Item label="Attachments">
          <Dragger
            name="attachment"
            maxCount={1}
            listType="text"
            customRequest={customRequest}
            showUploadList
            accept="application/pdf, .png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
            beforeUpload={validateFile}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p style={{ fontSize: 16 }} className="ant-upload-text">
              <span className="ant-uloadf">Click to upload</span>{" "}
              <span style={{ color: "#667085" }}>or</span>
            </p>
            <span style={{ color: "#667085" }}>Drag a attachment file</span>
          </Dragger>
          {errors?.file && <p className="text-danger m-0">{errors?.file}</p>}
        </Form.Item>

        <Form.Item>
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <Button className="clear-filter" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="apply-btn"
              type="primary"
              onClick={() => handleSubmit()}
              disabled={updating}
            >
              Save For This Estimate
            </Button>
          </div>
        </Form.Item>
      </Form>
      <ModalDailog
        isModalOpen={showCropper}
        handleCancel={() => setShowCropper(false)}
        closable
        title="Edit image"
        footer={[
          <Button onClick={() => setShowCropper(false)}>Cancel</Button>,
          <Button onClick={handleCrop} type="primary">
            Ok
          </Button>,
        ]}
      >
        <>
          <div>
            <img ref={imageRef} alt="" />
          </div>
        </>
      </ModalDailog>
    </>
  );
};

export default EditAttachmentUpload;
