import { Button, Input, Modal, Select } from "antd";
import React from "react";

import {
  createFormulaCategoryAPI,
  getFormulaCategoryAPI,
} from "../../../../api/formula";

function Category({ setCategory, category }) {
  const [isAddCategory, setIsAddCategory] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [selected, setSelected] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const getFormulaCategory = async () => {
    setLoading(true);
    const res = await getFormulaCategoryAPI();
    if (res.remote === "success") {
      setOptions(res.data.data);
    }
    setLoading(false);
  };

  const createFormulaCategory = async () => {
    setLoading(true);
    const res = await createFormulaCategoryAPI({ title });
    if (res.remote === "success") {
      setOptions([...options, res.data.data]);
      setIsAddCategory(false);
      setSelected(res.data.data._id);
      setTitle("");
    }
    setLoading(false);
  };
  React.useEffect(() => {
    // fetch all categories
    getFormulaCategory();
  }, []);
  React.useEffect(() => {
    if (category) {
      setSelected(category);
    }
  }, [category]);
  React.useEffect(() => {
    if (selected) {
      setCategory(selected);
    }
  }, [selected, setCategory]);

  return (
    <div>
      <Select
        className=""
        style={{ width: "200px" }}
        value={selected}
        disabled={loading}
        onChange={(e) => {
          if (e === "add") {
            setIsAddCategory(true);
          } else {
            setSelected(e);
          }
        }}
      >
        {options.map((option) => (
          <Select.Option value={option._id} key={option._id}>
            {option.title}
          </Select.Option>
        ))}
        <Select.Option value="add">Add Category</Select.Option>
      </Select>
      <Modal
        className="modal-radius warning-modal"
        title="Add Category"
        visible={isAddCategory}
        width={350}
        footer={null}
      >
        <div className="text-center">
          <div>Category:</div>
          <div>
            <Input
              placeholder="Category Name"
              className="ant-furmulla-input"
              onChange={(e) => setTitle(e.target.value)}
              disabled={loading}
              value={title}
            />
          </div>
          <div>
            <Button disabled={loading} onClick={() => setIsAddCategory(false)}>
              Cancel
            </Button>
            <Button onClick={createFormulaCategory} disabled={loading}>
              {loading ? "..." : "Confirm"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Category;
