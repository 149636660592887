/* eslint-disable no-unused-vars */
import { Card, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";

const ProposalCoverPage = ({
  proposalDetails,
  coverPageDetails,
  settings,
  generatingPdf,
}) => {
  const userDetails = useSelector((state) => state?.user);
  const [imageLogoURL, setImageLogoURL] = useState("");
  const [imageCoverURL, setImageCoverURL] = useState("");

  const [params] = useSearchParams();

  const token = params.get("token");

  const getFileData = async (imgURL, setImageURL) => {
    const headers = new Headers();
    // headers.set("Access-control-allow-origin", true);
    headers.set("Accept", "image/*");
    try {
      const response = await fetch(imgURL, {
        headers,
      });
      if (!response.ok) {
        setImageURL("");
        throw new Error("Failed to fetch image");
      }

      const blob = await response.blob();

      const mimeType = response.headers.get("content-type");
      const blobWithMIME = new Blob([blob], { type: mimeType });

      const objectURL = URL.createObjectURL(blobWithMIME);
      setImageURL(objectURL);
    } catch (error) {
      // console.log("Error while getting file data => ", error);
      setImageURL("");
    }
  };

  useEffect(() => {
    const func1 = async () => {
      await getFileData(
        createValidUrl(
          token
            ? proposalDetails?.organizationDetails?.logo
            : userDetails?.organization?.logo,
        ),
        setImageLogoURL,
      );
    };

    func1();
  }, [proposalDetails, userDetails]);

  useEffect(() => {
    const func2 = async () => {
      await getFileData(
        createValidUrl(proposalDetails?.coverPage?.photo),
        setImageCoverURL,
      );
    };
    func2();
  }, [proposalDetails]);

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className={`mb-3 proposal-card-h-fix ${
        generatingPdf ? "" : "proposal-card-h-fix2"
      }`}
    >
      {(userDetails?.organization?.logo ||
        proposalDetails?.organizationDetails?.logo) && (
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <div className="ms-auto">
            <img
              src={
                generatingPdf
                  ? imageLogoURL
                  : createValidUrl(
                      token
                        ? proposalDetails?.organizationDetails?.logo
                        : userDetails?.organization?.logo,
                    )
              }
              alt="organization-logo"
              className={`company-logo ${generatingPdf ? "" : "company-logo2"}`}
            />
          </div>
        </div>
      )}

      <div className="contract-bg-section">
        <div className="contract-img">
          <img
            className={`cover_img ${generatingPdf ? "" : "cover_img2"}`}
            src={
              generatingPdf
                ? imageCoverURL
                : createValidUrl(proposalDetails?.coverPage?.photo)
            }
            alt="cover-page"
            style={{ width: "100%", height: "500px" }}
          />
          <div
            className={`contrct-title ${generatingPdf ? "" : "contrct-title2"}`}
          >
            <h3>{coverPageDetails?.title}</h3>
          </div>
        </div>
        <div className="d-flex justify-content-center probtn-section">
          <div
            className={`pro_btn ${generatingPdf ? "" : "pro_btn-1"}`}
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
            }}
          >
            {proposalDetails?.customerProject?.projectName ||
              proposalDetails?.customerDetails?.projectName ||
              "Overall Project"}
          </div>
        </div>
        <div className={`overtext ${generatingPdf ? "" : "overtext2"}`}>
          <Row gutter={[8, 8]}>
            <Col xs={8} lg={8}>
              <div className={`overtitle ${generatingPdf ? "" : "overtitle2"}`}>
                <h4
                  {...{
                    ...(generatingPdf && {
                      style: {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      },
                    }),
                  }}
                >
                  Prepared For
                </h4>
                {/* Customer Name */}
                <p>{proposalDetails?.customerDetails?.fullName}</p>
                <div
                  className={`contct-address ${
                    generatingPdf ? "" : "contct-address2"
                  }`}
                >
                  {" "}
                  {/* Project Address */}
                  {proposalDetails?.customerProject?.projectAddress ||
                    proposalDetails?.customerDetails?.projectAddress ||
                    proposalDetails?.customerDetails?.address ||
                    "-"}
                </div>
              </div>
            </Col>
            <Col xs={8} lg={8}>
              <div className={`overtitle ${generatingPdf ? "" : "overtitle2"}`}>
                <h4
                  {...{
                    ...(generatingPdf && {
                      style: {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      },
                    }),
                  }}
                >
                  Submitted By
                </h4>
                {/* User Name */}
                <p>
                  {token
                    ? proposalDetails?.userDetails?.name
                    : userDetails?.name}
                </p>
                <div
                  className={`contct-address ${
                    generatingPdf ? "" : "contct-address2"
                  }`}
                >
                  {/* Comapany name */}
                  {token
                    ? proposalDetails?.organizationDetails?.name
                    : userDetails?.organization?.name}
                </div>
              </div>
            </Col>
            <Col xs={8} lg={8}>
              <div className={`overtitle ${generatingPdf ? "" : "overtitle2"}`}>
                <h4
                  {...{
                    ...(generatingPdf && {
                      style: {
                        color: "#3d414a",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        lineHeight: "31.855px",
                        letterSpacing: "-0.591px",
                      },
                    }),
                  }}
                >
                  Date Submitted
                </h4>
                <p>{moment().format("MMMM Do, YYYY")}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default ProposalCoverPage;
