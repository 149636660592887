import { lazy } from "react";

import ContractPreviewNew from "../components/contractpreview/v3/contract-preview-new";
import EstimatesV3 from "../components/estimates/v3";
import CreatePassword from "../components/hrms/approveAccount";
import WelcomeBack from "../components/hrms/components/billing/WelcomeBack";
import SendProjectSummary from "../components/invoicing/details/SendProjectSummary";
import InvoiceNote from "../components/invoicing/invoice-setting/invoice-note";
import LeadForm from "../components/leadForm"; // no need lazy for this component, may be better way to give this component route inside CRM component
import Details from "../components/setting/UserListV2/details";
import PromoCodePage from "../components/setting/UserListV2/PromoCodePage";
import { REDIRECTION_TO } from "./constants/constants";

const UserListV2 = lazy(() => import("../components/setting/UserListV2"));
const InvoiceDashBoard = lazy(() =>
  import("../components/invoiceV3/invoiceDashboard/index"),
);
// const InvoiceTable = lazy(() => import("../components/invoiceV2"));

const EditEmailTemplate = lazy(() =>
  import("../components/setting/EmailTemplates/edit-template"),
);

const EditSMSTemplate = lazy(() =>
  import("../components/setting/EmailTemplates/edit-sms-template"),
);
/* const UserSearchBuyNumber = lazy(() =>
  import("../components/user/userprofile/BuyNumber")
); */

const OverheadTable = lazy(() =>
  import("../components/overhead/Overhead.table"),
);

const Estimate = lazy(() => import("../components/estimates/v2"));
/* const ChatModuleBody = lazy(() =>
  import("../components/ChatModule/ChatModuleBody")
); */
const CustomerDetails = lazy(() =>
  import("../components/customer-details/index"),
);
// const CustomerDetails = lazy(() => import("../components/customer-details"));

const CRMDashboard = lazy(() => import("../components/crm/v2"));

// const TODO = lazy(() => import("../components/tasks-todo's/todo.components"));
const EmailSend = lazy(() => import("../components/email/email.component"));

const Services = lazy(() =>
  import("../components/estimates/services/Services.components"),
);

const ContractPreviewV3 = lazy(() =>
  import("../components/contractpreview/v3"),
);

const Dashboard = lazy(() => import("../components/dashboard.component"));

const FormulaV2 = lazy(() =>
  import("../components/formula/v2/formula.v2.component"),
);
const Catlog = lazy(() => import("../components/estimates/catlog/Catlog"));
const EmailSetting = lazy(() =>
  import("../components/setting/emailsetting.component"),
);
const UserList = lazy(() => import("../components/setting/userList.component"));
// const UserListOrganization = lazy(() =>
//   import("../components/userList.component")
// );
const UserProfile = lazy(() =>
  import("../components/user/userprofile/UserProfile"),
);

const PaymentTerm = lazy(() =>
  import("../components/setting/paymentTerms/PaymentTerm"),
);
const TermsAndConditions = lazy(() =>
  import("../components/setting/termsandconditions/TermsAndConditions"),
);
const CoverNote = lazy(() =>
  import("../components/setting/covernote/CoverNote"),
);
const SetupQuestion = lazy(() =>
  import("../components/setting/SetupQuestion/SetupQuestion"),
);
const SetupQuestionV2 = lazy(() =>
  import("../components/setting/SetupQuestion/v2"),
);
const GeneralSettings = lazy(() =>
  import("../components/setting/GeneralSettings/GeneralSettings"),
);
const EmailTemplate = lazy(() =>
  import("../components/setting/EmailTemplates/EmailTemplates"),
);

const AddNewEmailTemplate = lazy(() =>
  import("../components/setting/EmailTemplates/AddNewEmailTemplate"),
);
const AddNewSMSTemplate = lazy(() =>
  import("../components/setting/EmailTemplates/AddNewSMSTemplate"),
);

const AddNewCallTemplate = lazy(() =>
  import(
    "../components/setting/EmailTemplates/call-followup/new-call-template"
  ),
);
const EditCallTemplate = lazy(() =>
  import(
    "../components/setting/EmailTemplates/call-followup/edit-call-template"
  ),
);

const CompanySettings = lazy(() =>
  import("../components/setting/companysettings/CompanySettings"),
);
const Overhead = lazy(() => import("../components/overhead/Overhead"));

const PullFormulaUpdates = lazy(() =>
  import("../components/setting/PullFormulaUpdates"),
);

/* const Invoicepreviewcomponents = lazy(() =>
  import("../components/invoice-o/Invoice.preview.component")
); */

// const UserListJob = lazy(() => import("../components/job/userList.component"));

const HRMS = lazy(() => import("../components/hrms/Hrms"));
const EditRole = lazy(() => import("../components/hrms/Roles/editRole/index"));

// const AddCrew = lazy(() => import("../components/hrms/addCrew/addCrew"));
const AddNewEmployee = lazy(() =>
  import("../components/hrms/addEmployee/addNewEmployee/AddNewEmployee"),
);
const AccountDetails = lazy(() =>
  import("../components/hrms/accountDetails/AccountDetails"),
);

const AffiliateMarketing = lazy(() =>
  import("../components/affiliateSuperAdmin/AffiliateMarketing"),
);

const MyReferralDetails = lazy(() =>
  import("../components/affiliates/v3/profileTabs/referrals/MyReferralDetails"),
);

const ChangeOrderPreview = lazy(() =>
  import("../components/changeOrderPreview"),
);

const SendReceipt = lazy(() =>
  import("../components/invoicing/details/SendReceipt"),
);

const SendInvoicePaymentReceipt = lazy(() =>
  import("../components/invoicing/details/SendInvoicePaymentReceipt"),
);
const InvoiceSetting = lazy(() =>
  import("../components/invoicing/invoice-setting"),
);

const ChangePlan = lazy(() =>
  import("../components/hrms/components/billing/ChangePlan"),
);

const routes = [
  {
    path: "/change-order-preview",
    exact: true,
    name: "changeOrderPreview",
    component: <ChangeOrderPreview />,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "dashbaord",
    component: <Dashboard />,
  },
  {
    path: "/v2/crm",
    exact: true,
    name: "CRMDashboard",
    component: <CRMDashboard />,
  },

  {
    path: "/v2/crm/add-lead",
    exact: true,
    name: "LeadForm",
    component: <LeadForm />,
  },
  /* {
    path: "/tasks-todo",
    exact: true,
    name: "tasksTodo",
    component: <TODO />,
  }, */
  /* {
    path: "/invoicePreview/:id",
    exact: true,
    name: "invoicePreview",
    component: <Invoicepreviewcomponents />,
  }, */
  {
    path: "/view-email",
    exact: true,
    name: "EmailSend",
    component: <EmailSend />,
  },
  /* {
    path: "/tree",
    exact: true,
    name: "tree",
    component: <FormullaTree />,
  }, */
  {
    path: "/estimate-templates",
    exact: true,
    name: "estimate-templates",
    component: <Services />,
  },
  {
    path: "/estimate-templates/old",
    exact: true,
    name: "estimate-templates",
    component: <Services show="old" />,
  },

  /* {
    path: "/v2/contract-preview/:customerId",
    exact: false,
    name: "contract-preview-v2",
    component: <ContractPreviewV2 />,
  }, */
  {
    path: "/v3/contract-preview/:customerId",
    exact: false,
    name: "contract-preview-v3",
    component: <ContractPreviewV3 />,
  },
  {
    path: "/v2/formula-tree",
    exact: true,
    name: "Formula Tree v2",
    component: <FormulaV2 />,
  },
  {
    path: "/catalog",
    exact: true,
    name: "catalog",
    component: <Catlog />,
  },
  {
    path: "/catalog/old",
    exact: true,
    name: "catalog",
    component: <Catlog show="old" />,
  },
  {
    path: "/email-setting",
    exact: true,
    name: "email-setting",
    component: <EmailSetting />,
  },
  {
    path: "/userlist",
    exact: true,
    name: "userlist",
    component: <UserList />,
  },
  {
    path: "/v2/user-list",
    exact: true,
    name: "v2/user-list",
    component: <UserListV2 />,
  },
  {
    path: "/v2/promo-code",
    exact: true,
    name: "v2/promo-code",
    component: <PromoCodePage />,
  },
  {
    path: "/v3/affiliate-marketing",
    exact: true,
    name: "v3/affiliate-marketing",
    component: <AffiliateMarketing />,
  },
  {
    path: "/v2/details",
    exact: true,
    name: "v2/details",
    component: <Details />,
  },

  {
    path: "/user-profile",
    exact: true,
    name: "userprofile",
    component: <UserProfile />,
  },
  {
    path: "/company-settings",
    exact: true,
    name: "companysettings",
    component: <CompanySettings />,
  },
  {
    path: "/pull-formula-updates",
    exact: true,
    name: "pull-formula-update",
    component: <PullFormulaUpdates />,
  },

  {
    path: "/payment-terms",
    exact: true,
    name: "paymentterms",
    component: <PaymentTerm />,
  },
  {
    path: "/term-and-conditions",
    exact: true,
    name: "termsandconditions",
    component: <TermsAndConditions />,
  },
  {
    path: "/cover-notes",
    exact: true,
    name: "covernotes",
    component: <CoverNote />,
  },
  {
    path: "/setup-question",
    exact: true,
    name: "setupquestion",
    component: <SetupQuestion />,
  },
  {
    path: "/v2/setup-question",
    exact: true,
    name: "SetupQuestionV2",
    component: <SetupQuestionV2 />,
  },
  {
    path: "/general-settings",
    exact: true,
    name: "generalsettings",
    component: <GeneralSettings />,
  },
  {
    path: "/notification-templates",
    exact: true,
    name: "emailtemplates",
    component: <EmailTemplate />,
  },
  {
    path: "/notification-templates/new",
    exact: true,
    name: "addnewemailtemplates",
    component: <AddNewEmailTemplate />,
  },
  {
    path: "sms-templates/new",
    exact: true,
    name: "addnewsmstemplates",
    component: <AddNewSMSTemplate />,
  },
  {
    path: "call-templates/new",
    exact: true,
    name: "newcalltemplates",
    component: <AddNewCallTemplate />,
  },
  {
    path: "notification-templates/:id/edit",
    exact: true,
    name: "editemailtemplates",
    component: <EditEmailTemplate />,
  },
  {
    path: "sms-templates/:id/edit",
    exact: true,
    name: "editsmstemplates",
    component: <EditSMSTemplate />,
  },
  {
    path: "call-template/:id/edit",
    exact: true,
    name: "editcalltemplate",
    component: <EditCallTemplate />,
  },

  {
    path: "/overhead",
    exact: true,
    name: "overhead",
    component: <Overhead />,
  },
  {
    path: "/overhead",
    exact: true,
    name: "calender",
    component: <OverheadTable />,
  },
  {
    path: "v2/estimating",
    exact: true,
    name: "estimatingV2",
    component: <Estimate />,
  },
  {
    path: "v2/customer-details",
    exact: true,
    name: "estimatingV2",
    component: <CustomerDetails />,
  },
  {
    path: "/hrms",
    exact: true,
    name: "hrms",
    component: <HRMS />,
  },

  {
    path: "hrms/edit-role",
    exact: true,
    name: "hrms/edit-role",
    component: <EditRole />,
  },
  /* {
    path: "user/search-buy-number",
    exact: true,
    name: "searchbuynumber",
    component: <UserSearchBuyNumber />,
  }, */
  {
    path: "/hrms/add-employee",
    exact: true,
    name: "add-employee",
    component: <AddNewEmployee />,
  },
  {
    path: "/create-password",
    exact: true,
    name: "create-password",
    component: <CreatePassword />,
  },
  {
    path: "/account-details/:id",
    exact: true,
    name: "accountDetails",
    component: <AccountDetails />,
  },
  {
    path: "/welcome-back",
    exact: true,
    name: "welcomeBack",
    component: <WelcomeBack />,
  },
  /* {
    path: "/invoiceV2",
    exact: true,
    name: "invoiceV2",
    component: <InvoiceTable />,
  }, */
  {
    path: "/estimatesV3",
    exact: true,
    name: "estimatesv3",
    component: <EstimatesV3 />,
  },
  {
    path: "/v3/affiliate-marketing/my-referrals/profile/:id",
    exact: true,
    name: "affiliateSuperAdminDetails",
    component: <MyReferralDetails isSuperAdmin />,
  },
  {
    path: "/send-receipt",
    exact: true,
    name: "sendReceipt",
    component: <SendReceipt />,
  },
  {
    path: "/send-invoice",
    exact: true,
    name: "sendInvoice",
    component: <SendInvoicePaymentReceipt />,
  },
  {
    path: "/send-project-summary",
    exact: true,
    name: "sendProjectSummary",
    component: <SendProjectSummary />,
  },
  {
    path: "/invoice-setting",
    exact: true,
    name: "InvoiceSetting",
    component: <InvoiceSetting />,
  },
  {
    path: "/invoice-setting/stripe",
    exact: true,
    name: "InvoiceSetting/stripe",
    component: <InvoiceSetting pathname={REDIRECTION_TO.STRIPE} />,
  },
  {
    path: "/invoice-setting/qb-sync",
    exact: true,
    name: "InvoiceSetting/qbSync",
    component: <InvoiceSetting pathname={REDIRECTION_TO.QB_SYNC} />,
  },
  {
    path: "/invoice-setting/qb-payment",
    exact: true,
    name: "InvoiceSetting/qbPayment",
    component: <InvoiceSetting pathname={REDIRECTION_TO.QB_PAYMENT} />,
  },
  {
    path: "/invoice-setting/square",
    exact: true,
    name: "InvoiceSetting/square",
    component: <InvoiceSetting pathname={REDIRECTION_TO.SQUARE_PAYMENT} />,
  },
  {
    path: "/invoice-setting/square-payment",
    exact: true,
    name: "InvoiceSetting/square",
    component: <InvoiceSetting pathname={REDIRECTION_TO.SQUARE_PAYMENT} />,
  },
  {
    path: "/invoice-note",
    exact: true,
    name: "invoicenote",
    component: <InvoiceNote />,
  },
  {
    path: "/invoice-dashboard",
    expect: true,
    name: "invoice-v3",
    component: <InvoiceDashBoard />,
  },
  {
    path: "/email-templates",
    exact: true,
    name: "EmailTemplates",
    component: <EditEmailTemplate />,
  },
  {
    path: "/account-details/change-plan",
    exact: true,
    name: "ChangePlan",
    component: <ChangePlan />,
  },
  {
    path: "/account-details/reactivate-plan",
    exact: true,
    name: "ChangePlan",
    component: <ChangePlan />,
  },
  {
    path: "/v3/contract-proposal",
    exact: true,
    name: "ContractProposal",
    component: <ContractPreviewNew />,
  },
  {
    path: "/v3/add-project",
    exact: true,
    name: "AddProject",
    component: <CustomerDetails />,
  },
];

export default routes;
