import { getUserEstimationList } from "../../api/v2/userEstimation";
import leadTypes from "./lead.types";

export const setCustomerLeadInfo = (leadInfo) => async (dispatch) => {
  if (!leadInfo) {
    return;
  }
  dispatch({
    type: leadTypes.LEAD_LOADING,
  });

  dispatch({
    type: leadTypes.LEAD_INFO,
    leadInfo,
  });
};

export const setUserEstimationList = (data) => async (dispatch) => {
  if (!data) {
    return;
  }
  dispatch({
    type: leadTypes.LEAD_LOADING,
  });
  const id = data;
  const userEstimateListInfo = await getUserEstimationList(id);
  dispatch({
    type: leadTypes.USER_ESTIMATION,
    userEstimateListInfo,
  });
};
