import { useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useLocation } from "react-router-dom";

import {
  searchCatalogByName,
  searchOldCatalogByName,
} from "../../api/catalogue";
import { FORMULA_MATERIAL_TYPES } from "../../components/formula/v2/helper";

function ReactMentionInput({
  type,
  elementList,
  elementProps,
  hiddenInputList,
  lineItemsList,
  customInput,
  onChange,
  value,
  placeholder,
  className,
  onBlur,
  noMaterial,
  noElement,
  noHiddenInput,
  isCustomInput,
  isMaterialInput,
  isTotalCharge,
  isCustomNameInput,
  getSelectedCatalog = null,
  getSelectedVariation = null,
  variationsList,
  disabled,
  onKeyDown,
  inputRef,
}) {
  const params = useLocation();
  const messtyle = {
    input: {
      overflow: "auto",
      maxHeight: 150,
    },
    highlighter: {
      boxSizing: "border-box",
      overflow: "hidden",
      maxHeight: 150,
    },
    suggestions: {
      zIndex: 999999,
    },
  };
  const [originalCatalogs, setOriginalCatalogs] = useState([]);
  const handleCatalog = (query, callback) => {
    const searchQuery = params.search.trim();
    const queryParams = new URLSearchParams(searchQuery);
    const show = queryParams.get("show");
    if (show === "old") {
      return searchOldCatalogByName(query, [])
        .then((res) => {
          if (res.remote === "success") {
            const catalogs = res.data.data.map((item) => ({
              display: item.name,
              slug: item.slug,
              id: item.superId,
            }));
            return catalogs;
          }
          return [];
        })
        .then(callback);
    }
    return searchCatalogByName(query, [])
      .then((res) => {
        if (res.remote === "success") {
          const catalogs = res.data.data.map((item) => ({
            display: item.name,
            slug: item.slug,
            id: item.superId,
          }));
          setOriginalCatalogs(catalogs);
          return catalogs;
        }
        return [];
      })
      .then(callback);
  };

  return (
    <>
      <MentionsInput
        inputRef={(element) => {
          if (inputRef) {
            inputRef(element);
          }
        }}
        style={messtyle}
        className={className}
        value={value}
        onChange={(e, newValue) => {
          if (newValue.includes("{{catalog||")) {
            // need to replace display with slug for name change
            const originalCatalog = originalCatalogs.find((cata) =>
              newValue.includes(cata.id),
            );
            if (originalCatalog) {
              e.target.value = e.target.value.replace(
                originalCatalog.display,
                originalCatalog.slug,
              );
              newValue = newValue.replace(
                originalCatalog.display,
                originalCatalog.slug,
              );
            }
          }
          onChange(e, newValue);
        }}
        placeholder={placeholder}
        onBlur={onBlur}
        allowSpaceInQuery
        disabled={disabled}
        onKeyDown={(e) => {
          if (onKeyDown) onKeyDown(e);
        }}
      >
        <Mention
          trigger="%"
          markup="%{{variation||__id__||__display__}}"
          data={variationsList || []}
          displayTransform={(id, title) => {
            if (getSelectedVariation) {
              return `{Variation: "${getSelectedVariation(id)}"}`;
            }
            return `{Variation: "${title}"}`;
          }}
        />
        <Mention
          trigger="^"
          markup="^{{lineItems||__id__||__display__}}"
          data={lineItemsList || []}
          displayTransform={(id, title) => `{Line Item: "${title}"}`}
        />
        {!noElement && (
          <Mention
            trigger="@"
            markup="@{{element||__id__||__display__}}"
            data={elementList || []}
            displayTransform={(id, title) => `{Element: "${title}"}`}
            {...(elementProps || {})}
          />
        )}
        <Mention
          trigger="$"
          markup="@{{hidden||__id__||__display__}}"
          data={!noHiddenInput ? hiddenInputList || [] : []}
          displayTransform={(id, title) => `{HiddenValue: "${title}"}`}
        />
        <Mention
          markup="{__display__}"
          trigger="{"
          data={
            isTotalCharge
              ? [{ display: "Total Charge", id: "totalCharge" }]
              : isMaterialInput
              ? type === FORMULA_MATERIAL_TYPES.labor ||
                type === FORMULA_MATERIAL_TYPES.equipment
                ? [
                    { display: "Quantity", id: "quantity" },
                    { display: "Unit Cost", id: "unitCost" },
                    { display: "Cost", id: "cost" },
                    {
                      display: "Unit Charge",
                      id: "unitCharge",
                    },
                    {
                      display: 'Payment Terms: "Average Labor Cost"',
                      id: "averageLaborCost",
                    },
                  ]
                : type === FORMULA_MATERIAL_TYPES.material ||
                  type === FORMULA_MATERIAL_TYPES.subcontractor
                ? [
                    { display: "Quantity", id: "quantity" },
                    { display: "Unit Cost", id: "unitCost" },
                    { display: "Cost", id: "cost" },
                    { display: "Gross Profit %", id: "grossProfit" },
                    {
                      display: 'Payment Terms: "Average Labor Cost"',
                      id: "averageLaborCost",
                    },
                  ]
                : []
              : isCustomNameInput
              ? [
                  {
                    display: 'Company Settings: "# Of Workers"',
                    id: "companySettingsNumberOfWorker",
                  },
                  {
                    display: 'Payment Terms: "Labor Charge Per Hour"',
                    id: "laborChargePerHrs",
                  },
                  {
                    display: 'Payment Terms: "Average Labor Cost"',
                    id: "averageLaborCost",
                  },
                  {
                    display: 'Payment Terms: "Material Gross Profit %"',
                    id: "materialGrossProfit",
                  },
                  {
                    display: 'Payment Terms: "Subcontractor Gross Profit %"',
                    id: "subcontractorGrossProfit",
                  },
                  { display: "Quantity", id: "quantity" },
                  { display: "Unit Cost", id: "unitCost" },
                  { display: "Cost", id: "cost" },
                ]
              : []
          }
          displayTransform={(id, title) => `{${title}}`}
        />
        {!noMaterial && getSelectedCatalog && (
          <Mention
            markup="@{{catalog||__id__||__display__}}"
            trigger="#"
            data={handleCatalog}
            displayTransform={(id) => `{Catalog: "${getSelectedCatalog(id)}"}`}
          />
        )}
        {isCustomInput && customInput && (
          <Mention
            markup="@{{custom||__id__||__display__}}"
            trigger="!"
            data={customInput || []}
            displayTransform={(id, title) => `{Custom: "${title}"}`}
          />
        )}
      </MentionsInput>
    </>
  );
}

export default ReactMentionInput;
