import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import ProposalFinancing from "./ProposalFinancing";

const ProposalProposedSchedule = ({
  settings,
  proposalDetails,
  generatingPdf,
}) => {
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();
  const token = params.get("token");
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className={`mb-3 proposal-card-h-fix ${
        generatingPdf ? "" : "proposal-card-h-fix2"
      }`}
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className={`abouts-btn ${generatingPdf ? "" : "abouts-btn2"}`}
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Proposed Schedule
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="proposed-schedule"
                className={`company-logo ${
                  generatingPdf ? "" : "company-logo2"
                }`}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={`mt-lg-5 mt-3 ad-contract ${
          generatingPdf ? "" : "ad-contract2"
        }`}
      >
        <h4
          {...{
            ...(generatingPdf && {
              style: {
                color: "#484d59",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "30px",
              },
            }),
          }}
        >
          Proposed Start Date
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html: proposalDetails?.proposalSchedule?.startDateDescription,
          }}
        />
        <h4
          {...{
            ...(generatingPdf && {
              style: {
                color: "#484d59",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "30px",
              },
            }),
          }}
        >
          Estimated Completion Time
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html:
              proposalDetails?.proposalSchedule?.completionTimeDescription,
          }}
        />
        <h4
          {...{
            ...(generatingPdf && {
              style: {
                color: "#484d59",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "bold",
                lineHeight: "30px",
              },
            }),
          }}
        >
          Additional Notes
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html: proposalDetails?.proposalSchedule?.additionalNotes,
          }}
        />
      </div>

      {settings?.financing && (
        <ProposalFinancing
          settings={settings}
          proposalDetails={proposalDetails}
          generatingPdf={generatingPdf}
          userDetails={userDetails}
        />
      )}
    </Card>
  );
};

export default ProposalProposedSchedule;
