import api from "./api";
import { transformGetOrganizationResposne } from "./transform/organization";

export async function crateOrganization(data) {
  const response = await api.request({
    method: "post",
    url: "/v1/organization/create-organization",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformGetOrganizationResposne(response.data.data),
    };
  }
  return response;
}
export async function crateOrganizationInitialSetup(data) {
  const response = await api.request({
    method: "post",
    url: "/v3/organization/initial-setup",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
    };
  }
  return response;
}

export async function getOrganizationDetails() {
  if (localStorage.getItem("isCardDeclined") === "false") {
    const response = await api.request({
      method: "GET",
      url: "/v1/organization/get-organization-details",
    });
    if (response.remote === "success") {
      return {
        remote: response.remote,
        data: transformGetOrganizationResposne(response.data.data),
      };
    }
    return response;
  }
  return null;
}
export async function getOrganizationDetailsById(id) {
  const response = await api.request({
    method: "GET",
    url: `/v1/organization/get-organization-details-by-id/${id}`,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformGetOrganizationResposne(response.data.data),
    };
  }
  return response;
}

export async function updateOrganizationDetails(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/update-organization-details",
    data,
  });
  return response;
}

export async function updateOrganizationTermAndCondition(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/update-term-and-condition",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
  return response;
}

export async function updateCoverNote(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/update-cover-note",
    data,
  });
  return response;
}

export async function updateOrganizationPaymentTerms(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/update-organization-payment-terms",
    data,
  });
  return response;
}

export async function updateOrganizationMarkup(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v3/organization/update-organization-markup",
    data,
  });
  return response;
}

export async function copyFormulas() {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/setup-copy-formulae",
  });
  return response;
}

export async function copyCatalogs() {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/setup-copy-catalog",
  });
  return response;
}

export async function copyTermsAndCondition() {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/copy-terms-and-conditions",
  });
  return response;
}

export async function tempUpdateFormula() {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/temp-update-formula-for-org",
  });
  return response;
}

export async function tempUpdateCatalog() {
  const response = await api.request({
    method: "PUT",
    url: "/v1/organization/temp-update-catalog-for-org",
  });
  return response;
}

export async function overwrite(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v2/formula/organization/overwrite",
    data,
  });
  return response;
}

export async function updateSocialMediaLinks(data) {
  const response = await api.request({
    method: "put",
    url: "/v1/organization/set-social-media-links",
    data,
  });
  return response;
}

/** ************V3 API**************** */
export async function getUpdatedAndNewFormulaFromSuperAdminAPI() {
  const response = await api.request({
    method: "GET",
    url: "v3/formula/organization/get-updated-and-new-formula-from-as-collection-super-admin",
  });
  return response;
}
export async function copyFormulaFromSuperAdminAPI(formulaId) {
  const response = await api.request({
    method: "POST",
    url: `v3/formula/organization/copy-formula-from-super-admin/${formulaId}`,
  });
  return response;
}
export async function updateExistingFormulaFromSuperAdminAPI(data) {
  const response = await api.request({
    method: "PUT",
    url: "/v3/formula/organization/update-existing-formula-from-super-admin",
    data,
  });
  return response;
}

export async function getOrganizationProfitSettingsAPI() {
  const response = await api.request({
    method: "GET",
    url: "/v3/organization/profit-settings",
  });
  return response;
}
export async function updateOrganizationProfitSettingsAPI(data) {
  const response = await api.request({
    method: "put",
    url: "/v3/organization/profit-settings",
    data,
  });
  return response;
}
