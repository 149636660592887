import React, { useEffect, useState } from "react";

function HrsMinute({ hours, style }) {
  const [hrsMin, setHrsMin] = useState({
    hrs: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (!isNaN(hours)) {
      const hrs = Math.floor(Number(hours));
      let minutes = Number(((Number(hours) - hrs) * 60).toFixed(0));
      if (minutes.toString().length === 1) {
        minutes = `0${minutes.toString()}`;
      }
      setHrsMin({
        hrs,
        minutes,
      });
    }
  }, [hours]);

  return (
    <div {...{ ...(style && { style }) }}>
      {hrsMin.hrs}:{hrsMin.minutes}
    </div>
  );
}

export default HrsMinute;
