import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";

import ReactMentionInput from "../../../../utils/mentionInput/mentionInput";

function HiddenValueCard({
  hiddenValue,
  index,
  handleChange,
  hiddenValueList,
  handleDeleteHiddenValue,
  catalogsAndServices,
  processedElementList,
}) {
  const [isConditional, setIsConditional] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(null);
  const [isDeleteing, setIsDeleteing] = React.useState(false);
  const [selectedCatalog, setSelectedCatalog] = React.useState(null);
  useEffect(() => {
    if (hiddenValue.isConditional) {
      setIsConditional(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSelectedCatalog = (id) => {
    const catalog = catalogsAndServices.find((item) => item.id === id);
    if (catalog) {
      setSelectedCatalog(catalog);
      return catalog.display;
    }
  };

  return (
    <tr>
      <td width="33.333%">
        <Row className="align-items-center">
          <Col md={24} xs={24}>
            <label>Name Value:</label>
          </Col>
          <Col md={24} xs={24}>
            <Input
              size="large"
              className="ant-furmulla-input"
              name="name"
              value={hiddenValue.name}
              onChange={(e) => handleChange(e, index)}
            />
          </Col>
        </Row>
      </td>
      <td width="33.333%">
        <Row>
          <Col md={24} xs={24}>
            <label>Type:</label>
          </Col>
          <Col md={24} xs={24}>
            <Select
              className="select-formula"
              size="large"
              style={{ width: "100%" }}
              value={isConditional}
              onChange={(value) => {
                setIsConditional(value);
                handleChange(
                  { target: { value, name: "isConditional" } },
                  index,
                );
              }}
            >
              <Select.Option value>Conditional</Select.Option>
              <Select.Option value={false}>Value</Select.Option>
            </Select>
          </Col>
        </Row>
      </td>
      <td width="33.333%">
        <Row>
          {isConditional ? (
            <>
              <Col md={24} xs={24}>
                <label>Enter Condition:</label>
              </Col>
              <Col md={24} xs={24}>
                <Row gutter={[24, 0]}>
                  <Col lg={8} xs={24} className="d-flex align-items-center">
                    <span className="me-2">IF(</span>
                    <ReactMentionInput
                      className="ant-furmulla-input w-120"
                      elementList={processedElementList}
                      onChange={(e, newValue) => {
                        e = { target: { ...e.target, name: "condition" } };
                        if (selectedCatalog) {
                          e.target.value = e.target.value.replace(
                            selectedCatalog.display,
                            selectedCatalog.slug,
                          );
                          newValue = newValue.replace(
                            selectedCatalog.display,
                            selectedCatalog.slug,
                          );
                        }
                        handleChange(e, index, true, newValue);
                      }}
                      placeholder="Condition"
                      value={hiddenValue.expression.condition}
                      getSelectedCatalog={getSelectedCatalog}
                    />

                    <span className="ms-2">,</span>
                  </Col>
                  <Col lg={8} xs={24} className="d-flex align-items-center">
                    <Input
                      name="fullfill"
                      className="ant-furmulla-input w-120"
                      placeholder="Fullfill"
                      value={hiddenValue.expression.fullfill}
                      onChange={(e) => handleChange(e, index, true)}
                      type="number"
                    />
                    <span className="ms-2">,</span>
                  </Col>
                  <Col lg={8} xs={24} className="d-flex align-items-center">
                    <Input
                      name="fail"
                      className="ant-furmulla-input w-120"
                      placeholder="Fail"
                      value={hiddenValue.expression.fail}
                      onChange={(e) => handleChange(e, index, true)}
                      type="number"
                    />
                    <span className="ms-2">)</span>{" "}
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <Col md={24} xs={24}>
                <label>Enter Value:</label>
              </Col>
              <Col md={24} xs={24}>
                <ReactMentionInput
                  className="ant-furmulla-input"
                  elementList={processedElementList}
                  onChange={(e, newValue) => {
                    e = { target: { ...e.target, name: "value" } };
                    if (selectedCatalog) {
                      e.target.value = e.target.value.replace(
                        selectedCatalog.display,
                        selectedCatalog.slug,
                      );
                      newValue = newValue.replace(
                        selectedCatalog.display,
                        selectedCatalog.slug,
                      );
                    }
                    handleChange(e, index, false, newValue);
                  }}
                  placeholder="Value"
                  value={hiddenValue.value}
                  hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                    display: hiddenValue.name,
                    id: hiddenValue._id,
                  }))}
                  getSelectedCatalog={getSelectedCatalog}
                />
              </Col>
            </>
          )}
          <span
            className="delect"
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteModal(true)}
          >
            <DeleteOutlined className="text-danger" />
          </span>
        </Row>
      </td>
      <Modal
        className="modal-radius warning-modal"
        title="Warning!"
        visible={showDeleteModal}
        closeIcon={<InfoCircleOutlined />}
        width={350}
        footer={null}
      >
        <p>Are you sure you want to delete this service?</p>
        <Row>
          <Col md={12} xs={24} className="text-center">
            <Button
              type="text"
              onClick={() => {
                setShowDeleteModal(false);
              }}
              disabled={isDeleteing}
            >
              Cancel
            </Button>
          </Col>
          <Col md={12} xs={24}>
            <Button
              type="link"
              onClick={() =>
                handleDeleteHiddenValue(hiddenValue._id, setIsDeleteing)
              }
              disabled={isDeleteing}
            >
              {isDeleteing ? "Deleting..." : "Delete"}
            </Button>
          </Col>
        </Row>
      </Modal>
    </tr>
  );
}

export default HiddenValueCard;
