import { Card } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { replaceMentions } from "../../../utils/suggestions";
import { processDescription } from "./helper";

const ProposalTermsNCondition = ({
  settings,
  proposalDetails,
  generatingPdf,
}) => {
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();
  const token = params.get("token");
  return (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className={`mb-3 proposal-card-h-fix ${
        generatingPdf ? "" : "proposal-card-h-fix2"
      }`}
    >
      <div className="contract-bg-section mb-2">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            type="primary"
            className={`abouts-btn ${generatingPdf ? "" : "abouts-btn2"}`}
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Terms & Conditions
          </div>
          {(userDetails?.organization?.logo ||
            proposalDetails?.organizationDetails?.logo) && (
            <div className="ms-auto">
              <img
                src={createValidUrl(
                  token
                    ? proposalDetails?.organizationDetails?.logo
                    : userDetails?.organization?.logo,
                )}
                alt="terms-condition"
                className={`company-logo ${
                  generatingPdf ? "" : "company-logo2"
                }`}
              />
            </div>
          )}
        </div>
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: processDescription({
            desc: replaceMentions(
              proposalDetails?.estimateDetails?.termAndCondition,
              proposalDetails?.customerDetails,
              token
                ? proposalDetails?.organizationDetails
                : userDetails?.organization,
              token ? proposalDetails?.userDetails : userDetails,
            ),
            mergeSpan: generatingPdf,
            removeBold: generatingPdf,
          }),
        }}
        className={`termsNCondition ${generatingPdf ? "" : "termsNCondition2"}`}
      />
    </Card>
  );
};

export default ProposalTermsNCondition;
