/* eslint-disable no-unused-vars */
import { Card, Col, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { createValidUrl } from "../../../utils/commonFuctions";
import { NumberFormat } from "../../../utils/numberFormat";
import HrsMinute from "../../customer-details/components/estimationDetails/detailedBreakdown/hrsMinute";
import TableData from "../../setting/UserListV2/Table";
import EquipmentTasks from "./expanded/EquipmentTasks";
import LaborTasks from "./expanded/LaborTasks";
import MaterialTasks from "./expanded/MatrialTasks";
import SubcontractorTasks from "./expanded/SubcontractorTasks";

const { Text } = Typography;

const ProposalSummary = ({ settings, proposalDetails, generatingPdf }) => {
  const [data, setData] = useState([]);
  const [showBreakDown, setShowBreakDown] = useState(false);
  const userDetails = useSelector((state) => state?.user);
  const [params] = useSearchParams();
  const token = params.get("token");
  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
      className: "table-name-field main-table-data-field",
      // onCell: () => ({
      //   colSpan: 3,
      // }),
    },
    {
      ...(settings?.summary?.details?.lineItems?.details?.pricePerLineItem && {
        title: "price",
        dataIndex: "totalServiceCharge",
        className: "main-table-data-field",
        render: (value) => NumberFormat(value),
        // onCell: () => ({
        //   colSpan: 3,
        // }),
      }),
    },
  ]?.filter((obj) => Object.keys(obj)?.length);
  const texableAmount =
    proposalDetails?.estimateDetails?.estimationOverview
      ?.totalChargeWithoutTax *
    (Number(proposalDetails?.estimateDetails?.tax?.percentage) / 100);

  useEffect(() => {
    if (proposalDetails?.estimateDetails?.services?.length) {
      const cloned = [
        ...(proposalDetails?.estimateDetails?.services?.length
          ? proposalDetails?.estimateDetails?.services?.map((obj, ind) => ({
              ...obj,
              totalServiceCharge: obj?.actualServices?.find(
                (obj) => obj?.type === "original",
              )?.totalServiceCharge,
              key: ind?.toString(),
            }))
          : []),
      ];
      // if(cloned?.some(obj => obj?.title === "sub"))

      setData([...cloned]);
    }
  }, [proposalDetails?.estimateDetails?.services]);

  const getExpandedTableDetails = ({
    actualServices,
    type,
    totalCost = "",
    totalCharge = "",
    totalQty = "",
    breakdown = "",
  }) =>
    actualServices
      ?.find((obj) => obj?.type === "original")
      ?.processedMaterials?.filter((obj) => obj?.type === type)
      ?.map((obj) => ({
        quantityToShow: obj?.quantityToShow,
        unitToShow: obj?.unitToShow,
        name: obj?.processedName,
        costToShow: obj?.costToShow,
        chargeToShow: obj?.chargeToShow,
        unitCostToShow: obj?.unitCostToShow,
        type: obj?.type,
        ...(totalCost && {
          totalCost: actualServices?.find((obj) => obj?.type === "original")?.[
            totalCost
          ],
        }),
        ...(totalCharge && {
          totalCharge: actualServices?.find(
            (obj) => obj?.type === "original",
          )?.[totalCharge],
        }),
        ...(totalQty && {
          totalQty: actualServices?.find((obj) => obj?.type === "original")?.[
            totalQty
          ],
        }),
        ...(breakdown && {
          breakdown: obj,
        }),
      }));

  const expandedRowRender = (...rest) => {
    const actualServices = rest?.[0]?.actualServices;
    const materialDetails = getExpandedTableDetails({
      actualServices,
      type: "material",
      totalCharge: "materialCharge",
      totalCost: "materialCost",
    });
    const laborDetails = getExpandedTableDetails({
      actualServices,
      type: "labor",
      totalCharge: "laborCharge",
      totalCost: "laborCost",
      totalQty: "laborCharge",
      // breakdown: true,
    });
    const subcontractorDetails = getExpandedTableDetails({
      actualServices,
      type: "subcontractor",
      totalCharge: "subcontractorCharge",
      totalCost: "subcontractorCost",
    });
    const equipmentDetails = getExpandedTableDetails({
      actualServices,
      type: "equipment",
      totalCharge: "equipmentCharge",
      totalCost: "equipmentCost",
    });
    return (
      <>
        {settings?.breakdown?.details?.materials?.active && (
          <MaterialTasks
            settings={settings}
            laborDetails={laborDetails}
            equipmentDetails={equipmentDetails}
            subcontractorDetails={subcontractorDetails}
            generatingPdf={generatingPdf}
            materialDetails={materialDetails}
          />
        )}
        {settings?.breakdown?.details?.laborTasks?.active && (
          <LaborTasks
            settings={settings}
            generatingPdf={generatingPdf}
            laborDetails={laborDetails}
            subcontractorDetails={subcontractorDetails}
            equipmentDetails={equipmentDetails}
          />
        )}
        {settings?.breakdown?.details?.subcontractorsTasks?.active && (
          <SubcontractorTasks
            subcontractorDetails={subcontractorDetails}
            settings={settings}
            generatingPdf={generatingPdf}
            equipmentDetails={equipmentDetails}
          />
        )}
        {settings?.breakdown?.details?.equipmentTasks?.active && (
          <EquipmentTasks
            settings={settings}
            generatingPdf={generatingPdf}
            equipmentDetails={equipmentDetails}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    setShowBreakDown(settings?.breakdown?.active);
  }, [settings?.breakdown?.active]);

  return !settings?.summary?.details?.lineItems?.active &&
    settings?.estimateTotal ? (
    <div style={{ fontSize: 16 }}>
      {proposalDetails?.estimateDetails?.tax?.status && (
        <>
          <Row
            gutter={[24, 24]}
            style={{
              paddingTop: 14,
              paddingBottom: 10,
              borderBottom: "1px solid #DADADA",
              borderTop: "1px solid #DADADA",
              paddingLeft: 16,
              paddingRight: 16,
              marginLeft: 0,
              marginRight: 0,
            }}
            className={`total-summary ${generatingPdf ? "" : "total-summary2"}`}
          >
            <Col xs={12} className="p-0">
              <Text style={{ color: "#484d59" }}>Subtotal</Text>
            </Col>
            <Col xs={12} className="p-0" style={{ textAlign: "right" }}>
              <Text style={{ color: "#484d59" }}>
                {NumberFormat(
                  proposalDetails?.estimateDetails?.estimationOverview
                    ?.totalChargeWithoutTax,
                )}
              </Text>
            </Col>
          </Row>
          <Row
            gutter={[24, 24]}
            style={{
              paddingTop: 14,
              paddingBottom: 10,
              borderBottom: "1px solid #DADADA",
              borderTop: "1px solid #DADADA",
              paddingLeft: 16,
              paddingRight: 16,
              marginLeft: 0,
              marginRight: 0,
            }}
            className={`total-summary ${generatingPdf ? "" : "total-summary2"}`}
          >
            <Col xs={12} className="p-0">
              <Text style={{ color: "#484d59" }}>
                Tax ({proposalDetails?.estimateDetails?.tax?.percentage}
                %)
              </Text>
            </Col>
            <Col xs={12} style={{ textAlign: "right" }} className="p-0">
              <Text style={{ color: "#484d59" }}>
                {NumberFormat(texableAmount)}
              </Text>
            </Col>
          </Row>
        </>
      )}
      <Row
        gutter={[24, 24]}
        style={{
          paddingTop: 14,
          paddingBottom: 10,
          borderBottom: "1px solid #DADADA",
          borderTop: "1px solid #DADADA",
          paddingLeft: 16,
          paddingRight: 16,
          marginLeft: 0,
          marginRight: 0,
        }}
        className={`total-summary ${generatingPdf ? "" : "total-summary2"}`}
      >
        <Col xs={12} className="p-0">
          <Text
            style={{
              color: "#484d59",
              fontWeight: generatingPdf ? "bold" : 600,
            }}
          >
            Proposal Total
          </Text>
        </Col>
        <Col xs={12} className="p-0" style={{ textAlign: "right" }}>
          <Text
            style={{
              color: "#484d59",
              fontWeight: generatingPdf ? "bold" : 600,
            }}
          >
            {NumberFormat(
              proposalDetails?.estimateDetails?.estimationOverview?.totalCharge,
            )}
          </Text>
        </Col>
      </Row>
    </div>
  ) : settings?.summary?.details?.lineItems?.active ? (
    <Card
      bordered={false}
      style={{
        borderRadius: "12px",
        boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.15)",
      }}
      className={`mb-3 proposal-card-h-fix ${
        generatingPdf ? "" : "proposal-card-h-fix2"
      }`}
    >
      <div className="contract-bg-section">
        <div className="d-flex align-items-center gap-3 justify-content-between pe-4">
          <div
            className={`abouts-btn ${generatingPdf ? "" : "abouts-btn"}`}
            style={{
              backgroundColor: settings?.templateColor,
              color: settings?.templateTextColor,
              padding: "20px 60px",
              ...(generatingPdf && {
                fontSize: "35px",
                fontWeight: "bold",
              }),
            }}
          >
            Summary
          </div>
          {settings?.summary?.details?.lineItems?.active &&
            (userDetails?.organization?.logo ||
              proposalDetails?.organizationDetails?.logo) && (
              <div className="ms-auto">
                <img
                  src={createValidUrl(
                    token
                      ? proposalDetails?.organizationDetails?.logo
                      : userDetails?.organization?.logo,
                  )}
                  alt="summary-logo"
                  className={`company-logo ${
                    generatingPdf ? "" : "company-logo2"
                  }`}
                />
              </div>
            )}
        </div>
      </div>
      <div className="mt-5">
        <p
          className={`w-100 m-0 scope_work ${
            generatingPdf ? "" : "scope_work2"
          }`}
          style={{
            borderBottom: "1px solid #9f9f9f",
            fontWeight: 500,
            fontSize: 18,
            paddingBottom: 10,
          }}
        >
          Scope Of Work
        </p>
        {/* Actual data */}
        {!!data?.length && (
          <>
            <TableData
              columns={columns}
              dataSource={[...data]}
              className={`paverbox ${
                generatingPdf ? "" : "paverbox"
              }`} /*  overflow-x-auto */
              pagination={false}
              showHeader={false}
              tableLayout="fixed"
              expandable={{
                expandedRowRender,
                // indentSize: 20,
                expandedRowKeys: showBreakDown
                  ? data?.map((_, ind) => ind?.toString())
                  : [],
                expandIcon: () => (
                  <div style={{ visibility: "hidden", minWidth: 50 }} />
                ),
                showExpandColumn: false,
                columnWidth: 0,
              }}
            />
            {settings?.estimateTotal && (
              <div style={{ fontSize: 16 }}>
                {proposalDetails?.estimateDetails?.tax?.status && (
                  <>
                    <Row
                      gutter={[24, 24]}
                      style={{
                        paddingBottom: 10,
                        paddingRight: 16,
                        paddingLeft: 16,
                        borderBottom: "1px solid #DADADA",
                      }}
                    >
                      <Col xs={12}>
                        <Text style={{ color: "#484d59" }}>Subtotal</Text>
                      </Col>
                      <Col xs={12} style={{ textAlign: "right" }}>
                        <Text style={{ color: "#484d59" }}>
                          {NumberFormat(
                            proposalDetails?.estimateDetails?.estimationOverview
                              ?.totalChargeWithoutTax,
                          )}
                        </Text>
                      </Col>
                    </Row>
                    <Row
                      gutter={[24, 24]}
                      style={{
                        paddingTop: 14,
                        paddingBottom: 10,
                        borderBottom: "1px solid #DADADA",
                        paddingLeft: 16,
                        paddingRight: 16,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <Col xs={12} className="p-0">
                        <Text style={{ color: "#484d59" }}>
                          Tax (
                          {proposalDetails?.estimateDetails?.tax?.percentage}
                          %)
                        </Text>
                      </Col>
                      <Col
                        xs={12}
                        className="p-0"
                        style={{ textAlign: "right" }}
                      >
                        <Text style={{ color: "#484d59" }}>
                          {NumberFormat(texableAmount)}
                        </Text>
                      </Col>
                    </Row>
                  </>
                )}
                <Row
                  gutter={[24, 24]}
                  style={{
                    paddingTop: 14,
                    paddingBottom: 10,
                    borderBottom: "1px solid #DADADA",
                    paddingLeft: 16,
                    paddingRight: 16,
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                  className={`total-summary ${
                    generatingPdf ? "" : "total-summary2"
                  }`}
                >
                  <Col xs={12} className="p-0">
                    <Text
                      style={{
                        color: "#484d59",
                        fontWeight: generatingPdf ? "bold" : 600,
                      }}
                    >
                      Proposal Total
                    </Text>
                  </Col>
                  <Col xs={12} className="p-0" style={{ textAlign: "right" }}>
                    <Text
                      style={{
                        color: "#484d59",
                        fontWeight: generatingPdf ? "bold" : 600,
                      }}
                    >
                      {NumberFormat(
                        proposalDetails?.estimateDetails?.estimationOverview
                          ?.totalCharge,
                      )}
                    </Text>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default ProposalSummary;
