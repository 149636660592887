import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

function Tabs({ tabs, setTabs, elementList, setElementList }) {
  const [isAddingTab, setIsAddingTabs] = React.useState(false);
  const [selected, setSelected] = React.useState("main");
  const [title, setTitle] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const [selectedTabIdToDelete, setSelectedTabIdToDelete] =
    React.useState(null);

  const createFormulaTabs = async () => {
    const payload = {
      title,
      tabId: uuidv4(),
    };
    const newTabs = [...options];
    newTabs.splice(newTabs.length - 1, 0, payload);
    setOptions([...newTabs]);
    setTabs([...newTabs]);
    setIsAddingTabs(false);
    setSelected(payload.tabId);
    setTitle("");
  };

  const handleDeleteConfirmation = (tabId) => {
    if (tabId === "main" || tabId === "report") return;
    const selectedTab = options.find((option) => option.tabId === tabId);
    if (!selectedTab) return;
    setSelectedTabIdToDelete(tabId);
  };
  const handleDeleteTab = () => {
    if (selectedTabIdToDelete === "main" || selectedTabIdToDelete === "report")
      return;

    const index = options.findIndex(
      (tab) => tab.tabId === selectedTabIdToDelete,
    );
    if (index === -1) return;

    const newElementList = elementList?.map((elem) => {
      if (elem.elementTab === selectedTabIdToDelete) {
        elem.elementTab = "main";
      }
      return { ...elem };
    });

    setElementList(newElementList);

    const newTabs = [...options];
    newTabs.splice(index, 1);
    setOptions([...newTabs]);
    setTabs([...newTabs]);
    setSelected(options[0]?.tabId);
    setSelectedTabIdToDelete(null);
  };

  useEffect(() => {
    if (tabs) {
      setOptions(tabs);
    }
  }, [tabs]);

  return (
    <div>
      <Select
        className=""
        style={{ width: "200px" }}
        value={selected}
        onChange={(e) => {
          if (e === "add") {
            setIsAddingTabs(true);
          } else {
            setSelected(e);
          }
        }}
      >
        {options.map((option) => (
          <Select.Option value={option.tabId} key={option.tabId}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {option.title}
              {["main", "report"].includes(option.tabId) ? (
                ""
              ) : (
                <DeleteOutlined
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the select event from triggering
                    handleDeleteConfirmation(option.tabId);
                  }}
                />
              )}
            </div>
          </Select.Option>
        ))}
        <Select.Option value="add">Add Tab</Select.Option>
      </Select>
      <Modal
        className="modal-radius warning-modal"
        title="Add Tab"
        visible={isAddingTab}
        width={350}
        footer={null}
      >
        <div className="text-center">
          <div>Tab:</div>
          <div>
            <Input
              placeholder="Category Name"
              className="ant-furmulla-input"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div>
            <Button onClick={() => setIsAddingTabs(false)}>Cancel</Button>
            <Button onClick={createFormulaTabs}>Confirm</Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-radius warning-modal"
        title="Add Tab"
        visible={selectedTabIdToDelete}
        width={500}
        footer={null}
      >
        <div className="text-center">
          <h3>Are You sure you want to delete Tab?</h3>
          <div>Note: Element using this tabs will get reset to Main</div>
          <div>
            <Button onClick={() => setSelectedTabIdToDelete(null)}>
              Cancel
            </Button>
            <Button onClick={handleDeleteTab}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Tabs;
