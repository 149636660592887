import React from "react";
import { Editor } from "react-draft-wysiwyg";

import { mentions } from "../../../../utils/suggestions";

const ProposalTitleEditor = ({ setEditorState, editorState }) => {
  const titleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={titleEditorChange}
      wrapperClassName="title-wrapper-class"
      editorClassName="title-editor-class"
      mention={{
        trigger: "@",
        separator: " ",
        suggestions: mentions,
      }}
      toolbarHidden
    />
  );
};

export default ProposalTitleEditor;
