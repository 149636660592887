import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./App.css";
import "./responsive.css";

import { message } from "antd";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { getUserLastSignInDataAction } from "./api/admin";
import { getCdnToken } from "./api/auth";
import { getCurrentUser, getSubscriptionDetails } from "./api/user";
import AffiliateHome from "./components/affiliates/AffiliateHome";
import AffiliateProtectedRoute from "./components/affiliates/AffiliateProtectedRoute";
import AffiliateLogin from "./components/affiliates/Login";
import AffiliateRegistration from "./components/affiliates/v3";
import CreatePassword from "./components/auth/ApprovePassword";
import SignChangeOrder from "./components/changeOrderPreview/signChangeOrder";
import ContractPreviewNew from "./components/contractpreview/v3/contract-preview-new";
import Redirect from "./components/contractpreview/v3/Redirect";
import SignContractV2 from "./components/contractpreview/v3/signContract";
import ReceiptNotFound from "./components/invoicing/ReceiptNotFound";
import ViewInvoiceDetails from "./components/invoicing/ViewInvoiceDetails";
import ViewProjectReceipt from "./components/invoicing/ViewPaymentRecept";
import ViewProjectSummary from "./components/invoicing/ViewProjectSummary";
import SmallLoader from "./components/loader/smallLoader";
import PageNotFound from "./components/page-not-found";
import QBPayment from "./components/payments/QBPayment";
import FreeTrialSignUp from "./components/planPricing/FreeSignUp";
import { AppWrapper } from "./layouts/app-wrapper";
import Home from "./pages/home.pages";
import {
  setAPIKey,
  setCdnToken,
  setLoggedinState,
  setPermissions,
} from "./redux/api/api.action";
import { getOrganization } from "./redux/organization/organization.action";
import { withRouter } from "./rootWrapper";
import { USER_ROLE } from "./utils/constants/constants";
import googleClientId from "./utils/googleAPIKey";
import PrivateRoute from "./utils/privateRoute";

const Auth = lazy(() => import("./pages/auth.page"));

const ResetPassWord = lazy(() => import("./components/auth/ResetPassword"));
const Setup = lazy(() => import("./components/auth/Setup"));

const PlanPricingComponent = lazy(() =>
  import("./components/planPricing/planpricing.component"),
);
const Pro = lazy(() => import("./components/planPricing/proPlan.component"));
const Elite = lazy(() =>
  import("./components/planPricing/elitePlan.component"),
);
const Estimating = lazy(() =>
  import("./components/planPricing/estimation.component"),
);
const PaymentInformation = lazy(() =>
  import("./components/auth/PaymentInformation"),
);
const AffiliateResetPassword = lazy(() =>
  import("./components/affiliates/ResetPassword"),
);

const ScheduleDemo = lazy(() =>
  import("./components/affiliateLanding/ScheduleDemo"),
);

message.config({
  maxCount: 1,
});

function App(/* { getOrganization } */) {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => setToken(localStorage.getItem("token")), []);
  const { userRole, organization } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const googleAPIKey = useSelector((state) => state.api.googleKey);
  const fetchCdnToken = async () => {
    try {
      const reponse = await getCdnToken();
      const newCdnToken = reponse.data.token;
      localStorage.setItem("cdnToken", newCdnToken);
      dispatch(setCdnToken(newCdnToken));
    } catch (error) {}
  };

  const callLastLogin = async () => {
    await getUserLastSignInDataAction();
  };

  /* useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("IN FOREGROUND", payload);
      })
      .catch((err) => console.log("failed: ", err));
  }, []); */

  const getUserDetails = useCallback(async () => {
    setLoading(true);
    if (token) {
      if (getOrganization) {
        getOrganization();
      }
      const response = await getCurrentUser();
      if (response?.data?.id) {
        const userId = response?.data?.id;
        localStorage.setItem("userId", userId);
      }
      if (response?.remote === "success") {
        dispatch({ type: "user/userLoaded", payload: response.data });
        if (!localStorage.getItem("userRole")) {
          localStorage.setItem("userRole", response.data.userRole);
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [dispatch, getOrganization, token]);

  const fetchApiKeys = useCallback(async () => {
    if (!googleAPIKey) {
      const googleKey = await googleClientId();
      dispatch(setAPIKey({ googleKey }));
    }
  }, [dispatch, googleAPIKey]);

  const getSubDetails = useCallback(async () => {
    if (
      token &&
      localStorage?.getItem("isCardDeclined") === "false" &&
      localStorage.getItem("isSubscriptionCanceled") === "false"
    ) {
      const response = await getSubscriptionDetails();
      dispatch(setPermissions(response?.data?.data?.[0]));
    }
  }, [token]);

  useEffect(() => {
    getUserDetails();
  }, [token, getUserDetails]);

  useEffect(() => {
    getSubDetails();
  }, [token, getSubDetails]);

  useEffect(() => {
    if (token) {
      dispatch(setLoggedinState(true));
    }
  }, [token]);

  useEffect(() => {
    if (token) fetchApiKeys();
  }, [fetchApiKeys, token]);

  useEffect(() => {
    const fetchData = async () => {
      // set CDN Token
      await fetchCdnToken();
    };
    // Fetch data initially when component mounts
    fetchData();

    // Fetch data once a hour
    const intervalId = setInterval(fetchData, 1 * 60 * 60 * 1000);

    // Clear interval when component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (userRole?.roleName === USER_ROLE.salesPerson && !userRole?.superId) {
      navigate("/v2/user-list");
    }
  }, [userRole]);

  useEffect(() => {
    if (
      token &&
      !localStorage.getItem("superAdminToken") &&
      !localStorage.getItem("fromSuperAdmin")
    ) {
      callLastLogin();
    }
  }, [location.pathname, token]);

  useEffect(() => {
    if (organization && !organization?.isCompleteSetup) {
      navigate("/setup");
    }
  }, [organization]);

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              minHeight: "100vh",
            }}
          >
            <SmallLoader />
          </div>
        }
      >
        <div className="App">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                minHeight: "100vh",
              }}
            >
              <SmallLoader />
            </div>
          ) : (
            <>
              {/** abraham used this script tag with hardcoded api-key, so I am replacing that script tag over here: OSCAR */}
              <script
                src={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`}
              />
              <AppWrapper>
                <Routes>
                  <Route path="/auth/*" element={<Auth />} />
                  <Route
                    path="/*"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/" element={<Navigate replace to="/auth" />} />
                  <Route
                    path="/reset-password/:id"
                    element={<ResetPassWord />}
                  />
                  <Route
                    path="/create-password/:id"
                    element={<CreatePassword />}
                  />
                  <Route path="/setup" element={<Setup />} />
                  <Route
                    path="/pricing-page"
                    element={<PlanPricingComponent />}
                  />
                  <Route path="/pro-plan" element={<Pro />} />
                  <Route path="/elite-plan" element={<Elite />} />
                  <Route path="/estimating-plan" element={<Estimating />} />
                  <Route path="/essential-plan" element={<Estimating />} />
                  <Route path="/free-signup" element={<FreeTrialSignUp />} />
                  <Route
                    path="/payment-information"
                    element={<PaymentInformation />}
                  />
                  <Route path="/setup/:id" element={<Setup />} />
                  <Route
                    path="/v2/sign-contract"
                    element={<SignContractV2 />}
                  />
                  <Route
                    path="/sign-change-order"
                    element={<SignChangeOrder />}
                  />
                  <Route path="/redirect" element={<Redirect />} />
                  {/* <Route
                    path="/invoicePreviews"
                    element={<Invoicepreviewcomponentssend />}
                  /> */}
                  <Route path="/page-not-found" element={<PageNotFound />} />
                  <Route
                    path="/affiliate"
                    expect
                    element={<AffiliateLogin />}
                  />
                  <Route
                    path="/affiliate/registration"
                    element={<AffiliateRegistration />}
                  />
                  <Route
                    path="/affiliate/reset-password/:id"
                    element={<AffiliateResetPassword />}
                  />
                  <Route
                    path="/affiliate/*"
                    element={
                      <AffiliateProtectedRoute>
                        <AffiliateHome />
                      </AffiliateProtectedRoute>
                    }
                  />
                  <Route path="/schedule-demo" element={<ScheduleDemo />} />
                  <Route
                    path="/view-project-summary"
                    element={<ViewProjectSummary />}
                  />
                  <Route
                    path="/view-invoice"
                    element={<ViewInvoiceDetails />}
                  />
                  <Route
                    path="/view-payment-receipt"
                    element={<ViewProjectReceipt />}
                  />
                  <Route
                    path="/view-proposal"
                    element={<ContractPreviewNew />}
                  />
                  <Route
                    path="/receipt-not-found"
                    element={<ReceiptNotFound />}
                  />
                  <Route path="/quickbook-payment" element={<QBPayment />} />
                </Routes>
              </AppWrapper>
            </>
          )}
        </div>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        limit={1}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getOrganization: () => dispatch(getOrganization()),
});

export default withRouter(connect(null, mapDispatchToProps)(App));
