import { message } from "antd";

import api from "../../../api/api";
import { PROJECT_STATUS } from "../../../utils/constants/constants";
import { handleSelectDropdown } from "./tableHelper.jsx";

export const createRows = (data, projectStatuses) =>
  data?.map((item) => ({
    customerName: item.customerName,
    projectName: item.projectName?.replace(/^\s+|\s+$/g, ""),
    projectStatus: handleSelectDropdown(item, projectStatuses),
    projectNumber: item.projectNumber,
    invoicesStatus: item.invoicesStatus || "",
    daysDueOverdue: item.daysDueOverdue || "",
    totalProject: item.totalProject,
    amountPaid: item.amountPaid,
    amountOverdue: item.amountOverdue,
    amountDue: item.amountDue,
    amountNotDue: item.amountNotDue,
    estimationId: item.estimationId,
    customerId: item.projectId,
  }));
export const createFilterData = (payload) => ({
  projectStatuses: payload?.projectStatuses,
  invoiceStatuses: payload?.invoiceStatuses,
  page: payload?.selectedPage,
  perPage: 10,
  search: payload?.searchValue,
  fieldName: payload?.fieldName,
  order: payload?.order,
});
export const handleStatusColor = (status) => {
  switch (status) {
    case PROJECT_STATUS.Cancelled:
      return "#E42626";
    case PROJECT_STATUS.Completed:
      return "#14A88D";
    case PROJECT_STATUS.Delayed:
      return "#9B25B9";
    case PROJECT_STATUS.inProgress:
      return "#14A88D";
    case PROJECT_STATUS.notStarted:
      return "#E63B7A";
    case PROJECT_STATUS.onHold:
      return "#FF9E0D";
    default:
      return "#14A88D";
  }
};

export const updateStatus = async (payload) => {
  // Display processing message
  message.loading({ content: "Processing...", key: "updatable" });

  const response = await api.request({
    url: "/v3/invoice/dashboard/project-status",
    method: "PUT",
    data: payload, // Include payload in the request body
  });

  if (response.remote === "success") {
    // Display success message
    message.success({
      content: "Status updated successfully!",
      key: "updatable",
      duration: 2,
    });
    return response;
  }
  // Display error message
  message.error({
    content: "Error updating status. Please try again.",
    key: "updatable",
    duration: 2,
  });
};
